export default function PackageOpenIcon({
  ...props
}: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 16 14"
      {...props}
    >
      <path
        fill="#3872FA"
        d="M10.335 7.666a1.839 1.839 0 0 1-1.667-1.06l-.666-1.434-.667 1.433c-.3.647-.953 1.06-1.667 1.06h-4.34v4.167a.5.5 0 0 0 .4.487l6.16 1.333a.498.498 0 0 0 .107.013h.014c.033 0 .067-.007.107-.013l6.16-1.333a.499.499 0 0 0 .393-.487V7.665h-4.334Z"
      />
      <path
        fill="#608EFB"
        d="M15.498 6.336H10.33a.5.5 0 0 1-.453-.289l-2.33-5a.501.501 0 0 1 .558-.699l6.164 1.333a.499.499 0 0 1 .364.318l1.334 3.667a.499.499 0 0 1-.47.67Z"
      />
      <path
        fill="#608EFB"
        d="M5.668 6.337H.5a.5.5 0 0 1-.47-.671l1.334-3.667a.5.5 0 0 1 .364-.318L7.892.348a.497.497 0 0 1 .558.699l-2.33 5a.494.494 0 0 1-.452.29Z"
      />
    </svg>
  );
}
