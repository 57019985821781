import React from 'react';
import ProfileLayout from '../ProfileLayout';

interface UserContractsProps { }

const UserContracts: React.FC<UserContractsProps> = () => {
    return (
        <ProfileLayout>
            <div className="@container">
                <div className="mb-10 grid gap-7 divide-y-2 divide-dashed divide-gray-200 @2xl:gap-9 @3xl:gap-11">

                </div>
            </div>
        </ProfileLayout>
    );
};

export default UserContracts;