export default function FileSettingsIcon({
  ...props
}: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      fill="none"
      {...props}
    >
      <g clipPath="url(#a)">
        <path
          fill="#FFE177"
          d="M4.23 9.567v-2.84H2.681a.39.39 0 0 0-.39.39v2.45h1.94Z"
        />
        <path
          stroke="#000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit={10}
          strokeWidth={0.6}
          d="M4.191 6.727h-1.51a.39.39 0 0 0-.39.39v2.411h1.9V6.727Z"
        />
        <path
          fill="#D8ECFE"
          d="M8.75 9.567a.431.431 0 0 0 .347-.176l.896-1.223a.353.353 0 0 1 .283-.144h3.837V1.086a.39.39 0 0 0-.39-.39H6.92l-2.73 2.73v6.14h4.56Z"
        />
        <path
          fill="#C4E2FF"
          d="M13.722.695h-1.328a.39.39 0 0 1 .39.39v6.939h1.329V1.086a.39.39 0 0 0-.39-.39Z"
        />
        <path
          stroke="#000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit={10}
          strokeWidth={0.6}
          d="M4.19 5.978v3.55h4.56a.39.39 0 0 0 .316-.16l.895-1.223a.39.39 0 0 1 .315-.16h3.837v-6.9a.39.39 0 0 0-.39-.39H6.92l-2.73 2.73v1.186"
        />
        <path
          fill="#8AC9FE"
          d="M4.19 3.426H6.61c.172 0 .312-.14.312-.313V.695l-2.73 2.73Z"
        />
        <path
          stroke="#000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit={10}
          strokeWidth={0.6}
          d="M4.19 3.426H6.61c.172 0 .312-.14.312-.313V.695l-2.73 2.73Zm2.121 1.851h5.682M6.311 6.72h5.682M6.311 8.16h3.533"
        />
        <path
          fill="#0593FC"
          d="M11.797 3.427h-2.56a.195.195 0 0 1-.196-.196v-.918c0-.108.087-.196.195-.196h2.562c.107 0 .195.088.195.195v.92a.195.195 0 0 1-.195.195Z"
        />
        <path
          fill="#0182FC"
          d="M11.798 2.117h-1.212c.108 0 .195.088.195.195v.92a.195.195 0 0 1-.195.195h1.212a.195.195 0 0 0 .195-.196v-.918a.195.195 0 0 0-.195-.196Z"
        />
        <path
          stroke="#000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit={10}
          strokeWidth={0.6}
          d="M11.797 3.427h-2.56a.195.195 0 0 1-.196-.196v-.918c0-.108.087-.196.195-.196h2.562c.107 0 .195.088.195.195v.92a.195.195 0 0 1-.195.195Z"
        />
        <path
          fill="#FFD15B"
          d="M15.623 7.984h-5.347a.39.39 0 0 0-.315.16l-.895 1.223a.39.39 0 0 1-.315.16H2.29v3.274l.008.009c.065.064.15.1.243.1a.36.36 0 0 0 .16-.039 2.99 2.99 0 0 1 .414-.171.35.35 0 0 0 .244-.332v-.275c0-.149.121-.27.27-.27h.849c.148 0 .269.121.269.27v.275c0 .15.098.284.244.332.141.046.28.104.413.171a.36.36 0 0 0 .162.039.34.34 0 0 0 .242-.1l.196-.196a.267.267 0 0 1 .381 0l.6.6a.27.27 0 0 1 0 .38l-.196.197a.347.347 0 0 0-.061.403c.067.133.125.272.171.414a.35.35 0 0 0 .332.244h.275c.149 0 .27.12.27.27v.848a.27.27 0 0 1-.27.27h-.275a.35.35 0 0 0-.332.243c-.046.142-.104.281-.171.414a.347.347 0 0 0 .06.403l.197.197a.27.27 0 0 1 0 .38l-.257.257h8.698a.586.586 0 0 0 .586-.586V8.376a.39.39 0 0 0-.39-.39Z"
        />
        <path
          fill="#FFC344"
          d="M15.623 7.984H13.75a.39.39 0 0 1 .39.391v9.174a.586.586 0 0 1-.585.585h1.872a.586.586 0 0 0 .586-.586V8.376a.39.39 0 0 0-.39-.39Z"
        />
        <path
          stroke="#000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit={10}
          strokeWidth={0.6}
          d="M6.785 18.137h5.13m1.366-.003h2.146a.586.586 0 0 0 .586-.586V8.376a.39.39 0 0 0-.39-.39h-5.347a.39.39 0 0 0-.315.16l-.895 1.222a.39.39 0 0 1-.315.16H2.29v3.219"
        />
        <path
          fill="#fff"
          d="M14.162 16.455h-3.84a.195.195 0 0 1-.195-.195v-1.6c0-.108.088-.195.196-.195h3.839c.107 0 .195.087.195.195v1.6a.195.195 0 0 1-.195.195Z"
        />
        <path
          fill="#F3F0F3"
          d="M14.162 14.465H12.94c.108 0 .196.087.196.195v1.6a.195.195 0 0 1-.196.195h1.222a.195.195 0 0 0 .195-.195v-1.6a.195.195 0 0 0-.195-.195Z"
        />
        <path
          stroke="#000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit={10}
          strokeWidth={0.6}
          d="M14.162 16.455h-3.84a.195.195 0 0 1-.195-.195v-1.6c0-.108.088-.195.196-.195h3.839c.107 0 .195.087.195.195v1.6a.195.195 0 0 1-.195.195Z"
        />
        <path
          fill="#FFF0AD"
          d="M16.806.695h2.706c.108 0 .195.088.195.196v5.497a.195.195 0 0 1-.195.195H15.63a.195.195 0 0 1-.195-.195V2.066c0-.052.02-.102.057-.138L16.668.753a.196.196 0 0 1 .138-.058Z"
        />
        <path
          fill="#FFE177"
          d="M19.512.695h-1.108c.108 0 .196.088.196.196v5.497a.195.195 0 0 1-.196.195h1.108a.195.195 0 0 0 .195-.195V.89a.195.195 0 0 0-.195-.196Z"
        />
        <path
          stroke="#000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit={10}
          strokeWidth={0.6}
          d="M15.435 5.001v1.387c0 .107.088.195.196.195h3.88a.195.195 0 0 0 .196-.195V.89a.195.195 0 0 0-.195-.196h-2.706a.196.196 0 0 0-.138.058l-1.175 1.175a.195.195 0 0 0-.058.138v1.568m2.972-1.337h-1.191m1.191 1.34h-1.671m1.671 1.343h-1.671"
        />
        <path
          fill="#978ECB"
          d="M7.815 15.97v-.848a.308.308 0 0 0-.308-.308h-.275a.311.311 0 0 1-.295-.218 3.021 3.021 0 0 0-.173-.419.308.308 0 0 1 .054-.358l.196-.196a.308.308 0 0 0 0-.436l-.6-.6a.308.308 0 0 0-.436 0l-.197.196a.308.308 0 0 1-.358.054 3 3 0 0 0-.419-.174.311.311 0 0 1-.217-.294v-.276a.308.308 0 0 0-.308-.308h-.85a.308.308 0 0 0-.308.308v.276a.311.311 0 0 1-.217.294 3.012 3.012 0 0 0-.419.174.308.308 0 0 1-.358-.054l-.196-.196a.308.308 0 0 0-.436 0l-.6.6a.308.308 0 0 0 0 .436l.196.196a.308.308 0 0 1 .054.358 3.017 3.017 0 0 0-.174.42.311.311 0 0 1-.295.217H.601a.308.308 0 0 0-.308.308v.849c0 .17.138.308.308.308h.275c.136 0 .253.089.295.217.048.145.106.285.174.42.06.119.04.263-.054.358l-.196.196a.308.308 0 0 0 0 .436l.6.6c.12.12.315.12.436 0l.196-.196a.308.308 0 0 1 .358-.054c.134.068.274.126.42.174.128.042.216.159.216.294v.275c0 .17.139.309.309.309h.849c.17 0 .308-.138.308-.309v-.275c0-.135.089-.252.217-.294.145-.048.285-.106.42-.174.119-.06.263-.041.357.054l.197.196c.12.12.315.12.436 0l.6-.6a.308.308 0 0 0 0-.436l-.196-.196a.308.308 0 0 1-.054-.359c.068-.134.126-.274.173-.419a.311.311 0 0 1 .295-.217h.275c.17 0 .308-.138.308-.308Z"
        />
        <path
          fill="#8379C1"
          d="M7.507 14.814h-.275a.311.311 0 0 1-.295-.218 3.024 3.024 0 0 0-.174-.419.308.308 0 0 1 .054-.358l.197-.196a.308.308 0 0 0 0-.436l-.6-.6a.308.308 0 0 0-.437 0l-.196.196a.308.308 0 0 1-.358.054 3 3 0 0 0-.419-.174.311.311 0 0 1-.217-.294v-.276a.308.308 0 0 0-.309-.308H3.63a.308.308 0 0 0-.296.223 3.911 3.911 0 0 1 2.252 3.538 3.911 3.911 0 0 1-2.252 3.539.308.308 0 0 0 .296.222h.848c.17 0 .309-.138.309-.308v-.275c0-.135.088-.252.217-.295.145-.047.285-.105.419-.173.12-.06.264-.041.358.054l.196.196c.12.12.316.12.436 0l.6-.6a.308.308 0 0 0 0-.436l-.196-.197a.308.308 0 0 1-.054-.358c.068-.134.127-.274.174-.419a.311.311 0 0 1 .295-.217h.275c.17 0 .308-.138.308-.308v-.85a.308.308 0 0 0-.308-.307Z"
        />
        <path
          stroke="#000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit={10}
          strokeWidth={0.6}
          d="M7.815 15.97v-.848a.308.308 0 0 0-.308-.308h-.275a.311.311 0 0 1-.295-.218 3.021 3.021 0 0 0-.173-.419.308.308 0 0 1 .054-.358l.196-.196a.308.308 0 0 0 0-.436l-.6-.6a.308.308 0 0 0-.436 0l-.197.196a.308.308 0 0 1-.358.054 3 3 0 0 0-.419-.174.311.311 0 0 1-.217-.294v-.276a.308.308 0 0 0-.308-.308h-.85a.308.308 0 0 0-.308.308v.276a.311.311 0 0 1-.217.294 3.012 3.012 0 0 0-.419.174.308.308 0 0 1-.358-.054l-.196-.196a.308.308 0 0 0-.436 0l-.6.6a.308.308 0 0 0 0 .436l.196.196a.308.308 0 0 1 .054.358 3.017 3.017 0 0 0-.174.42.311.311 0 0 1-.295.217H.601a.308.308 0 0 0-.308.308v.849c0 .17.138.308.308.308h.275c.136 0 .253.089.295.217.048.145.106.285.174.42.06.119.04.263-.054.358l-.196.196a.308.308 0 0 0 0 .436l.6.6c.12.12.315.12.436 0l.196-.196a.308.308 0 0 1 .358-.054c.134.068.274.126.42.174.128.042.216.159.216.294v.275c0 .17.139.309.309.309h.849c.17 0 .308-.138.308-.309v-.275c0-.135.089-.252.217-.294.145-.048.285-.106.42-.174.119-.06.263-.041.357.054l.197.196c.12.12.315.12.436 0l.6-.6a.308.308 0 0 0 0-.436l-.196-.196a.308.308 0 0 1-.054-.359c.068-.134.126-.274.173-.419a.311.311 0 0 1 .295-.217h.275c.17 0 .308-.138.308-.308Z"
        />
        <path
          fill="#C2BCE0"
          d="M4.054 17.457a1.912 1.912 0 1 0 0-3.824 1.912 1.912 0 0 0 0 3.824Z"
        />
        <path
          stroke="#000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit={10}
          strokeWidth={0.6}
          d="M4.054 17.457a1.912 1.912 0 1 0 0-3.824 1.912 1.912 0 0 0 0 3.824Z"
        />
        <path
          fill="#fff"
          stroke="#000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit={10}
          strokeWidth={0.6}
          d="M4.054 16.48a.935.935 0 1 0 0-1.87.935.935 0 0 0 0 1.87Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h20v20H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}
