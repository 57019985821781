export default function NotificationSettingsIcon({
  ...props
}: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      fill="none"
      {...props}
    >
      <path
        fill="#FFEB3A"
        d="M15.863 16.997H2.137a.667.667 0 0 1-.47-1.126 6.263 6.263 0 0 0 1.666-3.874V8.331a5.667 5.667 0 0 1 6.09-5.65l-.446.77a.333.333 0 0 0 .076.426l1.304 1.067a3.217 3.217 0 0 0 0 .773L9.053 6.784a.333.333 0 0 0-.076.427l.72 1.24c.08.14.25.202.403.146l1.567-.6c.205.155.43.284.666.384l.277 1.666a.333.333 0 0 0 .333.28h1.434c.099 0 .193-.043.256-.12l.027.02v1.77a6.263 6.263 0 0 0 1.667 3.874.667.667 0 0 1-.464 1.126Z"
      />
      <path
        fill="#FDD834"
        d="m11.333 8.386.277 1.667a.333.333 0 0 0 .333.28h1a.333.333 0 0 1-.333-.28l-.277-1.667a3.125 3.125 0 0 1-.667-.39l-.63.237c.11.06.207.096.297.153ZM7.976 3.451a.333.333 0 0 0 .077.426l1.303 1.067a3.218 3.218 0 0 0 0 .773L8.053 6.784a.333.333 0 0 0-.077.427l.72 1.24c.081.14.252.202.404.146l.566-.21-.683-1.176a.333.333 0 0 1 .077-.427l1.303-1.067a3.217 3.217 0 0 1 0-.773L9.06 3.877a.333.333 0 0 1-.077-.426l.447-.77c-.147-.017-.287-.017-.43-.017-.195 0-.39.01-.584.03l-.44.757Zm8.357 12.42a6.263 6.263 0 0 1-1.667-3.873V10.23l-.027-.02a.333.333 0 0 1-.256.12h-.717v1.667a6.263 6.263 0 0 0 1.667 3.873.667.667 0 0 1-.47 1.127h1a.667.667 0 0 0 .47-1.127Z"
      />
      <path
        fill="#FEC108"
        d="M4.667 15.999H3.333a.333.333 0 1 1 0-.667h1.334a.333.333 0 0 1 0 .667Zm10 0H6a.333.333 0 1 1 0-.667h8.667a.333.333 0 0 1 0 .667Z"
      />
      <path
        fill="#F5F5F5"
        d="M4.666 8.665a.333.333 0 0 1-.333-.334c0-1.417.646-2.758 1.753-3.643a.333.333 0 0 1 .417.52A4 4 0 0 0 5 8.33a.333.333 0 0 1-.334.334Z"
      />
      <path fill="#FF9801" d="M11.666 17a2.667 2.667 0 1 1-5.333 0h5.333Z" />
      <path
        fill="#F57C00"
        d="M10.667 17A2.667 2.667 0 0 1 8.5 19.617c.165.033.332.05.5.05A2.667 2.667 0 0 0 11.667 17h-1Z"
      />
      <path
        fill="#607D8B"
        d="m11.666 7.999-1.566.6a.333.333 0 0 1-.404-.147l-.72-1.24a.333.333 0 0 1 .077-.427l1.303-1.066a3.314 3.314 0 0 0 1.31 2.28Z"
      />
      <path
        fill="#7F8E94"
        d="M17 5.33c0 .13-.008.259-.024.387A3.313 3.313 0 0 1 15 8.381a3.303 3.303 0 0 1-2.647 0 3.126 3.126 0 0 1-.687-.384 3.313 3.313 0 0 1-1.333-3.06 3.314 3.314 0 0 1 1.333-2.273c.206-.155.43-.284.667-.383a3.303 3.303 0 0 1 2.647 0c.244.098.474.227.686.383A3.313 3.313 0 0 1 17 4.95c.008.126.008.253 0 .38Z"
      />
      <path
        fill="#7F8E94"
        d="M17 5.33c0 .13-.008.259-.024.387A3.313 3.313 0 0 1 15 8.381a3.303 3.303 0 0 1-2.647 0 3.126 3.126 0 0 1-.687-.384 3.313 3.313 0 0 1-1.333-3.06 3.314 3.314 0 0 1 1.333-2.273c.206-.155.43-.284.667-.383a3.303 3.303 0 0 1 2.647 0c.244.098.474.227.686.383A3.313 3.313 0 0 1 17 4.95c.008.126.008.253 0 .38Z"
      />
      <path
        fill="#545C60"
        d="M16.977 4.947A3.313 3.313 0 0 0 15 2.283 3.257 3.257 0 0 0 13.667 2c-.168 0-.335.015-.5.043.283.042.56.12.823.234.241.1.469.232.677.39A3.313 3.313 0 0 1 16 5.727 3.313 3.313 0 0 1 14.667 8c-.206.155-.43.284-.667.383-.263.114-.54.192-.823.234.162.03.326.046.49.05.459.002.913-.092 1.333-.277.238-.102.462-.233.667-.39A3.313 3.313 0 0 0 17 4.94l-.023.007Z"
      />
      <path
        fill="#37474F"
        d="M13.666 6.667a1.333 1.333 0 1 0 0-2.667 1.333 1.333 0 0 0 0 2.667Z"
      />
      <path
        fill="#607D8B"
        d="M15 2.275a3.303 3.303 0 0 0-2.647 0L12.63.608a.333.333 0 0 1 .333-.28h1.434a.333.333 0 0 1 .333.28L15 2.275Zm0 6.112-.277 1.666a.333.333 0 0 1-.33.28H12.96a.333.333 0 0 1-.334-.28l-.276-1.666a3.303 3.303 0 0 0 2.646 0H15Z"
      />
      <path
        fill="#FF9801"
        d="M11.314 2.522 10.1 2.065a.333.333 0 0 0-.403.147l-.273.47c-.14-.017-.28-.017-.424-.017a5.603 5.603 0 0 0-2.283.48A2.333 2.333 0 0 1 9 .332a2.307 2.307 0 0 1 2.314 2.19Z"
      />
      <path
        fill="#607D8B"
        d="M11.666 2.666a3.313 3.313 0 0 0-1.333 2.286L9.03 3.886a.333.333 0 0 1-.077-.427l.447-.77.273-.47a.333.333 0 0 1 .427-.153l1.213.456.353.144Zm6.691 4.545-.72 1.24a.333.333 0 0 1-.403.147l-1.567-.6A3.313 3.313 0 0 0 17 5.71l1.304 1.067a.333.333 0 0 1 .053.433Zm-.077-3.334-1.303 1.066a3.313 3.313 0 0 0-1.31-2.28l1.567-.6a.333.333 0 0 1 .403.147l.72 1.24a.333.333 0 0 1-.077.427Z"
      />
      <path
        fill="currentColor"
        d="M1.227 16.743a1 1 0 0 0 .91.59H6.02a3 3 0 0 0 5.96 0h3.884a1 1 0 0 0 .91-.59 1 1 0 0 0-.18-1.076A6 6 0 0 1 15 12v-1.75a.604.604 0 0 0 .044-.143l.246-1.49c.144-.07.282-.148.414-.237l1.413.53a.667.667 0 0 0 .807-.29l.723-1.243a.667.667 0 0 0-.156-.847l-1.157-.96v-.473l1.173-.96a.666.666 0 0 0 .15-.85l-.73-1.237a.667.667 0 0 0-.81-.297l-1.413.534a3.397 3.397 0 0 0-.413-.237L15.044.56a.667.667 0 0 0-.667-.56h-1.433a.667.667 0 0 0-.667.56l-.246 1.49a3.39 3.39 0 0 0-.414.237l-1.4-.53a.667.667 0 0 0-.807.29l-.173.286H9c-.681.001-1.358.12-2 .35v-.016a2 2 0 0 1 2-2 1.967 1.967 0 0 1 1.564.76A.339.339 0 0 0 11.09 1 2.643 2.643 0 0 0 9 0a2.667 2.667 0 0 0-2.65 2.96A6 6 0 0 0 3 8.333V12a5.943 5.943 0 0 1-1.594 3.667 1 1 0 0 0-.18 1.076ZM9 19.333a2.333 2.333 0 0 1-2.31-2h4.62a2.333 2.333 0 0 1-2.31 2ZM14.384 10H12.95l-.187-1.117a3.557 3.557 0 0 0 1.807 0L14.384 10ZM11.05 7.89 10 8.287l-.72-1.244.86-.71a3.7 3.7 0 0 0 .903 1.557h.007Zm-.384-2.557a3 3 0 1 1 6 0 3 3 0 0 1-6 0Zm7.404 1.714-.72 1.24-1.06-.397a3.7 3.7 0 0 0 .903-1.557l.877.714Zm-.72-4.667.72 1.243-.877.71a3.7 3.7 0 0 0-.903-1.563l1.06-.39ZM12.95.667h1.433l.187 1.116a3.557 3.557 0 0 0-1.807 0L12.95.667Zm-1.907 2.11a3.7 3.7 0 0 0-.904 1.556l-.876-.72.443-.766.023-.037.27-.43 1.044.397ZM1.9 16.097A6.553 6.553 0 0 0 3.667 12V8.333A5.333 5.333 0 0 1 8.85 3l-.163.283a.667.667 0 0 0 .157.847L10 5.097v.473l-1.174.96a.667.667 0 0 0-.15.85l.717 1.237a.667.667 0 0 0 .81.293l1.413-.533c.132.088.27.167.414.236l.246 1.49a.667.667 0 0 0 .667.56h1.39v1.334a6.553 6.553 0 0 0 1.767 4.096c.098.1.123.25.063.377a.333.333 0 0 1-.3.193H2.137a.333.333 0 0 1-.237-.566Z"
      />
      <path
        fill="currentColor"
        d="M15.333 5.335a1.667 1.667 0 1 0-3.333 0 1.667 1.667 0 0 0 3.333 0Zm-2.666 0a1 1 0 1 1 2 0 1 1 0 0 1-2 0Z"
      />
    </svg>
  );
}
