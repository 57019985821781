import TabNav, {MenuItem} from "../../../components/navigation/TabNav";
import PageHeader from "../../../layout/shared/page-header";
import {menuRoutes} from "../../../router/menu";

const menuItems: MenuItem[] = [
    {
        label: 'Información',
        path: '/info',
        permission: {
            group: 'user',
            action: 'edit_user'
        },
    },
    {
        label: 'Permisos',
        path: '/permissions',
        permission: {
            group: 'user',
            action: 'edit_user_permissions'
        },
    },
];


const pageHeader = {
    title: "Editar Usuario",
    breadcrumb: [
        {name: 'Administración'},
        {name: 'Control de acceso'},
        {name: 'Usuarios', href: menuRoutes.users.path},
        {name: 'Editar Usuario'},
    ],
};


export default function UserEditLayout({children}: { children: React.ReactNode }) {

    return (
        <>
            <PageHeader title={pageHeader.title} breadcrumb={pageHeader.breadcrumb}>
            </PageHeader>
            <TabNav menuItems={menuItems} rootPath={'/edit'}/>
            {children}
        </>
    )
}