import { ForwardRefExoticComponent, RefAttributes } from 'react';
import { Input, InputProps } from 'rizzui';

const overwriteLabel = (WrappedComponent: ForwardRefExoticComponent<InputProps & RefAttributes<HTMLInputElement>>) => {
    return ({ label, required = true, ...props }: any) => (
        <WrappedComponent
            label={<span>{label} {required && (<span className="text-red-500">*</span>)} </span>}
            {...props}
        />
    );
};

const RequiredInput = overwriteLabel(Input);

export default RequiredInput;