import React, { useEffect, useState } from 'react';
import { Input } from 'rizzui';

type Props = {
  onSearch(value: string): void,
  placeholder?: string,
  defaultValue?: string,
}

const CustomSearchInput: React.FC<Props> = ({ placeholder = 'Buscar...', onSearch, defaultValue }) => {

  const [searchValue, setSearchValue] = useState<string>(defaultValue || '');
  const [loaded, setLoaded] = useState<boolean>(false);

  useEffect(() => {
    if (loaded) {
      const delaySearch = setTimeout(() => {
        onSearch(searchValue);
      }, 1000);
      return () => clearTimeout(delaySearch);
    }
  }, [searchValue])

  useEffect(() => {
    setSearchValue(defaultValue || '');
  }, [defaultValue]);

  useEffect(() => {
    setLoaded(true);
  }, []);

  return (
    <div>
      <Input placeholder={placeholder} value={searchValue} onChange={(e) => setSearchValue(e.target.value.replace('%', ''))} />
    </div>
  )
}

export default CustomSearchInput;