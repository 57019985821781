import React, { useEffect, useState } from 'react';
import { PiTrashDuotone } from 'react-icons/pi';
import { Button, Input } from 'rizzui';
import { FilterOptions } from '../../../hooks/useFilters';
import CustomSelect from '../../../components/forms/CustomSelect';
import { corporationFunctionOptions } from '../CorporationForm';

interface CorporationsFiltersProps {
    updateFilters: (filters: any) => void
    resetFilters: (limit: any) => void
    filters: FilterOptions
}

const CorporationsFilters: React.FC<CorporationsFiltersProps> = ({ filters, updateFilters, resetFilters }) => {

    return (
        <>
            <Input
                id='search_text'
                type="text"
                label="Buscar"
                className="[&>label>span]:font-medium"
                inputClassName="text-sm"
                onChange={(e) => updateFilters({ search_text: e.target.value })}
                value={filters.filter_filters?.search_text}
            />

            <CustomSelect
                isSearchable
                id={'active'}
                label="Estado"
                options={[
                    { label: 'Todos', value: 'null' }, 
                    { label: 'Activos', value: true }, 
                    { label: 'Desactivados', value: false }
                ]}
                onChange={(e: any) => {
                    if (e.value === 'null') {
                        updateFilters({ active: null });
                        return;
                    }
                    updateFilters({ active: e.value });
                }}
                value={filters.filter_filters?.active !== null
                    ? { label: filters.filter_filters?.active ? 'Activo' : 'Inactivo', value: filters.filter_filters?.active }
                    : { label: 'Todos', value: 'null' }}
            />

            <CustomSelect
                isSearchable
                id={'function'}
                label="Función de la empresa"
                options={corporationFunctionOptions}
                onChange={(e: any) => { updateFilters({ corporation_function: e?.value }); }}
                value={corporationFunctionOptions.find((g: any) => g.value === filters.filter_filters?.corporation_function)}
            />

            <Button
                size="sm"
                onClick={() => {
                    resetFilters(50);
                }}
                variant="flat"
                className="h-9 bg-gray-200/70"
            >
                <PiTrashDuotone className="me-1.5 h-[17px] w-[17px]" /> Limpiar
            </Button>
        </>
    );
};

export default CorporationsFilters;