import PageHeader from "../../../layout/shared/page-header";
import { menuRoutes } from "../../../router/menu";
import { useNavigate, useParams } from "react-router-dom";
import { useCallback, useState } from "react";
import AttivaCard from "../../../components/card/AttivaCard";
import { toast } from "sonner";
import useFetch from "../../../hooks/useFetch";
import { Loader } from "rizzui";
import ProductForm from "../ProductForm";
import {ProductService} from "../../../services/product/productService";
import {Product} from "../../../type/entities/product-type";

const pageHeader = {
    title: "Editar Producto",
    breadcrumb: [
        {
            href: menuRoutes.products.path,
            name: "Productos",
        },
        {
            name: "Editar",
        },
    ],
};

export default function ProductEdit() {

    const { id = "" } = useParams<{ id: string }>();
    const navigate = useNavigate();
    const service = new ProductService();

    const [loading, setLoading] = useState<boolean>(false);

    const [data] = useFetch(useCallback(async () => {
        const response = await service.getProductById(id as string);
        return response.getResponseData() as Product;
    }, [id]));

    const handleSubmit = async (values: any) => {
        setLoading(true);

        try {
            const response = await (
                await service.editProduct(values)
            ).getResponseData();
            if (response.success) {
                setLoading(false);
                navigate(-1);
                setTimeout(() => {
                    toast.success("Producto editado");
                }, 100);
            } else {
                response.data.errors.forEach((error: any) => {
                    toast.error(error.message);
                });
            }
        } catch (error: any) {
            toast.error(error.message);
        } finally {
            setLoading(false);
        }
    };

    if (loading) return <Loader />;

    return (
        <>
            <PageHeader title={pageHeader.title} breadcrumb={pageHeader.breadcrumb} />

            <AttivaCard title="Datos Principales" className="mb-4">
                {(data !== null && data !== undefined) && <ProductForm data={data} submit={handleSubmit} isLoading={loading} />}
            </AttivaCard>
        </>
    );
}