import moment from "moment";

interface TooltipProps {
    visible: boolean;
    x: number;
    y: number;
    nodeData: any;
    onClose: () => void;
}

export const NodeTooltip: React.FC<TooltipProps> = ({ visible, x, y, nodeData, onClose }) => {
    if (!visible || !nodeData) return null;

    return (
        <div
            onMouseLeave={onClose}
            style={{
                position: 'absolute',
                top: y,
                left: x,
                background: 'white',
                border: '1px solid #ccc',
                padding: '10px 15px',
                borderRadius: '5px',
                boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
                minWidth: '120px',
                maxWidth: '240px',
                zIndex: 1000
            }}
        >
            <p className='text-sm font-medium text-gray-500'>Usuarios: {nodeData.usersCount}</p>
            <p className='text-sm font-medium text-gray-500'>Productos: {nodeData.productsCount}</p>
            <p className='text-sm font-medium text-gray-500'>Estados visibles: {nodeData.viewStatesCount}</p>
            <p className='text-sm font-medium text-gray-500'>Estados modificables: {nodeData.editStatesCount}</p>
            <p className='text-sm font-medium text-gray-500'>Creado: {moment(nodeData.createdAt).format('DD/MM/YYYY')}</p>
            {nodeData.updatedAt && <p className='text-sm font-medium text-gray-500'>Actualizado: {moment(nodeData.updatedAt).format('DD/MM/YYYY')}</p>}
        </div>
    );
};