import { useCallback, useState } from "react";
import useFetch from "../../../hooks/useFetch";
import { TutorService } from "../../../services/tutor/tutorService";
import { toast } from "sonner";
import { useFiltersPR } from "../../../components/providers/FiltersProvider";
import { TutorsApiResponse } from "../../../type/entities/tutor-type";
import Page from "../../../layout/Page/Page";
import CustomTable from "../../../components/table/CustomTable";
import { capitalize } from "lodash";
import { formatDistance } from "date-fns";
import { es } from "date-fns/locale";
import moment from "moment";
import { ActionIcon, Switch, Tooltip } from "rizzui";
import { Link } from "react-router-dom";
import { HiOutlineEye } from "react-icons/hi2";
import { menuRoutes } from "../../../router/menu";
import { MdOutlineModeEditOutline } from "react-icons/md";
import DeletePopover from "../../../components/buttons/DeletePopover";
import { FilterDrawerView } from "../../../components/table/components/TableFilter";
import TutorsFilters from "./TutorsFilters";
import useHandleErrors from "../../../hooks/useHandleErrors";
import { availability, tutorType } from "../TutorForm";

interface TutorsListProps {
}

const columnOrder = [
    'id',
    'name',
    'lastName',
    'nifCif',
    'telephone',
    'tutorType',
    'availability',
    'email',
    'town',
    'province',
    'active',
    'lastLogin',
    'createdAt',
    'updatedAt',
];

const TutorsList: React.FC<TutorsListProps> = () => {

    const { handleErrors } = useHandleErrors();
    const { filters, updateFilters, updateFilterOrder, updatePage, updatePageSize, resetFilters } = useFiltersPR();
    const service = new TutorService();

    const [changingStatus, setChangingStatus] = useState<string[]>([]);
    const [openFilters, setOpenFilters] = useState<boolean>(false);

    const [data, loading, error, refetch] = useFetch(useCallback(async () => {
        const response = await service.listTutors(filters);
        return response.getResponseData() as TutorsApiResponse;
    }, [filters]));

    const toggleStatus = async (id: string, status: boolean) => {
        try {
            setChangingStatus([...changingStatus, id]);
            const response = (await service.toggleTutorStatus(id, status)).getResponseData();
            if (response.success) {
                setChangingStatus(changingStatus.filter((item) => item !== id));
                refetch();
                toast.success(status ? "Se ha desactivado el tutor" : "Se ha activado el tutor");
            } else {
                setChangingStatus(changingStatus.filter((item) => item !== id));
                toast.error(response.message);
            }
        } catch (error: any) {
            setChangingStatus(changingStatus.filter((item) => item !== id));
            toast.error(error.message);
        }
    };

    const handleDelete = async (id: string) => {
        try {
            const response = await (await service.deleteTutor(id)).getResponseData();
            if (response.success) {
                toast.success('Tutor eliminado correctamente');
                refetch();
            } else {
                handleErrors(response);
            }
        } catch (error) {
            handleErrors(error);
        }
    };

    const handleMultiDelete = async (ids: string[]) => {
        try {
            const response = await (await service.deleteMultiTutors(ids)).getResponseData();
            if (response.success) {
                toast.success('Tutores eliminados correctamente');
                refetch();
            } else {
                handleErrors(response);
            }
        } catch (error) {
            handleErrors(error);
        }
    };

    /**
     * Order the data to match the column order
     */
    const orderedData = () => data?.data.map((row: any) => {
        const orderedRow: any = {};
        columnOrder.forEach((key) => {
            orderedRow[key] = row[key];
        });
        return orderedRow;
    });

    return (
        <Page container='fluid'>
            {data !== undefined && (
                <CustomTable
                    id={'tutor-table'}
                    columnOrder={columnOrder}
                    data={orderedData()}
                    isLoading={loading}
                    columnsNotShown={['lastName']}
                    overrideColumns={[
                        {
                            key: 'tutorType',
                            render: (row: any) => { return tutorType.find((type) => type.value === row.row.original.tutorType)?.label },
                        },
                        {
                            key: 'availability',
                            render: (row: any) => {
                                return row.row.original.availability.map((a: any) => availability.find((av) => av.value === a)?.label).join(', ');
                            },
                        },
                        {
                            key: 'lastLogin',
                            render: (row: any) => {
                                const date = row.row.original?.lastLoginAt?.date;
                                return date ? capitalize(formatDistance(new Date(date), new Date(), { addSuffix: true, locale: es })) : 'Nunca';
                            },
                        },
                        {
                            key: 'createdAt',
                            render: (row: any) => {
                                const date = row.row.original?.createdAt?.date;
                                return date ? moment(date).format('HH:mm DD/MM/YY') : 'N/A';
                            },
                        },
                        {
                            key: 'updatedAt',
                            render: (row: any) => {
                                const date = row.row.original?.updatedAt?.date;
                                return date ? moment(date).format('HH:mm DD/MM/YY') : 'N/A';
                            },
                        },
                        {
                            key: 'active',
                            render: (row: any) => {
                                return (
                                    <Tooltip
                                        size="sm"
                                        content={row.row.original.active ? 'Desactivar tutor' : 'Activar tutor'}
                                        placement="top"
                                        color="invert"
                                    >
                                        <Switch
                                            id={row.row.original.id}
                                            checked={row.row.original.active}
                                            onChange={() => toggleStatus(row.row.original.id, row.row.original.active)}
                                        />
                                    </Tooltip>
                                );
                            },
                        },
                    ]}
                    actions={[
                        {
                            label: 'View',
                            permissions: { group: 'tutors', permission: 'get_tutors' },
                            render: (row: any) => {
                                return (
                                    <Tooltip
                                        size="sm"
                                        content={'Ver perfil'}
                                        placement="top"
                                        color="invert"
                                    >
                                        <Link
                                            to={`${menuRoutes.tutors.path}/${row.id}/profile${menuRoutes.tutors.profile.info}`}>
                                            <ActionIcon
                                                as="span"
                                                size="sm"
                                                variant="outline"
                                                className="hover:!border-gray-900 hover:text-gray-700"
                                            >
                                                <HiOutlineEye className="h-4 w-4" size={20} />
                                            </ActionIcon>
                                        </Link>
                                    </Tooltip>
                                );
                            },
                        },
                        {
                            label: 'Edit',
                            permissions: { group: 'tutors', permission: 'edit_tutors' },
                            render: (row: any) => {
                                return (
                                    <Tooltip
                                        size="sm"
                                        content={'Editar tutor'}
                                        placement="top"
                                        color="invert"
                                    >
                                        <Link to={`${menuRoutes.tutors.path}/${row.id}/edit`}>
                                            <ActionIcon
                                                as="span"
                                                size="sm"
                                                variant="outline"
                                                className="hover:!border-gray-900 hover:text-gray-700"
                                            >
                                                <MdOutlineModeEditOutline className="h-4 w-4" size={20} />
                                            </ActionIcon>
                                        </Link>
                                    </Tooltip>
                                );
                            },
                        },
                        {
                            label: 'Delete',
                            permissions: { group: 'tutors', permission: 'delete_tutors' },
                            render: (row: any) => {
                                return (
                                    <Tooltip
                                        size="sm"
                                        content={'Eliminar'}
                                        placement="top"
                                        color="invert"
                                    >
                                        <div>
                                            <DeletePopover
                                                title={`Eliminar tutor`}
                                                description={`¿Estás seguro de que deseas eliminar a ${row.name}?`}
                                                onDelete={() => handleDelete(row.id)}
                                            />
                                        </div>
                                    </Tooltip>
                                );
                            },
                        },
                    ]}
                    handleMultipleDelete={handleMultiDelete}
                    filters={filters}
                    updateFilters={updateFilters}
                    updateFilterOrder={updateFilterOrder}
                    defaultOrder={filters.filter_order || undefined}
                    paginationData={{
                        pageSize: filters.limit,
                        currentPage: filters.page,
                        pageCount: (data as TutorsApiResponse) ? data.lastPage : 1,
                        totalCount: data?.totalRegisters,
                        handlePagination: updatePage,
                        handlePerPage: updatePageSize,
                    }}
                    toggleFilters={() => setOpenFilters(!openFilters)}
                />
            )}

            <FilterDrawerView isOpen={openFilters} setOpenDrawer={setOpenFilters} drawerTitle={'Filtros Tutores'}>
                <div className="grid grid-cols-1 gap-6">
                    <TutorsFilters filters={filters} updateFilters={updateFilters} resetFilters={resetFilters} />
                </div>
            </FilterDrawerView>
        </Page>
    );
}

export default TutorsList;