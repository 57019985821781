export default function PageBlankIcon({
  strokeWidth,
  ...props
}: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      fill="none"
      {...props}
    >
      <path
        fill="#E7F2F7"
        fillRule="evenodd"
        d="M13.332 2.965c.345 0 .624.28.624.624v12.805c0 .34-.276.616-.616.616H3.66a.627.627 0 0 1-.628-.627V6.242c.945.683 1.821.707 2.653.312-.485-1.22-.435-2.306.29-3.224a.937.937 0 0 1 .736-.357c1.255-.008 5.104-.008 6.62-.008Z"
        clipRule="evenodd"
      />
      <path
        fill="#D1E3F5"
        fillRule="evenodd"
        d="M12.948 2.965h.384c.345 0 .624.28.624.624v12.805c0 .34-.276.616-.616.616H3.66a.627.627 0 0 1-.628-.627v-.743a.627.627 0 0 0 .597.434h9.087c.34 0 .616-.276.616-.616V3.54a.624.624 0 0 0-.384-.576Zm-7.263 3.59C5.4 7.07 4 7.642 3.032 7.107v-.866s1.59.314 2.653.312Z"
        clipRule="evenodd"
      />
      <path
        fill="#88B3DD"
        fillRule="evenodd"
        d="M6.517 2.992c-1.363.305-3.083 1.956-3.485 3.248.945.683 1.821.707 2.653.312-.485-1.22-.435-2.306.29-3.223.178-.226.35-.285.542-.337Z"
        clipRule="evenodd"
      />
      <path
        fill="#F9B22A"
        fillRule="evenodd"
        d="M12.806 5.918a.255.255 0 0 0-.457 0l-.722 1.444a.255.255 0 0 1-.114.115l-1.444.722a.255.255 0 0 0 0 .456l1.444.722c.05.025.09.065.114.114l.722 1.444a.255.255 0 0 0 .457 0l.722-1.444a.255.255 0 0 1 .114-.114l1.444-.722a.255.255 0 0 0 0-.456l-1.444-.722a.255.255 0 0 1-.114-.115l-.722-1.444Z"
        clipRule="evenodd"
      />
      <path
        fill="#FBC866"
        fillRule="evenodd"
        d="M12.577 5.777a.255.255 0 0 0-.228.141l-.722 1.444a.255.255 0 0 1-.114.115l-1.444.722a.255.255 0 0 0-.141.228l1.944-.131a.755.755 0 0 0 .705-.754V5.777Z"
        clipRule="evenodd"
      />
      <path
        fill="#F9B22A"
        fillRule="evenodd"
        d="M14.83 11.528a.255.255 0 0 0-.456 0l-.514 1.028a.255.255 0 0 1-.114.114l-1.028.514a.255.255 0 0 0 0 .456l1.028.514c.05.025.09.065.114.114l.514 1.028a.255.255 0 0 0 .457 0l.514-1.028a.255.255 0 0 1 .114-.114l1.028-.514a.256.256 0 0 0 0-.456l-1.028-.514a.255.255 0 0 1-.114-.114l-.514-1.028Z"
        clipRule="evenodd"
      />
      <path
        fill="#FBC96B"
        fillRule="evenodd"
        d="M14.602 11.387a.255.255 0 0 0-.228.14l-.514 1.029a.255.255 0 0 1-.114.114l-1.028.514a.255.255 0 0 0-.14.228h1.361a.663.663 0 0 0 .663-.663v-1.362Z"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M13.722 9.23v3.135a.234.234 0 0 0 .468 0V9.23a.234.234 0 0 0-.468 0Z"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M13.722 14.46v1.934c0 .21-.171.381-.382.381H3.66a.393.393 0 0 1-.394-.393V6.67c.89.503 1.723.473 2.52.095a.234.234 0 0 0 .116-.298c-.45-1.132-.416-2.14.256-2.992a.703.703 0 0 1 .553-.268h.001c1.254-.008 5.103-.008 6.62-.008a.39.39 0 0 1 .39.39v4.047a.234.234 0 0 0 .468 0V3.59a.858.858 0 0 0-.858-.859c-1.518 0-5.368 0-6.623.008a1.17 1.17 0 0 0-.918.447c-.735.93-.835 2.02-.404 3.242-.698.265-1.431.193-2.218-.375a.234.234 0 0 0-.371.19v10.14a.862.862 0 0 0 .861.862h9.681c.47 0 .85-.38.85-.85V14.46a.234.234 0 0 0-.468 0Z"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M13.016 5.814a.49.49 0 0 0-.876 0l-.722 1.443a.022.022 0 0 1-.01.01l-1.443.722a.49.49 0 0 0 0 .875l1.444.722a.022.022 0 0 1 .01.01l.721 1.444a.49.49 0 0 0 .876 0l.722-1.444a.022.022 0 0 1 .01-.01l1.443-.722a.49.49 0 0 0 0-.875l-1.444-.722a.022.022 0 0 1-.01-.01l-.721-1.443Zm-.42.209.723 1.444a.49.49 0 0 0 .219.219l1.444.722c.007.003.011.01.011.019a.021.021 0 0 1-.011.019l-1.444.721a.49.49 0 0 0-.22.22l-.721 1.443c-.004.007-.011.012-.02.012s-.014-.005-.018-.012l-.722-1.444a.49.49 0 0 0-.219-.219l-1.444-.721a.021.021 0 0 1 0-.038l1.444-.722a.49.49 0 0 0 .219-.22l.722-1.443a.021.021 0 0 1 .038 0Zm2.959 6.428-.514-1.028a.49.49 0 0 0-.876 0l-.513 1.028a.022.022 0 0 1-.01.01l-1.028.513a.49.49 0 0 0 0 .875l1.028.514a.021.021 0 0 1 .01.01l.513 1.028a.49.49 0 0 0 .876 0l.514-1.028a.02.02 0 0 1 .01-.01l1.027-.514a.49.49 0 0 0 0-.875l-1.028-.514a.021.021 0 0 1-.01-.01Zm-.933-.819.514 1.028a.49.49 0 0 0 .219.219l1.028.514a.021.021 0 0 1 0 .038l-1.028.514a.49.49 0 0 0-.219.218l-.514 1.028a.021.021 0 0 1-.038 0l-.514-1.028a.49.49 0 0 0-.219-.218l-1.027-.514a.021.021 0 0 1 0-.038l1.027-.514a.49.49 0 0 0 .22-.219l.513-1.028c.004-.007.011-.011.02-.011s.014.004.018.011ZM6.465 2.764c-.715.16-1.528.668-2.211 1.311-.683.643-1.235 1.418-1.446 2.096a.234.234 0 0 0 .447.139c.192-.615.7-1.312 1.32-1.894.617-.581 1.346-1.05 1.993-1.196a.234.234 0 0 0-.103-.456Z"
        clipRule="evenodd"
      />
    </svg>
  );
}
