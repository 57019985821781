import React, { Fragment } from "react";
import * as yup from "yup";
import { useNavigate, useParams } from "react-router-dom";
import Page from "../../layout/Page/Page";
import Spinner from "../../components/bootstrap/Spinner";
import FormFooter from "../_layout/_footers/form-footer";
import { menuRoutes } from "../../router/menu";
import FormGroup from "../../layout/shared/form-group";
import RequiredInput from "../../components/forms/InputHOC";
import { useFormik } from "formik";
import { Product } from "../../type/entities/product-type";
import CustomSelect from "../../components/forms/CustomSelect";
import useProductTypes from "../../hooks/api-calls/useProductTypes";
import { Switch, Textarea, Tooltip } from "rizzui";
import useCorporations from "../../hooks/api-calls/useCorporations";

interface CreateFormProps {
    isLoading: boolean;
    submit: Function;
    data?: Product | undefined;
}

const schema = yup.object({
    name: yup.string().min(1, 'Demasiado corto').max(200, 'Demasiado largo').required('Campo obligatorio'),
    productTypeId: yup.string().required('Campo obligatorio'),
    code: yup.string().min(1, 'Demasiado corto').max(200, 'Demasiado largo')
        .when('productTypeId', {
            is: '26a41eb6-a9d1-4d2f-88e5-1bb59bdb9ae1',
            then: schema => schema.required('Campo obligatorio para productos bonificados'),
            otherwise: schema => schema.notRequired().nullable(),
        }),
    providingCorporations: yup.array().when('productTypeId', {
        is: '26a41eb6-a9d1-4d2f-88e5-1bb59bdb9ae1',
        then: schema => schema.required('Campo obligatorio para productos bonificados').length(1, 'Debe seleccionar al menos una empresa'),
        otherwise: schema => schema.notRequired().nullable(),
    }),
    weeklyHours: yup.number().min(0, 'Debe ser mayor que 0')
        .when('productTypeId', {
            is: '26a41eb6-a9d1-4d2f-88e5-1bb59bdb9ae1',
            then: schema => schema.required('Campo obligatorio para productos bonificados'),
            otherwise: schema => schema.notRequired().nullable(),
        }),
    amount: yup.number().min(0, 'Debe ser mayor que 0').required('Campo obligatorio'),
});

const ProductForm: React.FC<CreateFormProps> = ({ isLoading, submit, data }) => {

    const { id = '' } = useParams<{ id: string }>();
    const { getProductTypesList } = useProductTypes();
    const { getCorporationsList } = useCorporations();
    const mode = data ? 'Editar' : 'Crear';
    const navigate = useNavigate();

    const formik = useFormik({
        initialValues: {
            productId: data?.id ?? '',
            name: data?.name ?? '',
            productTypeId: data?.productType?.id ?? '26a41eb6-a9d1-4d2f-88e5-1bb59bdb9ae1',
            code: data?.code ?? '',
            providingCorporations: data?.productHasCorporations?.map(item => item.corporation.id) ?? [],
            weeklyHours: data?.weeklyHours ?? null,
            amount: data?.amount ?? '',
            active: data?.active ?? true,
            description: data?.description ?? '',
        },
        validationSchema: schema,
        onSubmit: values => { submit(values) },
    });

    const getContent = () => {
        return (
            <>
                <div className="@container">
                    <form onSubmit={formik.handleSubmit}>
                        <div className="mb-10 grid gap-7 divide-y-2 divide-dashed divide-gray-200 @2xl:gap-9 @3xl:gap-11 font-medium">
                            <FormGroup
                                title="Información General"
                                description="Datos principales"
                                className='pt-6'
                            >
                                <RequiredInput
                                    id='name'
                                    type="text"
                                    label="Nombre"
                                    className="[&>label>span]:font-medium"
                                    inputClassName="text-sm"
                                    onChange={formik.handleChange}
                                    value={formik.values.name}
                                    error={formik.errors.name}
                                />

                                <CustomSelect
                                    isSearchable
                                    id={'productType'}
                                    label="Tipo de producto"
                                    value={{ value: formik.values.productTypeId, label: getProductTypesList()?.find((g: any) => g.value === formik.values.productTypeId)?.label }}
                                    options={getProductTypesList()}
                                    onChange={(e: any) => { formik.setFieldValue('productTypeId', e?.value); }}
                                    required
                                    error={formik.errors.productTypeId}
                                />

                                <RequiredInput
                                    id='code'
                                    type="text"
                                    label="Código A.F."
                                    className={`[&>label>span]:font-medium ${!(formik.values.productTypeId !== null && formik.values.productTypeId === '26a41eb6-a9d1-4d2f-88e5-1bb59bdb9ae1') ? 'hidden' : ''}`}
                                    inputClassName="text-sm"
                                    onChange={formik.handleChange}
                                    value={formik.values.code}
                                    error={formik.errors.code}
                                    required={formik.values.productTypeId !== null && formik.values.productTypeId === '26a41eb6-a9d1-4d2f-88e5-1bb59bdb9ae1'}
                                />

                                <CustomSelect
                                    isSearchable
                                    isMulti
                                    id={'corporations'}
                                    label="Empresas Impartidoras"
                                    display={!(formik.values.productTypeId !== null && formik.values.productTypeId === '26a41eb6-a9d1-4d2f-88e5-1bb59bdb9ae1') ? false : true}
                                    options={getCorporationsList()}
                                    onChange={(selectedOptions: any) => {
                                        const selectedIds = selectedOptions ? selectedOptions.map((option: any) => option.value) : [];
                                        formik.setFieldValue('providingCorporations', selectedIds);
                                    }}
                                    value={
                                        formik.values.providingCorporations ?
                                            getCorporationsList().filter((corporation: any) => formik.values.providingCorporations.includes(corporation.value))
                                            : []
                                    }
                                    error={formik.errors.providingCorporations}
                                    required={formik.values.productTypeId !== null && formik.values.productTypeId === '26a41eb6-a9d1-4d2f-88e5-1bb59bdb9ae1'}
                                />

                                <RequiredInput
                                    id='weeklyHours'
                                    type="number"
                                    label="Horas Semanales"
                                    className={`[&>label>span]:font-medium ${!(formik.values.productTypeId !== null && formik.values.productTypeId === '26a41eb6-a9d1-4d2f-88e5-1bb59bdb9ae1') ? 'hidden' : ''}`}
                                    inputClassName="text-sm"
                                    onChange={formik.handleChange}
                                    value={formik.values.weeklyHours}
                                    error={formik.errors.weeklyHours}
                                    required={formik.values.productTypeId !== null && formik.values.productTypeId === '26a41eb6-a9d1-4d2f-88e5-1bb59bdb9ae1'}
                                />

                                <RequiredInput
                                    id='amount'
                                    type="number"
                                    label="Importe"
                                    className="[&>label>span]:font-medium"
                                    inputClassName="text-sm"
                                    onChange={formik.handleChange}
                                    value={formik.values.amount}
                                    error={formik.errors.amount}
                                />

                                <Tooltip
                                    size="sm"
                                    content={formik.values.active ? 'Desactivar producto' : 'Activar producto'}
                                    placement="top"
                                    color="invert"
                                >
                                    <Switch
                                        id={id}
                                        label={formik.values.active ? 'Activo' : 'Desactivado'}
                                        checked={formik.values.active}
                                        onChange={() => formik.setFieldValue('active', !formik.values.active)}
                                    />
                                </Tooltip>

                            </FormGroup>
                            <FormGroup
                                title="Descripción"
                                description="Información adicional"
                                className='pt-6'
                                childClassName="col-span-full grid gap-4 @2xl:grid-cols-1 @4xl:col-span-8 @4xl:gap-5 xl:gap-7"
                            >
                                <Textarea
                                    id='description'
                                    className="[&>label>span]:font-medium "
                                    onChange={formik.handleChange}
                                    value={formik.values.description}
                                    error={formik.errors.description}
                                />
                            </FormGroup>
                        </div>
                    </form>
                </div>
            </>
        )
    };

    return (
        <Fragment>
            <Page container="fluid">
                {(mode === "Editar" && !data) && <Spinner />}
                {(mode === "Editar" && data) && getContent()}
                {mode === "Crear" && getContent()}
            </Page>
            <FormFooter
                submitBtnText={mode + ' Producto'}
                handleCancelBtn={() => navigate(menuRoutes.products.path)}
                handleSubmitBtn={formik.submitForm} isLoading={isLoading}
            />
        </Fragment>
    )
};

export default ProductForm;