import React, { useCallback, useEffect, useState } from 'react';
import Page from '../../../layout/Page/Page';
import { useFiltersPR } from '../../../components/providers/FiltersProvider';
import useFetch from '../../../hooks/useFetch';
import { FilterDrawerView } from '../../../components/table/components/TableFilter';
import Tree from 'react-d3-tree';
import { useWindowDimensions } from '../../../hooks/useWindowDimensions';
import { Loader } from '../../../components/loader/SpinnerLogo';
import classNames from 'classnames';
import { usePrivilege } from '../../../components/priviledge/PriviledgeProvider';
import { DepartmentService } from '../../../services/departments/departmentService';
import DepartmentForm from '../DepartmentForm';
import DepartmentsFilters from './DepartmentsFilters';
import { AuthState } from '../../../redux/authSlice';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import { InfoModal } from './components/InfoModal';
import { NodeTooltip } from './components/NodeTooltip';
import useDepartmentTree from '../../../hooks/customs/departments/useDepartmentTree';
import { CopyConfigModal } from './components/CopyConfigModal';

interface DepartmentsListProps {
    isOpen: boolean;
    setIsOpen: (value: boolean) => void;
    openFilters: boolean;
    setOpenFilters: any;
}

const DepartmentsList: React.FC<DepartmentsListProps> = ({ isOpen, setIsOpen, openFilters, setOpenFilters }) => {

    const { user }: AuthState = useSelector((state: RootState) => state.auth);
    const { userCan } = usePrivilege();
    const { filters, updateFilters, resetFilters } = useFiltersPR();
    const { width, height } = useWindowDimensions();

    const [departments, setDepartments] = useState<any | undefined>(undefined); // Se reintroduce el estado de departments
    const [tooltip, setTooltip] = useState({ visible: false, x: 0, y: 0, nodeData: null });
    const [info, setInfo] = useState<boolean>(false);
    const [copyConfig, setCopyConfig] = useState<boolean>(false);
    const [nodeId, setNodeId] = useState<string | undefined>(undefined);

    const [data, loading, error, refetch] = useFetch(useCallback(async () => {
        const response = await new DepartmentService().listDepartments(filters);
        return response.getResponseData();
    }, [filters]));

    // Hook para generar el árbol de departamentos
    const generateNetworkTree = useDepartmentTree(data?.data, user);

    /**
     * Controla el clic en los nodos del árbol
     */
    const handleNodeClick = useCallback((nodeData: any) => {
        if (nodeData?.data?.id === '1') return; // No hacer nada si es el nodo raíz
        if (userCan('edit_departments', 'departments')) {
            setNodeId(nodeData?.data?.id);
            setIsOpen(true);
        }
    }, [userCan]);

    /**
     * Controla el hover sobre los nodos del árbol
     */
    const handleNodeMouseOver = useCallback((nodeData: any, evt: any) => {
        if (nodeData?.data?.id === '1') return; // No hacer nada si es el nodo raíz
        const { x, y } = evt.target.getBoundingClientRect();
        setTooltip({
            visible: true,
            x: x + window.scrollX + 35,
            y: y + window.scrollY,
            nodeData: nodeData.data,
        });
    }, []);

    /**
     * Actualiza el árbol cuando se obtienen los datos
     */
    useEffect(() => {
        if (data?.data.length > 0) {
            setDepartments(generateNetworkTree()); // Se asigna el árbol generado a 'departments'
        } else {
            setDepartments(undefined);
        }
    }, [data, generateNetworkTree]);

    if (loading) return <Loader height={'70'} />;

    return (
        <Page container="fluid">
            <div className="grid grid-cols-3 gap-6 static">
                <div id="treeWrapper" className={classNames('col-span-3 relative', { 'md:col-span-2': isOpen })}>
                    {/* Renderizar árbol o mensaje vacío */}
                    {departments ? (
                        <Tree
                            data={departments}
                            orientation="vertical"
                            pathFunc={'step'}
                            translate={{ x: width / 3, y: height / 6 }}
                            separation={{ siblings: 1, nonSiblings: 1 }}
                            nodeSize={{ x: 300, y: 150 }}
                            shouldCollapseNeighborNodes
                            collapsible={false}
                            hasInteractiveNodes
                            scaleExtent={{ min: 0.4, max: 3 }} // Zoom scale
                            onNodeClick={handleNodeClick}
                            onNodeMouseOver={handleNodeMouseOver}
                            onNodeMouseOut={() => setTooltip({ ...tooltip, visible: false })}
                            rootNodeClassName="node__root"
                            branchNodeClassName="node__branch"
                            leafNodeClassName="node__leaf mt-10"
                        />
                    ) : (
                        <div className="flex justify-center items-center h-[calc(100vh-100px)]">
                            <p className="text-gray-500">No se han encontrado departamentos</p>
                        </div>
                    )}
                </div>

                <NodeTooltip {...tooltip} onClose={() => setTooltip({ ...tooltip, visible: false })} />

                <InfoModal isOpen={info} nodeData={tooltip.nodeData} onClose={() => setInfo(false)} />

                <CopyConfigModal department={tooltip.nodeData} isOpen={copyConfig} onClose={() => setCopyConfig(false)} refetch={refetch} />

                <div className="col-span-3 md:col-span-1">
                    <DepartmentForm id={nodeId} setNodeId={setNodeId} setCopyConfig={setCopyConfig} setInfo={setInfo} isOpen={isOpen} setIsOpen={setIsOpen} refetch={refetch} />
                    C</div>
            </div>

            <FilterDrawerView isOpen={openFilters} setOpenDrawer={setOpenFilters} drawerTitle="Filtros Empresas">
                <DepartmentsFilters filters={filters} updateFilters={updateFilters} resetFilters={resetFilters} />
            </FilterDrawerView>
        </Page>
    );
};

export default DepartmentsList;