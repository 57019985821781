import React from 'react';
import FormGroup from '../../../layout/shared/form-group';
import CustomSelect from '../../../components/forms/CustomSelect';
import RequiredInput from '../../../components/forms/InputHOC';
import { Button } from 'rizzui';
import { FaRegTrashAlt } from 'react-icons/fa';
import { MdAdd } from 'react-icons/md';
import useProducts from '../../../hooks/api-calls/useProducts';
import classNames from 'classnames';

interface TutorProductsFormProps {
    formik: any;
    product: { id: string, price: string };
    setProduct: (product: { id: string, price: string }) => void;
}

const TutorProductsForm: React.FC<TutorProductsFormProps> = ({ formik, product, setProduct }) => {

    const { getProductsList } = useProducts();

    return (
        <FormGroup
            title="Productos"
            description="Productos que el tutor puede impartir"
            className={classNames('pt-6', { 'hidden': formik.values.tutorType === '' })}
        >
            <div className={classNames('col-span-2', { 'border-gray-500': formik.values.products.length > 0 })} /* style={{ borderBottom: '1px solid lightgray' }} */>
                {formik.values.products.map((productItem: any, index: number) => {
                    return (
                        <div key={index} className="grid grid-cols-4 gap-4 mb-4">
                            <CustomSelect
                                id={`products[${index}].id`}
                                required
                                label="Producto"
                                options={getProductsList()}
                                onChange={(e: any) => {
                                    const updatedProducts = formik.values.products.map((product: any, i: number) => {
                                        if (i === index) {
                                            return { ...product, id: e.value };
                                        }
                                        return product;
                                    });
                                    formik.setFieldValue('products', updatedProducts);
                                }}
                                value={{ value: productItem.id, label: getProductsList().find((p: any) => p.value === productItem.id)?.label }}
                                containerClassName='col-span-2'
                            />

                            {formik.values.tutorType === 'external' && (
                                <RequiredInput
                                    id={`products[${index}].price`}
                                    type="number"
                                    label="Precio"
                                    className="[&>label>span]:font-medium"
                                    inputClassName="text-sm"
                                    onChange={formik.handleChange}
                                    value={productItem.price}
                                />
                            )}

                            <div className="flex justify-end items-end">
                                <Button
                                    color="danger"
                                    size='sm'
                                    onClick={() => {
                                        const updatedProducts = formik.values.products.filter((product: any, i: number) => i !== index);
                                        formik.setFieldValue('products', updatedProducts);
                                    }}
                                >
                                    <FaRegTrashAlt />
                                </Button>
                            </div>
                        </div>
                    )
                })}
            </div>

            <div className='col-span-2'>
                <div className="grid grid-cols-4 gap-4 mb-4">
                    <CustomSelect
                        id='productsId'
                        isClearable
                        required
                        label="Producto"
                        options={getProductsList()}
                        onChange={(e: any) => { setProduct({ id: e?.value, price: '' }) }}
                        value={{ value: product.id, label: getProductsList().find((p: any) => p.value === product.id)?.label }}
                        containerClassName='col-span-2'
                    />

                    {formik.values.tutorType === 'external' && (
                        <RequiredInput
                            id='price'
                            type="number"
                            label="Precio"
                            className="[&>label>span]:font-medium"
                            inputClassName="text-sm"
                            onChange={(e: any) => setProduct({ id: product.id, price: e.target.value })}
                            value={product.price}
                        />
                    )}

                    <div className="flex justify-end items-end">
                        <Button
                            color='secondary'
                            size='sm'
                            disabled={product.id === '' || (formik.values.tutorType === 'external' && product.price === '')}
                            onClick={() => {
                                formik.setFieldValue('products', [...formik.values.products, product]);
                                setProduct({ id: '', price: '' });
                            }}
                        //className="inline-flex items-center px-4 py-2 text-sm font-medium text-white bg-blue-400 border border-transparent rounded-md shadow-sm hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                        >
                            <MdAdd className='h-5 w-5' />
                        </Button>
                    </div>
                </div>
            </div>
        </FormGroup>
    );
};

export default TutorProductsForm;