import { AxiosResponse } from 'axios';
import { RestServiceConnection } from '../restServiceConnection';

const ENDPOINT = '/statuses';

export class StateService extends RestServiceConnection {

    listStates = async (filters?: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/list',
            data: filters,
        }, true) as AxiosResponse;
        return this;
    }

    getStateById = async (statusId: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/get',
            data: { statusId },
            headers: { "Content-Type": "application/json" }
        }, true);
        return this;
    }

    toggleStateStatus = async (statusId: string, status: boolean) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/toggle',
            data: { statusId, active: status },
            headers: { "Content-Type": "application/json" }
        }, true);
        return this;
    }

    createState = async (user: FormData) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/create',
            data: user,
            headers: { "Content-Type": "application/json" }
        }, true);
        return this;
    }

    editState = async (user: FormData) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/edit',
            data: user,
            headers: { "Content-Type": "application/json" }
        }, true);
        return this;
    }

    deleteState = async (statusId: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/delete',
            data: { statusId },
            headers: { "Content-Type": "application/json" }
        }, true);
        return this;
    }

    deleteMultiStates = async (statusesIds: string[]) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/delete-multi',
            data: { statusesIds }
        }, true);
        return this;
    }

    toggleInitialState = async (statusId: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/toggle-initial-status',
            data: { statusId },
            headers: { "Content-Type": "application/json" }
        }, true);
        return this;
    }

    toggleSatisfactoryState = async (statusId: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/toggle-satisfactory-status',
            data: { statusId },
            headers: { "Content-Type": "application/json" }
        }, true);
        return this;
    }

    toggleUnsatisfactoryState = async (statusId: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/toggle-unsatisfactory-status',
            data: { statusId },
            headers: { "Content-Type": "application/json" }
        }, true);
        return this;
    }

    updateStateOrder = async (statusId: string, action: 'up' | 'down') => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/change-status-order',
            data: { statusId, action },
            headers: { "Content-Type": "application/json" }
        }, true);
        return this;
    }

}