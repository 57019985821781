export default function WalkmanWithExclamationIcon({
  ...props
}: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      fill="none"
      {...props}
    >
      <path
        fill="#7776D0"
        d="M12.127 19.707h1.555a.841.841 0 1 0 0-1.682h-1.555a.841.841 0 1 0 0 1.682Z"
      />
      <path
        fill="#D9DDFF"
        d="M18.87 14.645h-1.302v-5.48c0-4.175-3.394-7.571-7.565-7.571-4.172 0-7.565 3.396-7.565 7.57v5.48H1.136v-5.48c0-4.892 3.978-8.872 8.867-8.872 4.889 0 8.866 3.98 8.866 8.872v5.48Z"
      />
      <path
        fill="#7776D0"
        d="M3.272 9.02H1.95c-.914 0-1.656.742-1.656 1.657v2.83c0 .916.742 1.657 1.656 1.657h1.323V9.02Z"
      />
      <path
        fill="#E8F3FF"
        d="M3.272 8.157c.45 0 .814.364.814.814v6.145a.814.814 0 0 1-1.627 0V8.97c0-.45.364-.814.813-.814Z"
      />
      <path
        fill="#7776D0"
        d="M16.727 15.063h1.323c.915 0 1.657-.742 1.657-1.657v-2.83c0-.916-.742-1.657-1.656-1.657h-1.323v6.144Z"
      />
      <path
        fill="#E8F3FF"
        d="M16.727 15.93a.814.814 0 0 1-.813-.814V8.97a.814.814 0 1 1 1.627 0v6.145c0 .45-.364.814-.814.814Z"
      />
      <path
        fill="#FF8CCC"
        d="M10 8.015a4.106 4.106 0 0 1 2.78 7.128v1.082l-.98-.413A4.106 4.106 0 1 1 10 8.016Z"
      />
      <path
        fill="#FF78C2"
        d="M10 8.015c-.267 0-.528.026-.781.075a4.107 4.107 0 0 1 3.325 4.031c0 1.96-1.469 3.656-3.336 4.03a4.13 4.13 0 0 0 2.592-.34l.98.414v-1.082A4.106 4.106 0 0 0 10 8.016Z"
      />
      <path
        fill="#FFD54D"
        d="M14.201.291a3.493 3.493 0 0 0-2.365 6.064v.921l.834-.352A3.493 3.493 0 1 0 14.202.291Z"
      />
      <path
        fill="#FFC919"
        d="M14.201.291c-.268 0-.53.03-.781.088a3.494 3.494 0 0 1 0 6.81 3.493 3.493 0 1 0 .781-6.898Z"
      />
      <path
        className="stroke-black dark:stroke-white"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={0.6}
        d="M17.542 13.938c-.003.985.036 1.961-.003 2.938a2.158 2.158 0 0 1-1.797 1.953c-.39.04-.82.04-1.218.027m-2.397.851h1.555a.841.841 0 1 0 0-1.682h-1.555a.841.841 0 1 0 0 1.682ZM4.62 2.121A8.872 8.872 0 0 1 7.14.77a8.86 8.86 0 0 1 5.19-.164M1.136 9.165c-.003-2.096.739-4.206 2.142-5.778m8.008-1.684a7.58 7.58 0 0 0-1.283-.11c-4.171 0-7.565 3.397-7.565 7.571v5.48m15.126-5.726a7.524 7.524 0 0 0-.64-2.808m.676-1.516a8.822 8.822 0 0 1 1.268 4.426"
      />
      <path
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={0.6}
        d="M2.438 9.02h-.489c-.914 0-1.656.742-1.656 1.657v2.83c0 .916.742 1.657 1.656 1.657h.51m.813-7.007c.45 0 .814.364.814.814v6.145a.814.814 0 0 1-1.627 0V8.97c0-.45.364-.814.813-.814Zm14.27 6.905h.509c.914 0 1.656-.741 1.656-1.656v-2.83c0-.916-.742-1.657-1.656-1.657h-.51m0 3.349V8.97a.814.814 0 1 0-1.627 0v6.145a.814.814 0 1 0 1.627 0v-1.289M10 8.015a4.106 4.106 0 0 1 2.78 7.128v1.082l-.98-.413A4.106 4.106 0 1 1 10 8.016Zm-.784 2.465h1.568m-1.568 3.286h1.568M8.377 12.12h3.244m2.58-11.83a3.493 3.493 0 0 0-2.366 6.064v.921l.834-.352A3.493 3.493 0 1 0 14.202.291Z"
      />
      <path
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={0.6}
        d="M13.243 2.801a.959.959 0 1 1 .958.958v1.003"
      />
      <path
        fill="black"
        d="M14.201 6.063c.377 0 .378-.586 0-.586-.377 0-.378.585 0 .585Z"
      />
    </svg>
  );
}
