export default function UserAvatarIcon({
  strokeWidth,
  ...props
}: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      fill="none"
      {...props}
    >
      <path
        fill="#FEB103"
        d="M16.264 16.264A8.858 8.858 0 1 0 3.734 3.739a8.858 8.858 0 0 0 12.53 12.525Z"
      />
      <path
        fill="#979797"
        d="M16.264 16.263a6.477 6.477 0 0 0-12.529 0 8.859 8.859 0 0 0 12.529 0Z"
      />
      <path
        fill="#FDE2C5"
        d="M10 11.434a3.338 3.338 0 1 0 0-6.676 3.338 3.338 0 0 0 0 6.676Z"
      />
      <path
        fill="currentColor"
        d="M3.612 15.73a8.806 8.806 0 0 1-.43-.515.273.273 0 1 0-.435.332A9.132 9.132 0 0 0 17.624 4.97a.274.274 0 1 0-.457.301 8.583 8.583 0 0 1-.778 10.462 6.75 6.75 0 0 0-4.817-4.387 3.611 3.611 0 1 0-3.144 0 6.75 6.75 0 0 0-4.816 4.384Zm3.323-7.634a3.065 3.065 0 1 1 3.07 3.065h-.01a3.068 3.068 0 0 1-3.06-3.065Zm3.058 3.612h.014a6.203 6.203 0 0 1 5.95 4.47 8.575 8.575 0 0 1-11.914 0 6.203 6.203 0 0 1 5.95-4.47Zm6.315-7.531a.274.274 0 0 0 .451-.068.272.272 0 0 0-.049-.302 8.543 8.543 0 0 0-.568-.565.273.273 0 1 0-.366.407c.179.16.358.339.532.528Z"
      />
      <path
        fill="currentColor"
        d="M10 1.413a8.545 8.545 0 0 1 4.673 1.384.274.274 0 0 0 .298-.458 9.133 9.133 0 0 0-13.023 11.97.273.273 0 0 0 .503-.05.273.273 0 0 0-.02-.208A8.587 8.587 0 0 1 10 1.413Z"
      />
    </svg>
  );
}
