// src/store/slices/statesSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface UserProfileState {
    selectedView: string;
}

const initialState: UserProfileState = {
    selectedView: '/contract_states',
};

const statesSlice = createSlice({
    name: 'states',
    initialState,
    reducers: {
        setSelectedView(state, action: PayloadAction<string>) {
            state.selectedView = action.payload;
        },
    },
});

export const { setSelectedView } = statesSlice.actions;
export default statesSlice.reducer;