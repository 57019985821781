import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface Tutor {
  id: string;
  token: string;
  name: string;
  lastName: string;
  platformUser: string;
  email: string;
  roles: string[];
  commercialNetwork?: string | null;
  company: string;
  companyName?: string | null;
}

export interface TutorState {
  isAuthenticated: boolean;
  tutor: Tutor | null;
  loading: boolean;
  error: string | null;
}

export const initialState: TutorState = {
  isAuthenticated: false,
  tutor: null,
  loading: false,
  error: null,
};

const tutorSlice = createSlice({
  name: 'tutor',
  initialState,
  reducers: {
    login: (state: TutorState, action: PayloadAction<TutorState>) => {
      state = {
        ...action.payload
      }
      return state;
    },
    logout: (state) => {
      state.isAuthenticated = false;
      state.tutor = null;
      return state;
    },
  }
});

export const { logout, login } = tutorSlice.actions;

export default tutorSlice.reducer;