import { useCallback } from 'react';
import { toast } from 'sonner';
import { DepartmentService } from '../../../services/departments/departmentService';
import useHandleErrors from '../../useHandleErrors';

const useDeleteDepartment = (refetch: () => void) => {
    const service = new DepartmentService();
    const { handleErrors } = useHandleErrors();

    /**
     * Función para eliminar un departamento
     */
    const deleteDepartment = useCallback(async (id: string) => {
        try {
            const response = await (await service.deleteDepartment(id)).getResponseData();
            if (response.success) {
                toast.success('Departamento eliminado correctamente');
                refetch();
            } else {
                handleErrors(response);
            }
        } catch (error) {
            handleErrors(error);
        }
    }, [service, refetch, handleErrors]);

    return deleteDepartment;
};

export default useDeleteDepartment;