import React, { Fragment } from 'react';
import * as yup from 'yup';
import { useNavigate, useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import FormGroup from '../../layout/shared/form-group';
import { Textarea } from 'rizzui';
import Page from '../../layout/Page/Page';
import FormFooter from '../_layout/_footers/form-footer';
import Spinner from '../../components/bootstrap/Spinner';
import { PiEnvelopeSimple } from 'react-icons/pi';
import RequiredInput from '../../components/forms/InputHOC';
import { Tutor } from '../../type/entities/tutor-type';
import { validateCifNif } from '../../utils/validatorFunctions';
import { isValidPhoneNumber } from "react-phone-number-input";
import CustomSelect from '../../components/forms/CustomSelect';
import TutorProductsForm from './components/TutorProductsForm';
import RequiredPassword from '../../components/forms/PasswordHOC';
import PhoneInput from '../../components/forms/PhoneInputWrapper';
import { toast } from 'sonner';

interface CreateFormProps {
  isLoading: boolean;
  submit: Function;
  data?: Tutor | undefined;
}

export const tutorType = [
  { value: 'internal', label: 'Interno' },
  { value: 'external', label: 'Externo' },
];

export const availability = [
  { value: 'Mañanas', label: 'Mañanas' },
  { value: 'Tardes', label: 'Tardes' },
  { value: 'Todo el día', label: 'Todo el día' },
];

const schema = yup.object({
  name: yup.string().min(1, 'Demasiado corto').max(100, 'Demasiado largo').required('Campo obligatorio'),
  lastName: yup.string().min(1, 'Demasiado corto').max(100, 'Demasiado largo').required('Campo obligatorio'),
  nifCif: yup.string().test('valid-cif-nif', 'El CIF/NIF no es válido', (value) => {
    if (typeof value === 'string') {
      return validateCifNif(value);
    } else {
      return true;
    }
  }).required('Campo obligatorio'),
  address: yup.string().min(1, 'Demasiado corto').max(100, 'Demasiado largo').required('Campo obligatorio'),
  town: yup.string().min(1, 'Demasiado corto').max(100, 'Demasiado largo').required('Campo obligatorio'),
  province: yup.string().min(1, 'Demasiado corto').max(100, 'Demasiado largo').required('Campo obligatorio'),
  postalCode: yup.string().min(5, 'Demasiado corto').max(5, 'Demasiado largo').matches(/^[0-9]+$/, 'Código postal inválido').required('Campo obligatorio'),
  tutorType: yup.string().oneOf(['internal', 'external'], 'Debes seleccionar entre Interno o Externo').required('Campo obligatorio'),
  email: yup.string().email('Correo electrónico inválido').required('Campo obligatorio'),
  telephone: yup.string().required('El teléfono es obligatorio').test("valid-phone", "El teléfono no es válido", (value) => isValidPhoneNumber(value)),
  telephoneSecondary: yup.string().nullable().test("valid-phone", "El teléfono no es válido", (value) => isValidPhoneNumber(value ?? '')),
  comments: yup.string().nullable(),
  products: yup.array().of(yup.object().shape({
    id: yup.string().required('Campo obligatorio'),
    price: yup.number().nullable().when('tutorType', {
      is: 'external',
      then: schema => schema.required('Campo obligatorio para tutor externo'),
      otherwise: schema => schema.notRequired().nullable(),
    }),
  })).required('Campo obligatorio'),
  availability: yup.array().of(yup.string().oneOf(['Mañanas', 'Tardes', 'Todo el día'], 'Debes seleccionar entre Mañanas, Tardes o Todo el día')).required('Campo obligatorio').length(1, 'Campo obligatorio'),
  platformUser: yup.string().required('Campo obligatorio'),
  platformPassword: yup.string().required('Campo obligatorio'),
  password: yup.string().min(8, 'Contraseña de al menos 8 caracteres').max(30, 'Contraseña menor de 30 caracteres').matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/, 'La contraseña debe contener al menos una letra mayúscula, una minúscula y un número').nullable(),
  confirmPassword: yup.string().when('password', {
    is: (val: string | null | undefined) => val !== null && val !== undefined && val.length > 0,
    then: (schema: yup.Schema) => schema.oneOf([yup.ref('password'), ''], 'Las contraseñas no coinciden'),
    otherwise: (schema: yup.Schema) => schema.nullable(),
  }).nullable(),
});

const TutorForm: React.FC<CreateFormProps> = ({ isLoading, submit, data }) => {

  const { id = '' } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const mode = data ? 'Editar' : 'Crear';

  const [product, setProduct] = React.useState<{ id: string, price: string }>({ id: '', price: '' });

  const formik = useFormik({
    initialValues: {
      tutorId: id,
      name: data?.name ?? '',
      lastName: data?.lastName ?? '',
      nifCif: data?.nifCif ?? '',
      address: data?.address ?? '',
      town: data?.town ?? '',
      province: data?.province ?? '',
      postalCode: data?.postalCode ?? '',
      tutorType: data?.tutorType ?? '',
      email: data?.email ?? '',
      telephone: data?.telephone ?? '',
      telephoneSecondary: data?.telephoneSecondary ?? '',
      comments: data?.comments ?? '',
      products: data?.products ? data.products.map((p: any) => ({ id: p.product.id, price: p.pricePerStudent })) : [],
      availability: data?.availability ?? [],
      platformUser: data?.platformUser ?? '',
      platformPassword: data?.platformPassword ?? '',
      password: '',
      confirmPassword: '',
      active: data?.active ?? '',
    },
    validationSchema: schema,
    onSubmit: async (values) => {
      let updatedProducts = [...formik.values.products];

      if (product.id !== '') {
        if (values.tutorType === 'internal') {
          updatedProducts.push(product);
        } else if (values.tutorType === 'external') {
          if (product.price === '') {
            toast.error('Debes rellenar el precio del producto');
            return;
          }
          updatedProducts.push(product);
        }
        values.products = updatedProducts;
        await formik.setFieldValue('products', updatedProducts);
        setProduct({ id: '', price: '' });
      }

      submit(values);
    }
  });

  const getContent = () => {
    return (
      <>
        <div className="@container">
          <form onSubmit={formik.handleSubmit}>
            <FormGroup
              title="Información General"
              description="Datos principales del tutor"
              className='pt-3'
            >
              <RequiredInput
                id='name'
                type="text"
                label="Nombre"
                className="[&>label>span]:font-medium"
                inputClassName="text-sm"
                onChange={formik.handleChange}
                value={formik.values.name}
                error={formik.errors.name}
              />

              <RequiredInput
                id='lastName'
                type="text"
                label="Apellidos"
                className="[&>label>span]:font-medium"
                inputClassName="text-sm"
                onChange={formik.handleChange}
                value={formik.values.lastName}
                error={formik.errors.lastName}
              />

              <RequiredInput
                id='nifCif'
                type="text"
                label="CIF/NIF"
                className="[&>label>span]:font-medium"
                inputClassName="text-sm"
                onChange={formik.handleChange}
                value={formik.values.nifCif}
                error={formik.errors.nifCif}
              />

              <RequiredInput
                id='email'
                prefix={<PiEnvelopeSimple className="h-6 w-6 text-gray-500" />}
                type="email"
                label="Correo Electrónico"
                className="[&>label>span]:font-medium"
                inputClassName="text-sm"
                onChange={formik.handleChange}
                value={formik.values.email}
                error={formik.errors.email}
              />

              <CustomSelect
                id='tutorType'
                required
                label="Tipo de Tutor"
                options={tutorType}
                onChange={(e: any) => formik.setFieldValue('tutorType', e ? e.value : null)}
                value={{ value: formik.values.tutorType, label: tutorType.find((g: any) => g.value === formik.values.tutorType)?.label }}
                error={formik.errors.tutorType}
              />

              <CustomSelect
                id='availability'
                isMulti
                required
                label="Disponibilidad"
                options={availability}
                onChange={(e: any) => formik.setFieldValue('availability', e ? e.map((g: any) => g.value) : [])}
                value={formik.values.availability.map((g: string) => availability.find((a: any) => a.value === g))}
                error={formik.errors.availability}
              />

              <RequiredInput
                id='platformUser'
                type="text"
                label="Usuario de la Plataforma"
                className="[&>label>span]:font-medium"
                inputClassName="text-sm"
                onChange={formik.handleChange}
                value={formik.values.platformUser}
                error={formik.errors.platformUser}
              />

              <RequiredPassword
                id='platformPassword'
                label="Contraseña de la Plataforma"
                className="[&>label>span]:font-medium"
                inputClassName="text-sm"
                onChange={formik.handleChange}
                value={formik.values.platformPassword}
                error={formik.errors.platformPassword}
              />

              <RequiredPassword
                id='password'
                label="Contraseña"
                className="[&>label>span]:font-medium"
                inputClassName="text-sm"
                onChange={formik.handleChange}
                value={formik.values.password}
                error={formik.errors.password}
              />

              <RequiredPassword
                id='confirmPassword'
                label="Confirmar contraseña"
                className="[&>label>span]:font-medium"
                inputClassName="text-sm"
                onChange={formik.handleChange}
                value={formik.values.confirmPassword}
                error={formik.errors.confirmPassword}
              />
            </FormGroup>

            <TutorProductsForm formik={formik} product={product} setProduct={setProduct} />

            <FormGroup
              title="Información Adicional"
              description="Datos adicionales del tutor"
              className='pt-6'
            >
              <PhoneInput
                id='telephone'
                label='Teléfono'
                onBlur={formik.handleBlur}
                onChange={e => { formik.setFieldValue('telephone', e) }}
                value={formik.values.telephone}
                error={formik.errors.telephone}
              />

              <PhoneInput
                id='telephoneSecondary'
                label='Teléfono Secundario'
                onBlur={formik.handleBlur}
                onChange={e => { formik.setFieldValue('telephoneSecondary', e) }}
                value={formik.values.telephoneSecondary}
                error={formik.errors.telephoneSecondary}
              />

              <RequiredInput
                id='address'
                type="text"
                label="Dirección"
                className="[&>label>span]:font-medium"
                inputClassName="text-sm"
                onChange={formik.handleChange}
                value={formik.values.address}
                error={formik.errors.address}
              />

              <RequiredInput
                id='town'
                type="text"
                label="Población"
                className="[&>label>span]:font-medium"
                inputClassName="text-sm"
                onChange={formik.handleChange}
                value={formik.values.town}
                error={formik.errors.town}
              />

              <RequiredInput
                id='province'
                type="text"
                label="Provincia"
                className="[&>label>span]:font-medium"
                inputClassName="text-sm"
                onChange={formik.handleChange}
                value={formik.values.province}
                error={formik.errors.province}
              />

              <RequiredInput
                id='postalCode'
                type="text"
                label="Código Postal"
                className="[&>label>span]:font-medium"
                inputClassName="text-sm"
                onChange={formik.handleChange}
                value={formik.values.postalCode}
                error={formik.errors.postalCode}
              />

              <Textarea
                id='comments'
                label='Comentarios'
                rows={3}
                className="col-span-2 [&>label>span]:font-medium"
                onChange={formik.handleChange}
                value={formik.values.comments}
                error={formik.errors.comments}
              />
            </FormGroup>
          </form>
        </div>
      </>
    )
  };

  return (
    <Fragment>
      <Page container="fluid">
        {(mode === "Editar" && !data) && <Spinner />}
        {(mode === "Editar" && data) && getContent()}
        {mode === "Crear" && getContent()}
      </Page>
      <FormFooter
        submitBtnText={mode + ' Tutor'}
        handleCancelBtn={() => navigate(-1)}
        handleSubmitBtn={formik.submitForm} isLoading={isLoading}
      />
    </Fragment>
  )
};

export default TutorForm;