import { useCallback, useEffect, useState } from 'react';
import { DepartmentService } from '../../services/departments/departmentService';

/*---------------------------------------------------------------------------------------------
* EN: Hook to manage departments fetching and transformation logic.
* ES: Hook para gestionar la lógica de obtención y transformación de departamentos.
----------------------------------------------------------------------------------------------*/

const useDepartments = () => {

    const [data, setData] = useState<any[]>([]);

    const fetchDepartments = useCallback(async () => {
        try {
            const service = new DepartmentService();
            const response = await service.listDepartments();
            const fetchedData = response.getResponseData().data;

            if (fetchedData && fetchedData?.data) {
                const mappedData = fetchedData.data?.map((data: { id: string; name: string; }) => ({
                    value: data.id,
                    label: data.name,
                }));
                setData(mappedData);
            }
        } catch (error) {
            console.log('Error fetching departments:', error);
        }
    }, []);

    useEffect(() => {
        fetchDepartments();
    }, []);

    const getDepartmentsList = () => {
        return data;
    };

    return { fetchDepartments, getDepartmentsList };
}

export default useDepartments;


/*----------------------------------------------------------------------------------------------
* EN: Hook to manage unassigned departments fetching and transformation logic.
* ES: Hook para gestionar la lógica de obtención y transformación de departamentos no asignados.
-----------------------------------------------------------------------------------------------*/

export const useUnassignedDepartments = () => {

    const [data, setData] = useState<any[]>([]);

    const fetchUnassignedDepartments = useCallback(async () => {
        try {
            const service = new DepartmentService();
            const response = await service.getUnassignedDepartments();
            const fetchedData = response.getResponseData();

            if (fetchedData && fetchedData?.data) {
                const mappedData = fetchedData.data?.map((data: { id: string; name: string; }) => ({
                    value: data.id,
                    label: data.name,
                }));
                setData(mappedData);
            }
        } catch (error) {
            console.log('Error fetching departments:', error);
        }
    }, []);

    useEffect(() => {
        fetchUnassignedDepartments();
    }, []);

    const getUnassignedDepartmentsList = () => {
        return data;
    };

    return { fetchUnassignedDepartments, getUnassignedDepartmentsList };
}