import React, { useEffect, useState } from 'react';
import CustomSelect from './CustomSelect';

interface OptionType {
    label: string;
    value: string;
}


interface Props {
    id: string;
    label: string;
    filters: any;
    minInputLength?: number;
    updateFilters?: (filter: any) => void;
    fetchOptions: (searchText: string) => Promise<OptionType[]>;
}

const CustomSearchableEntities: React.FC<Props> = ({
    id,
    label,
    filters,
    minInputLength = 2,
    updateFilters,
    fetchOptions
}) => {
    const [options, setOptions] = useState<OptionType[]>([]);
    const [inputValue, setInputValue] = useState('');
    const [filtersValue, setFiltersValue] = useState<any>([]);
    const [loading, setLoading] = useState(false);

    // Clear the selected values from filters if it is not in the options
    useEffect(() => {
        if (!filters.filter_filters?.[id]) setFiltersValue([]);
    }, [filters]);

    // Get and Print the options filtered by input value
    const handleInputChange = (value: string) => {
        setInputValue(value);

        if (value.length >= minInputLength) {
            setLoading(true);
            fetchOptions(value).then(fetchedOptions => {
                setOptions(fetchedOptions);
            }).catch(error => {
                console.error('Error fetching options:', error);
                setOptions([]);
            }
            ).finally(() => setLoading(false));
        } else {
            setOptions([]);
        }
    };

    // Handle change of selected values
    const handleFiltersValue = (valueArray: OptionType[]) => {
        if (valueArray && valueArray.length > 0) {
            const values = valueArray.map((item) => item.value);
            updateFilters && updateFilters({ [id]: values });
            setFiltersValue(valueArray);
        } else {
            updateFilters && updateFilters({ [id]: [] });
            setFiltersValue([]);
        }
    };

    return (
        <CustomSelect
            id={id}
            label={label}
            isSearchable
            isClearable
            isMulti
            options={options}
            isLoading={loading}
            onInputChange={handleInputChange}
            onChange={handleFiltersValue}
            inputValue={inputValue}
            value={filtersValue}
            noOption={() => (inputValue.length < minInputLength ? `Introduce al menos ${minInputLength} letras` : 'No se encontraron opciones')}
        />
    );
};

export default CustomSearchableEntities;