import { useCallback, useEffect, useState } from 'react';
import { CorporationService } from '../../services/corporation/corporationService';

/*----------------------------------------------------------------------------------------
* EN: Hook to manage corporations fetching and transformation logic.
* ES: Hook para gestionar la lógica de obtención y transformación de empresas.
----------------------------------------------------------------------------------------*/

const useCorporations = () => {

    const [data, setData] = useState<any[]>([]);

    const fetchCorporations = useCallback(async () => {
        try {
            const service = new CorporationService();
            const response = await service.listCorporations({ limit: 999999 });
            const fetchedData = response.getResponseData().data;

            if (fetchedData && fetchedData?.data) {
                const mappedData = fetchedData.data?.map((data: { id: string; name: string; }) => ({
                    value: data.id,
                    label: data.name,
                }));
                setData(mappedData);
            }
        } catch (error) {
            console.log('Error fetching corporations:', error);
        }
    }, []);

    useEffect(() => {
        fetchCorporations();
    }, []);

    const getCorporationsList = () => {
        return data;
    };

    return { fetchCorporations, getCorporationsList };
}

export default useCorporations;