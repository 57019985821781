export default function FinancialStatisticsIcon({
  ...props
}: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
      fill="none"
      {...props}
    >
      <path
        fill="#92D2FE"
        fillRule="evenodd"
        d="M42.273 275.801h71.17v187.223h-71.17V275.801Z"
        clipRule="evenodd"
      />
      <path
        fill="#EB745E"
        fillRule="evenodd"
        d="M161.035 198.629h71.17v264.396h-71.17V198.629Z"
        clipRule="evenodd"
      />
      <path
        fill="#9AD297"
        fillRule="evenodd"
        d="M279.795 148.895h71.17v314.128h-71.17V148.895Z"
        clipRule="evenodd"
      />
      <path
        fill="#FFD54F"
        fillRule="evenodd"
        d="M398.555 106.879h71.17v356.145h-71.17V106.879Z"
        clipRule="evenodd"
      />
      <path
        fill="#656565"
        fillRule="evenodd"
        d="M16.852 463.023H495.15v28.751H16.852v-28.751Z"
        clipRule="evenodd"
      />
      <path
        fill="#FFD54F"
        d="M256 499.488c43.011 0 77.879-34.867 77.879-77.879 0-43.011-34.868-77.879-77.879-77.879s-77.879 34.868-77.879 77.879c0 43.012 34.868 77.879 77.879 77.879Z"
      />
      <path
        fill="#FFC107"
        d="M256 478.839c31.607 0 57.23-25.623 57.23-57.23 0-31.607-25.623-57.23-57.23-57.23-31.608 0-57.23 25.623-57.23 57.23 0 31.607 25.622 57.23 57.23 57.23Z"
      />
      <path
        fill="#E6E6E6"
        fillRule="evenodd"
        d="M263 458.943v3.765a7 7 0 1 1-14 0v-3.987a29.71 29.71 0 0 1-6.077-2.236 7.277 7.277 0 0 1-.6-.336c-6.343-3.353-11.547-9.067-13.795-17.158a6.979 6.979 0 1 1 13.453-3.719c1.107 3.986 3.648 6.815 6.751 8.495.143.06.285.124.425.194a16.617 16.617 0 0 0 7.775 1.727c2.567-.061 5.043-.716 7.087-1.959 2.388-1.453 4.206-3.81 4.817-7.068l.005.001c.746-4.078-1.871-5.291-5.339-5.935-1.783-.331-3.648-.57-5.539-.811-12.737-1.626-26.344-3.363-27.032-21.995-.325-8.805 3.452-15.356 9.22-19.508 2.626-1.89 5.647-3.233 8.848-4.018v-3.883a7 7 0 1 1 14 0v3.898c.497.119.992.251 1.484.396 8.338 2.454 15.827 8.531 18.932 18.42a6.989 6.989 0 0 1-13.344 4.156c-1.54-4.906-5.312-7.937-9.526-9.177a16.349 16.349 0 0 0-6.788-.525c-2.059.283-3.955.972-5.458 2.054-2.151 1.548-3.555 4.117-3.423 7.696.251 6.788 7.784 7.751 14.836 8.651 2.176.277 4.322.552 6.306.921 11.153 2.074 19.479 6.424 16.544 22.204l-.005.027.005.001c-1.413 7.53-5.666 13.009-11.27 16.419-2.541 1.544-5.355 2.642-8.292 3.29Z"
        clipRule="evenodd"
      />
      <path
        fill="#EB745E"
        fillRule="evenodd"
        d="M58.657 209.127a6.979 6.979 0 1 1-3.72-13.453c58.365-16.335 120.768-40.841 175.593-69.295 50.636-26.279 94.658-55.844 122.788-85.307a6.982 6.982 0 1 1 10.117 9.625c-29.249 30.636-74.577 61.146-126.506 88.096-55.611 28.862-118.967 53.736-178.273 70.334Z"
        clipRule="evenodd"
      />
      <path
        fill="#EB745E"
        fillRule="evenodd"
        d="m391.269 22.6-2.674 41.012a6.985 6.985 0 0 1-12.462 3.883l-38.1-43.414a6.977 6.977 0 0 1 5.686-11.556v-.01l41.013 2.672a6.99 6.99 0 0 1 6.537 7.412Z"
        clipRule="evenodd"
      />
    </svg>
  );
}
