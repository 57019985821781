import React, { useCallback, useState } from 'react';
import { Button, Switch, Tooltip } from 'rizzui';
import { usePrivilege } from '../../../components/priviledge/PriviledgeProvider';
import TabNav, { MenuItem } from "../../../components/navigation/TabNav";
import { menuRoutes } from "../../../router/menu";
import PageHeader from "../../../layout/shared/page-header";
import { useNavigate, useParams } from "react-router-dom";
import DeletePopover from '../../../components/buttons/DeletePopover';
import { UserService } from '../../../services/user/userService';
import useFetch from '../../../hooks/useFetch';
import { UserApiResponse } from '../../../type/entities/user-type';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import { toast } from 'sonner';

const menuItems: MenuItem[] = [
    {
        label: 'Información',
        path: '/info',
        permission: {
            group: 'user',
            action: 'get_user',
        },
    },
    {
        label: 'Estadísticas',
        path: '/stats',
        permission: {
            group: 'user',
            action: 'user_profile_stats',
        },
    },
    {
        label: 'Documentos',
        path: '/documents',
        permission: {
            group: 'user',
            action: 'user_profile_documents',
        },
    },
    {
        label: 'Permisos',
        path: '/permissions',
        permission: {
            group: 'user',
            action: 'user_profile_permissions',
        },
    },
    {
        label: 'Histórico de contratos',
        path: '/contract-history',
        permission: {
            group: 'user',
            action: 'user_profile_contract_history',
        },
    },
    {
        label: 'Actividad',
        path: '/activity',
        permission: {
            group: 'user',
            action: 'user_profile_activity',
        },
    },
];

const pageHeader = {
    title: "Perfil de Usuario",
    breadcrumb: [
        { name: 'Administración' },
        { name: 'Control de acceso' },
        { name: 'Usuarios', href: menuRoutes.users.path },
        { name: 'Perfil de Usuario' },
    ],
};

export default function ProfileLayout({ children }: { children: React.ReactNode }) {

    const { user } = useSelector((state: RootState) => state.auth);
    const { id = '' } = useParams<{ id: string }>();
    const { userCan } = usePrivilege();
    const navigate = useNavigate();
    const service = new UserService();

    const [changingStatus, setChangingStatus] = useState<string[]>([]);

    const [data, loading, error, refetch] = useFetch(useCallback(async () => {
        if (!id || id === '') return null;
        const response = await service.getUserById(id);
        return response.getResponseData() as UserApiResponse;
    }, [id]));

    const toggleStatus = async (id: string, status: boolean) => {
        try {
            setChangingStatus([...changingStatus, id]);
            const response = (await service.toggleUserStatus(id, status)).getResponseData();
            if (response.success) {
                setChangingStatus(changingStatus.filter((item) => item !== id));
                refetch();
                toast.success(status ? "Se ha desactivado el usuario" : "Se ha activado el usuario");
            } else {
                setChangingStatus(changingStatus.filter((item) => item !== id));
                toast.error(response.message);
            }
        } catch (error: any) {
            setChangingStatus(changingStatus.filter((item) => item !== id));
            toast.error(error.message);
        }
    };

    const handleDelete = async (id: string) => {
        await (new UserService()).deleteUser(id);
        navigate(-1);
    };

    return (
        <>
            <PageHeader title={pageHeader.title} breadcrumb={pageHeader.breadcrumb}>
                <div className='flex flex-row flex-nowrap gap-3 mt-3 md:mt-0'>
                    {userCan('edit_user', 'user') && (
                        <>
                            {data && (
                                <Tooltip
                                    size="sm"
                                    content={data.active ? 'Desactivar usuario' : 'Activar usuario'}
                                    placement="top"
                                    color="invert"
                                >
                                    <Switch
                                        id={id}
                                        label={data.active ? 'Activo' : 'Desactivado'}
                                        checked={data.active}
                                        disabled={changingStatus.includes(data.id) || data.id === user?.id}
                                        onChange={() => toggleStatus(data.id, data.active)}
                                    />
                                </Tooltip>
                            )}

                            <div className='border-l border-gray-300' />

                            <Tooltip content={'Editar usuario'} placement={'top'} color={'invert'} size={'sm'}>
                                <Button variant={'outline'} color={'primary'} size={'md'} onClick={() => {
                                    navigate(`${menuRoutes.users.path}/${id}/edit/info`)
                                }}>
                                    Editar
                                </Button>
                            </Tooltip>
                        </>
                    )}

                    {userCan('delete_user', 'user') && (
                        <Tooltip content={'Eliminar usuario'} placement={'top'} color={'invert'} size={'sm'} className='text-danger'>
                            <DeletePopover
                                title={`Eliminar usuario`}
                                description={`¿Estás seguro de que deseas eliminar el usuario?`}
                                size={'md'}
                                onDelete={() => handleDelete(id)}
                                actionIconClassName='h-auto w-10'
                            />
                        </Tooltip>
                    )}
                </div>


            </PageHeader>
            <TabNav menuItems={menuItems} />
            {children}
        </>
    );
}