export default function WalkmanIcon({
  ...props
}: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      fill="none"
      {...props}
    >
      <path
        fill="#E8EDF2"
        d="M17.665 13.845a.599.599 0 0 1-.517-.9 7.624 7.624 0 0 0 1.027-3.826c0-4.206-3.422-7.629-7.629-7.629-4.206 0-7.629 3.423-7.629 7.63 0 1.346.356 2.669 1.028 3.826a.599.599 0 0 1-1.036.601A8.823 8.823 0 0 1 1.72 9.12c0-2.357.919-4.574 2.586-6.24A8.769 8.769 0 0 1 10.546.292c2.358 0 4.574.918 6.242 2.585a8.769 8.769 0 0 1 2.585 6.241 8.824 8.824 0 0 1-1.19 4.428.599.599 0 0 1-.518.298Zm-3.49 5.153h-2a.603.603 0 1 1 0-1.206h2c.55 0 1.037-.33 1.242-.84l.903-2.25a.603.603 0 1 1 1.12.45l-.904 2.25a2.532 2.532 0 0 1-2.36 1.596Z"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={0.6}
        d="M12.738 17.79h1.437c.55 0 1.037-.33 1.242-.84l.645-1.606"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={0.6}
        d="M12.738 18.998h1.437a2.532 2.532 0 0 0 2.361-1.596l.839-2.09"
      />
      <path
        fill="#5F99D7"
        d="m17.142 10.098 1.317.967c.379.279.529.774.368 1.216l-.883 2.425c-.16.442-.594.725-1.063.695l-1.631-.106 1.892-5.197Z"
      />
      <path
        fill="#3C87D0"
        d="m15.78 13.835-.53 1.457 1.63.106c.47.03.903-.252 1.064-.695l.53-1.457c-.16.442-.593.725-1.063.694l-1.63-.105Z"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={0.6}
        d="m15.448 15.309 1.433.093c.47.03.903-.253 1.064-.695l.291-.8m-.934-3.692 1.157.85c.38.28.529.774.368 1.216l-.113.311"
      />
      <path
        fill="#017297"
        d="M16.69 9.094c.501.182.76.736.577 1.237l-1.77 4.864a.966.966 0 1 1-1.814-.66l1.77-4.864a.966.966 0 0 1 1.237-.577Z"
      />
      <path
        fill="#025F80"
        d="m14.418 12.516-.735 2.02a.962.962 0 0 0 .576 1.238.966.966 0 0 0 1.238-.578l.736-2.02a.966.966 0 0 1-1.815-.66Z"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={0.6}
        d="M16.69 9.094c.501.182.76.736.577 1.237l-1.77 4.864a.966.966 0 1 1-1.814-.66l1.77-4.864a.966.966 0 0 1 1.237-.577Z"
      />
      <path
        fill="#017297"
        d="M11.572 19.707a1.312 1.312 0 1 0 0-2.625 1.312 1.312 0 0 0 0 2.625Z"
      />
      <path
        fill="#025F80"
        d="M12.141 19.578a1.312 1.312 0 1 1 0-2.366 1.312 1.312 0 0 0 0 2.366Z"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={0.6}
        d="M11.572 19.707a1.312 1.312 0 1 0 0-2.625 1.312 1.312 0 0 0 0 2.625Z"
      />
      <path
        fill="#9BE69D"
        d="M2.482 6.996h8.299c1.024 0 1.855.83 1.855 1.855v5.032c0 1.025-.83 1.855-1.855 1.855H9.045v1.833a.541.541 0 0 1-.893.411L5.53 15.738H2.482a1.855 1.855 0 0 1-1.855-1.855V8.851c0-1.024.83-1.855 1.855-1.855Z"
      />
      <path
        fill="#6FD7A3"
        d="M3.827 15.738H2.482a1.855 1.855 0 0 1-1.855-1.855V8.851c0-1.024.83-1.855 1.855-1.855h1.345c-1.024 0-1.855.83-1.855 1.855v5.032c0 1.024.83 1.855 1.855 1.855Z"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={0.6}
        d="M4.526 15.738h1.003l2.623 2.245c.351.3.893.05.893-.412v-1.833h1.736c1.024 0 1.855-.83 1.855-1.854V8.85c0-1.024-.83-1.855-1.855-1.855h-8.3c-1.023 0-1.854.83-1.854 1.855v5.032c0 1.025.83 1.855 1.855 1.855h.647m-.571-5.437h8.147m-8.147 1.43h8.147m-8.147 1.433h6.814"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={0.6}
        d="M1.957 7.073a8.769 8.769 0 0 1 2.348-4.195A8.77 8.77 0 0 1 10.546.293a8.77 8.77 0 0 1 6.242 2.585 8.77 8.77 0 0 1 2.585 6.241 8.84 8.84 0 0 1-.486 2.893"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={0.6}
        d="M3.218 7c.921-3.178 3.858-5.508 7.329-5.508 4.206 0 7.628 3.423 7.628 7.63 0 .542-.057 1.082-.17 1.609"
      />
    </svg>
  );
}
