import React, { useCallback, useState } from 'react';
import { Button, Switch, Tooltip } from 'rizzui';
import { usePrivilege } from '../../../components/priviledge/PriviledgeProvider';
import TabNav, { MenuItem } from "../../../components/navigation/TabNav";
import { menuRoutes } from "../../../router/menu";
import PageHeader from "../../../layout/shared/page-header";
import { useNavigate, useParams } from "react-router-dom";
import DeletePopover from '../../../components/buttons/DeletePopover';
import useFetch from '../../../hooks/useFetch';
import { toast } from 'sonner';
import { TutorService } from '../../../services/tutor/tutorService';
import { TutorApiResponse } from '../../../type/entities/tutor-type';
import useHandleErrors from '../../../hooks/useHandleErrors';

const menuItems: MenuItem[] = [
    {
        label: 'Información',
        path: '/info',
        permission: {
            group: 'tutors',
            action: 'get_tutors',
        },
    },
    {
        label: 'Estadísticas',
        path: '/stats',
        permission: {
            group: 'tutors',
            action: 'tutors_stats',
        },
    },
    {
        label: 'Documentos',
        path: '/documents',
        permission: {
            group: 'tutors',
            action: 'tutors_documentation',
        },
    },
    {
        label: 'Facturas',
        path: '/invoices',
        permission: {
            group: 'tutors',
            action: 'tutors_invoices',
        },
    },
    {
        label: 'Contratos',
        path: '/contracts',
        permission: {
            group: 'tutors',
            action: 'tutors_pending_contracts',
        },
    },
];

const pageHeader = {
    title: "Perfil de Tutor",
    breadcrumb: [
        { name: 'Administración' },
        { name: 'Control de acceso' },
        { name: 'Tutores', href: menuRoutes.tutors.path },
        { name: 'Perfil de Tutor' },
    ],
};

export default function ProfileLayout({ children }: { children: React.ReactNode }) {

    const { id = '' } = useParams<{ id: string }>();
    const { userCan } = usePrivilege();
    const navigate = useNavigate();
    const { handleErrors } = useHandleErrors();
    const service = new TutorService();

    const [changingStatus, setChangingStatus] = useState<string[]>([]);

    const [data, loading, error, refetch] = useFetch(useCallback(async () => {
        if (!id || id === '') return null;
        const response = await service.getTutorById(id);
        return response.getResponseData() as TutorApiResponse;
    }, [id]));

    const toggleState = async (id: string, status: boolean) => {
        try {
            setChangingStatus([...changingStatus, id]);
            const response = (await service.toggleTutorStatus(id, status)).getResponseData();
            if (response.success) {
                setChangingStatus(changingStatus.filter((item) => item !== id));
                refetch();
                toast.success(status ? "Se ha desactivado el tutor" : "Se ha activado el tutor");
            } else {
                setChangingStatus(changingStatus.filter((item) => item !== id));
                toast.error(response.message);
            }
        } catch (error: any) {
            setChangingStatus(changingStatus.filter((item) => item !== id));
            toast.error(error.message);
        }
    };

    const handleDelete = async (id: string) => {
        try {
            const response = await (await service.deleteTutor(id)).getResponseData();
            if (response.success) {
                navigate(-1);
                toast.success('Tutor eliminado correctamente');
            } else {
                handleErrors(response);
            }
        } catch (error) {
            handleErrors(error);
        }
    };

    return (
        <>
            <PageHeader title={pageHeader.title} breadcrumb={pageHeader.breadcrumb}>
                <div className='flex flex-row flex-nowrap gap-3 mt-3 md:mt-0'>
                    {userCan('edit_tutors', 'tutors') && (
                        <>
                            {data && (
                                <Tooltip
                                    size="sm"
                                    content={data.active ? 'Desactivar tutor' : 'Activar tutor'}
                                    placement="top"
                                    color="invert"
                                >
                                    <Switch
                                        id={id}
                                        label={data.active ? 'Activo' : 'Desactivado'}
                                        checked={data.active}
                                        onChange={() => toggleState(data.id, data.active)}
                                    />
                                </Tooltip>
                            )}

                            <div className='border-l border-gray-300' />

                            <Tooltip content={'Editar tutor'} placement={'top'} color={'invert'} size={'sm'}>
                                <Button variant={'outline'} color={'primary'} size={'md'} onClick={() => {
                                    navigate(`${menuRoutes.tutors.path}/${id}/edit`)
                                }}>
                                    Editar
                                </Button>
                            </Tooltip>
                        </>
                    )}

                    {userCan('delete_tutors', 'tutors') && (
                        <Tooltip content={'Eliminar tutor'} placement={'top'} color={'invert'} size={'sm'} className='text-danger'>
                            <DeletePopover
                                title={`Eliminar tutor`}
                                description={`¿Estás seguro de que deseas eliminar el tutor?`}
                                size={'md'}
                                onDelete={() => handleDelete(id)}
                                actionIconClassName='h-auto w-10'
                            />
                        </Tooltip>
                    )}
                </div>
            </PageHeader>

            <TabNav menuItems={menuItems} />

            {children}
        </>
    );
}