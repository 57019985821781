export function validateCifNif(value: string): boolean {
  const cifRegex = /^[ABCDEFGHJKLMNPQSUVW]{1}[0-9]{7}[0-9A-J]{1}$/;
  //   const nifRegex = /^(\d{8}[A-Z])|([XYZ]\d{7}[A-Z])$/;

  const cleanValue = value.trim().toUpperCase();

  if (cifRegex.test(cleanValue)) {
    return true;
  }
  // Validar NIF
  if (/^(\d{8}[A-Z])|([XYZ]\d{7}[A-Z])$/.test(cleanValue)) {
    const dni = parseInt(cleanValue.slice(0, -1), 10);
    const letra = cleanValue.charAt(cleanValue.length - 1);
    const letrasValidas = "TRWAGMYFPDXBNJZSQVHLCKE";
    const letraCalculada = letrasValidas.charAt(dni % 23);
    return letra === letraCalculada;
  }

  return false;
};


export function validateNif(value: string): boolean {
  const nifRegex = /^(\d{8}[A-Z])|([XYZ]\d{7}[A-Z])$/;

  if (!value || !nifRegex.test(value)) {
    return false;
  }

  const valor = value.trim().toUpperCase();

  if (/^(\d{8}[A-Z])|([XYZ]\d{7}[A-Z])$/.test(valor)) {
    const dni = parseInt(valor.slice(0, -1), 10);
    const letra = valor.charAt(valor.length - 1);
    const letrasValidas = "TRWAGMYFPDXBNJZSQVHLCKE";
    const letraCalculada = letrasValidas.charAt(dni % 23);
    return letra === letraCalculada;
  }

  return false;
}


export function validateCif(value: string): boolean {
  const cifRegex = /^[ABCDEFGHJKLMNPQSUVW]{1}[0-9]{7}[0-9A-J]{1}$/;

  if (!value || !cifRegex.test(value)) {
    return false;
  }

  const valor = value.trim().toUpperCase();

  if (cifRegex.test(valor)) {
    return true;
  }

  return false;
};


export function validateSS(numeroSS: string): boolean {
  // Quitar cualquier espacio en blanco
  const cleanNum = numeroSS.replace(/\s/g, '');

  // Verificar que tiene exactamente 12 dígitos
  if (!/^\d{12}$/.test(cleanNum)) {
    return false;
  }

  // Extraer los 10 primeros dígitos y los 2 dígitos de control
  const baseNum = cleanNum.slice(0, 10);
  const controlDigits = cleanNum.slice(10);

  // El número base debe estar entre 0 y 9999999999 (10 dígitos)
  const baseNumInt = parseInt(baseNum, 10);
  if (baseNumInt < 0 || baseNumInt > 9999999999) {
    return false;
  }

  // El dígito de control se calcula dividiendo por 97 y tomando el resto
  const calculateControl = ('0' + (baseNumInt % 97)).slice(-2);

  // Verificar que el dígito de control coincide con el calculado
  return calculateControl === controlDigits;
};