export default function NewsletterAnnouncement({
  strokeWidth,
  ...props
}: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/currentColor/svg"
      viewBox="0 0 20 20"
      fill="none"
      {...props}
    >
      <path fill="#FFE082" d="M10 .441.312 8.098v7.5h19.375v-7.5L10 .44Z" />
      <path fill="#9FA8DA" d="M19.688 19.66H.313v-10l9.687 5 9.688-5v10Z" />
      <path fill="#7986CB" d="m.313 19.66 9.687-5 9.688 5H.313Z" />
      <path
        fill="#FF8A65"
        d="M8.351 8.399a7.193 7.193 0 0 1 5.37-.012l-2.356-5.973a7.195 7.195 0 0 1-3.931 3.66L4.527 7.22l.918 2.326L8.35 8.399Z"
      />
      <path
        fill="#78909C"
        d="m6.52 8.865 1.162-.459 1.12 2.838-1.162.46-1.12-2.839Z"
      />
      <path
        fill="#90A4AE"
        d="M7.434 6.074 4.527 7.221l.918 2.326L8.352 8.4l-.918-2.326Z"
      />
      <path fill="#fff" d="m3.303 8.374.581-.23.459 1.163-.581.23-.46-1.163Z" />
      <path
        fill="currentColor"
        d="M19.375 8.097V9.47L10 14.308.625 9.47V8.249L10 .839l.795.629.388-.49L10 .042 0 7.946v12.026h20V8.097h-.625ZM.625 10.173l8.694 4.487-8.694 4.487v-8.974Zm.974 9.174L10 15.011l8.4 4.336H1.6Zm17.776-.2-8.694-4.487 8.694-4.487v8.974Z"
      />
      <path
        fill="currentColor"
        d="M7.319 5.784 4.12 7.046l1.147 2.906 1.163-.459 1.031 2.617 1.744-.688-1.03-2.616.29-.115a6.838 6.838 0 0 1 5.137-.013l.68.281-2.897-7.342-.306.669A6.837 6.837 0 0 1 7.32 5.784ZM5.62 9.142l-.687-1.744 2.325-.918.687 1.744-2.325.918Zm2.777 1.928-.581.229-.804-2.034.582-.23.803 2.035Zm4.78-3.205a7.484 7.484 0 0 0-4.646.142L7.833 6.24a7.487 7.487 0 0 0 3.492-3.07l1.853 4.695Zm4.913-4.196a5.586 5.586 0 0 1-.07 4.305l.573.25A6.256 6.256 0 0 0 15.35 0l-.25.573A5.588 5.588 0 0 1 18.09 3.67Z"
      />
      <path
        fill="currentColor"
        d="M16.346 4.356a3.725 3.725 0 0 1-.046 2.87l.575.247a4.38 4.38 0 0 0-2.273-5.754l-.25.573a3.725 3.725 0 0 1 1.994 2.064Z"
      />
      <path
        fill="currentColor"
        d="m14.58 6.481.574.25a2.503 2.503 0 0 0-1.299-3.29l-.25.574a1.877 1.877 0 0 1 .975 2.466Z"
      />
    </svg>
  );
}
