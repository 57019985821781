import { useCallback, useEffect, useState } from 'react';
import {ProductService} from "../../services/product/productService";

/*----------------------------------------------------------------------------------------
* EN: Hook to manage product types fetching and transformation logic.
* ES: Hook para gestionar la lógica de obtención y transformación de tipos de productos.
----------------------------------------------------------------------------------------*/

const useProductTypes = () => {

    const [data, setData] = useState<any[]>([]);

    const fetchProductTypes = useCallback(async () => {
        try {
            const service = new ProductService();
            const response = await service.getProductTypes({ limit: 999999 });
            const fetchedData = response.getResponseData().data;

            if (fetchedData && fetchedData?.data) {
                const mappedData = fetchedData.data?.map((data: { id: string; name: string; }) => ({
                    value: data.id,
                    label: data.name,
                }));
                setData(mappedData);
            }
        } catch (error) {
            console.log('Error fetching product types:', error);
        }
    }, []);

    useEffect(() => {
        fetchProductTypes();
    }, []);

    const getProductTypesList = () => {
        return data;
    };

    return { fetchProductTypes, getProductTypesList };
}

export default useProductTypes;