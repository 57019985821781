import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "sonner";
import { menuRoutes } from "../../../router/menu";
import { RoleService } from "../../../services/role/roleService";
import RoleForm from "../RoleForm";
import PageHeader from "../../../layout/shared/page-header";
import AttivaCard from "../../../components/card/AttivaCard";

const pageHeader = {
    title: "Crear Rol",
    breadcrumb: [
        {
            href: menuRoutes.roles.path,
            name: "Roles",
        },
        {
            name: "Crear",
        },
    ],
};

const RoleCreate: FC = () => {

    const navigate = useNavigate();
    const service = new RoleService();

    const [loading, setLoading] = useState<boolean>(false);

    const handleSubmit = async (values: any, permissions: number[]) => {
        setLoading(true);
        values.permissions = permissions;

        try {
            const response = await service.createRole(values);
            const responseData = response.getResponseData();

            if (responseData.success) {
                navigate(-1);
                toast.success("Rol creado correctamente");
            } else {
                responseData.data.errors.forEach((error: any) => {
                    toast.error(error.message);
                });
            }
        } catch (error: any) {
            toast.error(error.message);
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <PageHeader title={pageHeader.title} breadcrumb={pageHeader.breadcrumb} />

            <AttivaCard title="Datos Principales" className="mb-4">
                <RoleForm submit={handleSubmit} isLoading={loading} />
            </AttivaCard>
        </>
    );
};

export default RoleCreate;