import { useContext } from "react";
import { PrivilegeContext } from "../components/priviledge/PriviledgeProvider";
import { DropdownMenuItemsType, DropdownMenuItemType, PermissionRequired, PermissionsRequired } from "../type/menu-type";

// ----------------------------------------------------------
/**
 * EN: Check if Parent Menu has access
 * ES: Verifica si el menú padre tiene acceso
 * 
 * @param menu menu to ckech permissions
 * @returns boolean
 */
// ----------------------------------------------------------
export function CheckMenuItemAccess(
    permissionsRequired: PermissionsRequired | undefined,
    dropdownItems?: DropdownMenuItemsType | undefined | null,
    ref?: string): boolean {
    const { userCan } = useContext(PrivilegeContext);
    let hasPermission = true;
    let childPermissionCount = 0;
    let hasChildPermission = !((dropdownItems?.length ?? 0) > 0);
    let subChildPermissionCount = 0;
    let hasSubChildPermission = true;
    
    if (permissionsRequired) {
        permissionsRequired.forEach((permission: PermissionRequired) => {
            if (!userCan(permission.action, permission.group, permission.isSuperAdmin || false)) {
                hasPermission = false;
            }
        });
    }


    // Check if has at least one permission from children
    if (!hasChildPermission && dropdownItems && hasPermission) {
        hasSubChildPermission = false;

        dropdownItems.forEach((item: DropdownMenuItemType) => {
            if (!item.permissions_required || item.permissions_required.length === 0) {
                hasChildPermission = true;
            } else if (item && item.permissions_required !== undefined) {
                childPermissionCount += item.permissions_required.length;
                for (const permission of item.permissions_required) {
                    if (userCan(permission.action, permission.group, permission.isSuperAdmin || false)) {
                        hasChildPermission = true;
                        break;  // Salir del bucle una vez que se encuentra un permiso válido
                    }
                }
            }

            // Ahora verificamos los permisos en los subMenuItems
            if (item.subMenuItems) {
                item.subMenuItems.forEach((subItem) => {
                    if (!subItem.permissions_required || subItem.permissions_required.length === 0) {
                        hasSubChildPermission = true;
                    } else if (subItem.permissions_required !== undefined) {
                        subChildPermissionCount += subItem.permissions_required.length;
                        for (const permission of subItem.permissions_required) {
                            if (userCan(permission.action, permission.group, permission.isSuperAdmin || false)) {
                                hasSubChildPermission = true;
                                break;  // Salir del bucle una vez que se encuentra un permiso válido
                            }
                        }
                    }
                });
            }
        });

        if (subChildPermissionCount === 0) hasSubChildPermission = true;
        if (childPermissionCount === 0) hasChildPermission = true;

    }



    return hasPermission && hasChildPermission && hasSubChildPermission;
}