import { useCallback, useEffect, useState } from 'react';
import { StateService } from '../../services/state/stateService';
import { entities } from '../../pages/states/StateForm';

/*----------------------------------------------------------------------------------------
* EN: Hook to manage states fetching and transformation logic.
* ES: Hook para gestionar la lógica de obtención y transformación de estados.
----------------------------------------------------------------------------------------*/

const useStates = () => {

    const [data, setData] = useState<any[]>([]);

    const fetchStates = useCallback(async () => {
        try {
            const service = new StateService();
            const response = await service.listStates({ limit: 999999 });
            const fetchedData = response.getResponseData().data;

            if (fetchedData && fetchedData?.data) {
                const mappedData = fetchedData.data?.map((data: { id: string; name: string; entity: string; color: string; }) => ({
                    value: data.id,
                    label: entities.find((entity) => entity.value === data.entity)?.label + ' - ' + data.name,
                    color: data.color,
                }));
                mappedData.sort((a: any, b: any) => a.label.localeCompare(b.label));
                setData(mappedData);
            }
        } catch (error) {
            console.log('Error fetching states:', error);
        }
    }, []);

    useEffect(() => {
        fetchStates();
    }, []);

    const getStatesList = () => {
        return data;
    };

    return { fetchStates, getStatesList };
}

export default useStates;