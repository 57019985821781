export default function AddIcon({
  strokeWidth,
  ...props
}: React.SVGProps<SVGSVGElement>) {
  return (

    <svg
    xmlns="http://www.w3.org/2000/svg"
    data-name="Layer 2"
    viewBox="0 0 512 512"
    strokeWidth={strokeWidth ?? 1.5}
    {...props}
  >
    <g>
      <g data-name="19">
        <path
          fill="#1e88e5"
          d="M362 0H150C67.16 0 0 67.16 0 150v212c0 82.84 67.16 150 150 150h212c82.84 0 150-67.16 150-150V150C512 67.16 444.84 0 362 0zm100 362c0 55.14-44.86 100-100 100H150c-55.14 0-100-44.86-100-100V150C50 94.86 94.86 50 150 50h212c55.14 0 100 44.86 100 100z"
        ></path>
        <path
          fill="#1e88e5"
          d="M329.83 362.13H214.47c-35.68 0-64.6-28.92-64.6-64.6V182.17c0-12.74-10.33-23.07-23.07-23.07s-23.07 10.33-23.07 23.07v124.58c0 56.06 45.45 101.51 101.51 101.51h124.58c12.74 0 23.07-10.33 23.07-23.07s-10.33-23.07-23.07-23.07z"
        ></path>
        <rect
          width="234.4"
          height="234.4"
          x="173.87"
          y="103.73"
          fill="#64b5f6"
          rx="46.14"
        ></rect>
        <path
          fill="#1e88e5"
          d="M291.07 133.92c-12.74 0-23.07 10.33-23.07 23.07v40.87h-40.87c-12.74 0-23.07 10.33-23.07 23.07S214.39 244 227.13 244H268v40.87c0 12.74 10.33 23.07 23.07 23.07s23.07-10.33 23.07-23.07V244h40.87c12.74 0 23.07-10.33 23.07-23.07s-10.33-23.07-23.07-23.07h-40.87v-40.87c0-12.74-10.33-23.07-23.07-23.07z"
        ></path>
      </g>
    </g>
  </svg>
  );
}
