export default function GreenLeafIcon({
  ...props
}: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      fill="none"
      {...props}
    >
      <g clipPath="url(#a)">
        <path
          fill="#73BF2D"
          d="M1.435 14.3S.692 7.759 8.746 5.157C16.801 2.553 17.544.348 17.544.348s5.452 8.748-1.784 13.383c-7.212 4.61-14.325.57-14.325.57Z"
        />
        <path
          fill="#20953D"
          d="M15.76 13.73C22.972 9.12 17.544.349 17.544.349s-.025.074-.099.198c.025.149.05.322.1.496 1.635 7.93-6.221 8.401-6.221 8.401-7.287-.173-9.864 4.882-9.864 4.882s7.088 4.015 14.3-.594Z"
        />
        <path
          className="fill-black dark:fill-white"
          d="M.146 19.653c0 .198.174.347.347.347h.025c.198 0 .347-.174.347-.372-.099-1.858.174-3.445.768-4.808.893.446 3.396 1.487 6.642 1.487 2.751 0 5.353-.768 7.708-2.28C23.418 9.27 17.916.247 17.866.173 17.792.05 17.668 0 17.52 0c-.148.025-.247.1-.297.248 0 .025-.892 2.106-8.55 4.61C.915 7.36 1.063 13.531 1.112 14.3.346 15.787.048 17.57.147 19.653ZM8.87 5.502c5.898-1.908 7.955-3.618 8.674-4.486C18.56 2.9 21.56 9.591 15.586 13.408c-2.255 1.437-4.709 2.156-7.31 2.156-3.05 0-5.478-1.016-6.32-1.413.322-.57.718-1.09 1.19-1.561 2.948-2.974 7.756-2.825 7.806-2.825.198.024.372-.15.372-.347 0-.199-.15-.372-.347-.372-.223 0-5.18-.174-8.352 3.023-.273.298-.546.595-.769.917.223-1.933 1.463-5.675 7.014-7.484Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h20v20H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}
