import classNames from 'classnames';
import React, { useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { FaRegTrashAlt } from "react-icons/fa";
import { Button, Loader } from 'rizzui';
import { DocumentsService } from '../../services/document/documentsService';
import { toast } from 'sonner';
import CustomSelect from '../forms/CustomSelect';
import { usePrivilege } from '../priviledge/PriviledgeProvider';
import useDocumentTypes from '../../hooks/api-calls/useDocumentTypes';
import { FaFilePdf, FaFileVideo, FaFileExcel, FaFileAlt } from 'react-icons/fa';
import { MdOutlineAttachFile } from 'react-icons/md';
import { Document, Page, pdfjs } from 'react-pdf';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';

// Configurar el worker de PDF.js
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

interface DropzoneProps {
    id: string;
    entity: string;
    setUpdateList: (value: boolean) => void;
    companyId: string;
}

const DropzoneTutor: React.FC<DropzoneProps> = ({ id, entity, setUpdateList, companyId }) => {

    const { isAuthenticated } = useSelector((state: RootState) => state.tutor);
    const { userCan } = usePrivilege();
    const { getDocumentTypesList } = useDocumentTypes();
    const service = new DocumentsService();

    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [files, setFiles] = React.useState<any[]>([]);
    const [documentTypeId, setDocumentTypeId] = React.useState<string>('');
    const [pdfPreviews, setPdfPreviews] = React.useState<{ [key: string]: string }>({}); // Estado para almacenar los previews de PDFs

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop(acceptedFiles) {
            const newFiles = acceptedFiles.map(file => Object.assign(file, { preview: URL.createObjectURL(file) }));
            setFiles(prevFiles => [...prevFiles, ...newFiles]);

            // Crear previews para PDFs
            acceptedFiles.forEach(file => {
                if (file.type === 'application/pdf') {
                    const url = URL.createObjectURL(file);
                    setPdfPreviews(prev => ({ ...prev, [file.name]: url }));
                }
            });
        },
        multiple: true,
        maxFiles: 100,
        maxSize: 1048576000, // 1GB
    });

    // Limpiar los object URLs al desmontar el componente
    useEffect(() => {
        return () => {
            files.forEach(file => URL.revokeObjectURL(file.preview));
            Object.values(pdfPreviews).forEach(url => URL.revokeObjectURL(url));
        };
    }, [files, pdfPreviews]);

    const handleUpload = async (files: File[]) => {
        setIsLoading(true);
        try {
            const response = (await (service.uploadTutorDocuments(id, entity, files, documentTypeId, companyId))).getResponseData();
            if (response) {
                setDocumentTypeId('');
                setUpdateList(true);
                toast.success('Se ha realizado la subida de documentos');
                setFiles([]);
                setPdfPreviews({});
            } else {
                response.data.errors.forEach((error: any) => {
                    toast.error(error.message);
                });
            }
        } catch (error: any) {
            toast.error('Error al realizar la subida de documentos');
        } finally {
            setIsLoading(false);
        }
    };

    // Función para obtener el ícono o previsualización basado en el tipo de archivo
    const getFilePreview = (file: any) => {
        const fileType = file.type;

        if (fileType.startsWith('image/')) {
            // Si es una imagen, mostramos una previsualización de la misma
            return <img src={file.preview} alt={file.name} className="transform rounded-md object-contain max-h-32" />;
        } else if (fileType === 'application/pdf') {
            return <FaFilePdf size={24} className="me-3" />;
            /* return (
                <Document
                    file={pdfPreviews[file.name]}
                    className="pdf-preview"
                    loading={<FaFilePdf size={24} className="me-3" />}
                >
                    <Page pageNumber={1} width={100} />
                </Document>
            ); */
        } else if (fileType.startsWith('video/')) {
            return <FaFileVideo size={24} className="me-3" />;
        } else if (fileType.includes('excel') || fileType.includes('spreadsheet')) {
            return <FaFileExcel size={24} className="me-3" />;
        } else if (fileType.startsWith('text/') || fileType.includes('document')) {
            return <FaFileAlt size={24} className="me-3" />;
        } else {
            return <MdOutlineAttachFile size={24} className="me-3" />;
        }
    };

    return (
        <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
            <div
                {...getRootProps({
                    className: classNames('text-center mb-4', 'border-dashed border-2 border-gray-400 p-4', {
                        'col-span-4': files.length === 0 || !isDragActive, // En dispositivos móviles y si no hay archivos o no se está arrastrando
                        'col-span-3': files.length > 0 && isDragActive, // En dispositivos móviles, ocupa 3 columnas si hay archivos y se está arrastrando
                    }),
                    style: { cursor: isDragActive ? 'grab' : 'pointer', borderRadius: '15px' },
                })}
            >
                <input {...getInputProps()} />
                {isDragActive
                    ? <p className='text-muted500'>Suelta aquí el documento ...</p>
                    : <p className='text-muted500'>Arrastra aquí el documento o haz click para seleccionarlo</p>
                }
            </div>

            {files.length > 0 && (
                <div className="col-span-4 md:col-span-1 sm:col-span-4 flex flex-col">
                    <CustomSelect
                        isSearchable
                        required
                        id={'documentTypeId'}
                        label="Tipo de documento"
                        options={getDocumentTypesList()}
                        onChange={(e: any) => { setDocumentTypeId(e?.value); }}
                        display={userCan('list_document_type', 'document_type') || isAuthenticated}
                    />

                    <Button
                        color="primary"
                        onClick={() => { handleUpload(files) }}
                        className="my-3"
                        disabled={!documentTypeId || isLoading}
                    >
                        {isLoading ? <div className='text-center'><Loader /></div> : 'Subir documentos'}
                    </Button>
                </div>
            )}

            {/* Previsualización de archivos */}
            {files.length > 0 && (
                <div className="col-span-4 grid grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-4">
                    {files.map((file, index) => (
                        <div key={`${file.name}-${index}`} className="relative flex flex-col justify-between border p-2 rounded-lg min-h-[120px] bg-white shadow-sm">
                            <div className="flex items-center justify-start space-x-2">
                                {getFilePreview(file)}
                            </div>
                            {/* Ícono de eliminar en la esquina superior derecha */}
                            <FaRegTrashAlt
                                className="absolute top-2 right-2 cursor-pointer text-muted500 hover:text-red-500"
                                size={15}
                                onClick={() => {
                                    setFiles(prevFiles => prevFiles.filter((_, i) => i !== index));
                                    setPdfPreviews(prev => {
                                        const newPreviews = { ...prev };
                                        delete newPreviews[file.name];
                                        return newPreviews;
                                    });
                                }}
                            />
                            {/* Nombre del archivo y peso en la esquina inferior izquierda */}
                            <div className="mt-2 text-sm text-gray-700 truncate">
                                <span>{file.name}</span>
                                <br />
                                <span>{(file.size / 1024 / 1024).toFixed(2)} MB</span>
                            </div>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default DropzoneTutor;