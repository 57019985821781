import { AxiosResponse } from 'axios';
import { RestServiceConnection } from '../restServiceConnection';

const ENDPOINT = '/commercial-network';

export class NetworkService extends RestServiceConnection {

    listNetworks = async (filters?: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/list',
            data: filters,
        }, true) as AxiosResponse;
        return this;
    }

    getNetworkById = async (commercialNetworkId: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/get',
            data: { commercialNetworkId },
            headers: { "Content-Type": "application/json" }
        }, true);
        return this;
    }

    toggleNetworkState = async (commercialNetworkId: string, status: boolean) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/toggle',
            data: { commercialNetworkId, active: status },
            headers: { "Content-Type": "application/json" }
        }, true);
        return this;
    }

    createNetwork = async (user: FormData) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/create',
            data: user,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded"
            }
        }, true);
        return this;
    }

    editNetwork = async (user: FormData) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/edit',
            data: user,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded"
            }
        }, true);
        return this;
    }

    deleteNetwork = async (commercialNetworkId: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/delete',
            data: { commercialNetworkId },
            headers: { "Content-Type": "application/json" }
        }, true);
        return this;
    }

    deleteMultiNetworks = async (usersIds: string[]) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/delete-multi',
            data: { usersIds }
        }, true);
        return this;
    }

}