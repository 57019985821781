import { menuRoutes } from "./menu";
import Dashboard from "../pages/dashboard/Dashboard";
import UsersWrapper from "../pages/users";
import UsersListWrapper from "../pages/users/list/UsersListWrapper";
import OrganisationWrapper from "../pages/organisations";
import OrganisationsListWrapper from "../pages/organisations/list/OrganisationsListWrapper";
import OrganisationCreate from "../pages/organisations/create/OrganisationCreate";
import OrganisationEdit from "../pages/organisations/edit/OrganisationEdit";
import UserEdit from "../pages/users/edit/UserEdit";
import UserCreate from "../pages/users/create/UserCreate";
import UserInfo from "../pages/users/profile/views/UserInfo";
import RolesWrapper from "../pages/roles";
import RolesListWrapper from "../pages/roles/list/RolesListWrapper";
import RoleCreate from "../pages/roles/create/RoleCreate";
import RoleEdit from "../pages/roles/edit/RoleEdit";
import UserPermissions from "../pages/users/profile/views/UserPermissions";
import UserDocuments from "../pages/users/profile/views/documents/UserDocuments";
import UserChangelogsWrapper from "../pages/users/profile/views/changelogs/UserChangelogsWrapper";
import UserEditPermissions from "../pages/users/edit/UserEditPermissions";
import UserContracts from "../pages/users/profile/views/UserContracts";
import ProductsWrapper from "../pages/products";
import ProductsListWrapper from "../pages/products/list/ProductsListWrapper";
import ProductCreate from "../pages/products/create/ProductCreate";
import ProductEdit from "../pages/products/edit/ProductEdit";
import UserStats from "../pages/users/profile/views/UserStats";
import CorporationsWrapper from "../pages/corporations";
import CorporationsListWrapper from "../pages/corporations/list/CorporationsListWrapper";
import StatesWrapper from "../pages/states";
import StatesListWrapper from "../pages/states/list/StatesListWrapper";
import StateEdit from "../pages/states/edit/StateEdit";
import StateCreate from "../pages/states/create/StateCreate";
import TutorsWrapper from "../pages/tutors";
import TutorsListWrapper from "../pages/tutors/list/TutorsListWrapper";
import TutorCreate from "../pages/tutors/create/TutorCreate";
import TutorEdit from "../pages/tutors/edit/TutorEdit";
import NetworksWrapper from "../pages/networks";
import NetworksListWrapper from "../pages/networks/list/NetworksListWrapper";
import DepartmentsWrapper from "../pages/departments";
import DepartmentsListWrapper from "../pages/departments/list/DepartmentsListWrapper";
import TutorInfo from "../pages/tutors/profile/views/TutorInfo";
import TutorStats from "../pages/tutors/profile/views/TutorStats";
import TutorDocuments from "../pages/tutors/profile/views/documents/TutorDocuments";
import TutorsLayoutWrapper from "../pages/_tutor-layout";
import TutorProfile from "../pages/_tutor-layout/profile/TutorProfile";
import ListMyDocumentsWrapper from "../pages/_tutor-layout/documents/ListMyDocumentsWrapper";

const protectedRoutes = [
	/******************************************************
	 * Dashboard
	 ******************************************************/
	{
		path: menuRoutes.dashboard.path,
		element: <Dashboard />,
		access: {
			group: "dashboard",
			action: "get_dashboard_information"
		},
	},
	/******************************************************
	 * Organisations
	 ******************************************************/
	{
		path: menuRoutes.organisations.path,
		element: <OrganisationWrapper />,
		sub: [
			{
				element: <OrganisationsListWrapper />,
				access: {
					group: "companies",
					action: "list_company",
				},
				index: true,
			},
			{
				element: <OrganisationCreate />,
				path: menuRoutes.organisations.create,
				access: {
					group: "companies",
					action: "create_company"
				}
			},
			{
				element: <OrganisationEdit />,
				path: ':id/edit',
				access: {
					group: "companies",
					action: "edit_company"
				}
			}
		],
	},

	/******************************************************
	 * Roles
	 ******************************************************/
	{
		path: menuRoutes.roles.path,
		element: <RolesWrapper />,
		sub: [
			{
				element: <RolesListWrapper />,
				access: {
					group: 'roles',
					action: 'list_roles'
				},
				index: true,
			},
			{
				element: <RoleCreate />,
				path: menuRoutes.roles.create,
				access: {
					group: 'roles',
					action: 'create_roles'
				}
			},
			{
				element: <RoleEdit />,
				path: menuRoutes.roles.edit,
				access: {
					group: 'roles',
					action: 'edit_roles'
				}
			},
		]
	},

	/******************************************************
	 * Users
	 ******************************************************/
	{
		path: menuRoutes.users.path,
		element: <UsersWrapper />,
		sub: [
			{
				element: <UsersListWrapper />,
				access: {
					group: 'user',
					action: 'list_user'
				},
				index: true,
			},
			{
				element: <UserInfo />,
				path: menuRoutes.users.profile.path + menuRoutes.users.profile.info,
				access: {
					group: 'user',
					action: 'get_user'
				},
			},
			{
				element: <UserStats />,
				path: menuRoutes.users.profile.path + menuRoutes.users.profile.stats,
				access: {
					group: 'user',
					action: 'user_profile_stats'
				},
			},
			{
				element: <UserDocuments />,
				path: menuRoutes.users.profile.path + menuRoutes.users.profile.documents,
				access: {
					group: 'user',
					action: 'user_profile_documents'
				},
			},
			{
				element: <UserPermissions />,
				path: menuRoutes.users.profile.path + menuRoutes.users.profile.permissions,
				access: {
					group: 'user',
					action: 'user_profile_permissions'
				},
			},
			{
				element: <UserContracts />,
				path: menuRoutes.users.profile.path + menuRoutes.users.profile.contract_history,
				access: {
					group: 'user',
					action: 'user_profile_contract_history'
				},
			},
			{
				element: <UserChangelogsWrapper />,
				path: menuRoutes.users.profile.path + menuRoutes.users.profile.activity,
				access: {
					group: 'user',
					action: 'user_profile_activity'
				},
			},
			{
				element: <UserCreate />,
				path: menuRoutes.users.create,
				access: {
					group: 'user',
					action: 'create_user'
				}
			},
			{
				element: <UserEdit />,
				path: menuRoutes.users.edit,
				access: {
					group: 'user',
					action: 'edit_user'
				}
			},
			{
				element: <UserEditPermissions />,
				path: menuRoutes.users.edit_permissions,
				access: {
					group: 'user',
					action: 'edit_user'
				}
			},
		]
	},

	/******************************************************
	 * Tutors
	 ******************************************************/
	{
		path: menuRoutes.tutors.path,
		element: <TutorsWrapper />,
		sub: [
			{
				element: <TutorsListWrapper />,
				access: {
					group: 'tutors',
					action: 'list_tutors'
				},
				index: true,
			},
			{
				element: <TutorInfo />,
				path: menuRoutes.tutors.profile.path + menuRoutes.tutors.profile.info,
				access: {
					group: 'tutors',
					action: 'get_tutors'
				},
			},
			{
				element: <TutorStats />,
				path: menuRoutes.tutors.profile.path + menuRoutes.tutors.profile.stats,
				access: {
					group: 'tutors',
					action: 'tutors_stats'
				},
			},
			{
				element: <TutorDocuments />,
				path: menuRoutes.tutors.profile.path + menuRoutes.tutors.profile.documents,
				access: {
					group: 'tutors',
					action: 'tutors_documentation'
				},
			},
			{
				element: <TutorCreate />,
				path: menuRoutes.tutors.create,
				access: {
					group: 'tutors',
					action: 'create_tutors'
				}
			},
			{
				element: <TutorEdit />,
				path: menuRoutes.tutors.edit,
				access: {
					group: 'tutors',
					action: 'edit_tutors'
				}
			},
		]
	},

	/******************************************************
	 * Products
	 ******************************************************/
	{
		path: menuRoutes.products.path,
		element: <ProductsWrapper />,
		sub: [
			{
				element: <ProductsListWrapper />,
				access: {
					group: 'products',
					action: 'list_products'
				},
				index: true,
			},
			{
				element: <ProductCreate />,
				path: menuRoutes.products.create,
				access: {
					group: 'products',
					action: 'create_products'
				}
			},
			{
				element: <ProductEdit />,
				path: menuRoutes.products.edit,
				access: {
					group: 'products',
					action: 'edit_products'
				}
			}
		]
	},
	{
		path: menuRoutes.states.path,
		element: <StatesWrapper />,
		sub: [
			{
				element: <StatesListWrapper />,
				access: {
					group: 'statuses',
					action: 'admin_statuses'
				},
				index: true,
			},
			{
				element: <StatesListWrapper />,
				path: menuRoutes.states.path + menuRoutes.states.list.contract,
			},
			{
				element: <StatesListWrapper />,
				path: menuRoutes.states.path + menuRoutes.states.list.contract_product,
			},
			{
				element: <StatesListWrapper />,
				path: menuRoutes.states.path + menuRoutes.states.list.contract_invoicing,
			},
			{
				element: <StatesListWrapper />,
				path: menuRoutes.states.path + menuRoutes.states.list.contract_incidence,
			},
			{
				element: <StatesListWrapper />,
				path: menuRoutes.states.path + menuRoutes.states.list.expiration,
			},
			{
				element: <StatesListWrapper />,
				path: menuRoutes.states.path + menuRoutes.states.list.invoice,
			},
			{
				element: <StatesListWrapper />,
				path: menuRoutes.states.path + menuRoutes.states.list.incidence,
			},
			{
				element: <StatesListWrapper />,
				path: menuRoutes.states.path + menuRoutes.states.list.renovation,
			},
			{
				element: <StateCreate />,
				path: menuRoutes.states.create,
				access: {
					group: 'statuses',
					action: 'create_statuses'
				}
			},
			{
				element: <StateEdit />,
				path: menuRoutes.states.edit,
				access: {
					group: 'statuses',
					action: 'edit_statuses'
				}
			},
		]
	},

	/******************************************************
	 * Corporations
	 ******************************************************/
	{
		path: menuRoutes.corporations.path,
		element: <CorporationsWrapper />,
		sub: [
			{
				element: <CorporationsListWrapper />,
				access: {
					group: 'corporation',
					action: 'list_corporation'
				},
				index: true,
			},
		]
	},

	/******************************************************
	 * Networks
	 ******************************************************/
	{
		path: menuRoutes.networks.path,
		element: <NetworksWrapper />,
		sub: [
			{
				element: <NetworksListWrapper />,
				access: {
					group: 'commercial_network',
					action: 'list_commercial_network'
				},
				index: true,
			},
		]
	},

	/******************************************************
	 * Departments
	 * ****************************************************/
	{
		path: menuRoutes.departments.path,
		element: <DepartmentsWrapper />,
		sub: [
			{
				element: <DepartmentsListWrapper />,
				access: {
					group: 'departments',
					action: 'list_departments'
				},
				index: true,
			},
		]
	},


	/******************************************************
	 * Tutors admin panel
	 ******************************************************/
	{
		path: '',
		element: <TutorsLayoutWrapper />,
		sub: [
			{
				element: <TutorProfile />,
				path: menuRoutes.tutor_panel.profile,
				index: true,
			},
			{
				element: <ListMyDocumentsWrapper />,
				path: menuRoutes.tutor_panel.documents,
			},
		]
	},
];

const contents = [...protectedRoutes];
export default contents;