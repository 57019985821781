import { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { LoginService } from '../../../services/auth/loginService';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import { toast } from 'sonner';
import { Button, Input, Text } from 'rizzui';
import { useFormik } from 'formik';
import Spinner from '../../../components/bootstrap/Spinner';
import cn from '../../../utils/classNames';
import fullLogo from '../../../assets/logo_completo.png';
import { menuRoutes } from '../../../router/menu';

const TutorForgotPassword: FC = () => {

    const navigate = useNavigate();
    const { isAuthenticated } = useSelector((state: RootState) => state.tutor);

    const [isLoading, setIsLoading] = useState(false);

    if (isAuthenticated) {
        setTimeout(() => {
            navigate(menuRoutes.tutor_panel.tutorials, { replace: true })
        }, 100);
    }

    const handleEmailTutorForgotPassword = async (username: string) => {
        setIsLoading(true);
        const response = await (await (new LoginService()).sendEmailTutorForgotPassword(username)).getResponseData();

        if (!response.success) {
            toast.error(response.message);
            setIsLoading(false);
            return;
        }

        if (response.success) {
            try {
                navigate(menuRoutes.tutor_panel.login, { replace: true });
                toast.success('Email enviado. Por favor revise su correo.');
            } catch (error) {
                toast.error('Error sending email. Please try again later.');
                return;
            } finally {
                setIsLoading(false);
            }
        }
    };

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: { loginUsername: '' },
        validate: (values) => {
            const errors: { loginUsername?: string; loginPassword?: string } = {};
            if (!values.loginUsername) {
                errors.loginUsername = 'Requerido';
            }
            return errors;
        },
        validateOnChange: false,
        onSubmit: (values) => { handleEmailTutorForgotPassword(values.loginUsername) },
    });

    return (
        <div className="relative min-h-screen">
            <div className='w-full h-[100vh] grid grid-cols-3'>
                <div className='col-span-2 bg-gray-100 hidden lg:block'>
                    <div className='flex flex-col justify-center items-center h-full'>
                        <img src={fullLogo} height={'30vh'} alt="Logo de Attiva" />
                        <Text className="text-2xl font-bold text-gray-800 mt-5">Bienvenido Tutor</Text>
                    </div>
                </div>

                <div className={cn('mx-auto w-full max-w-md rounded-xl px-4 py-9 flex flex-col justify-center',)}>
                    <div className='flex flex-col justify-center text-center mb-5'>
                        <Text className="text-2xl font-bold text-gray-800 mt-5 mb-3">¿Has olvidado tu contraseña?</Text>
                        <Text className="text-sm text-gray-500">Introduce tu correo electrónico y te enviaremos un enlace para restablecer tu contraseña.</Text>
                    </div>

                    <form onSubmit={formik.handleSubmit}>
                        <div className="space-y-5">
                            <Input
                                id='loginUsername'
                                type="email"
                                size="lg"
                                label="Email"
                                placeholder="Introducte tu email"
                                className="[&>label>span]:font-medium"
                                inputClassName="text-sm"
                                onChange={formik.handleChange}
                                error={formik.errors.loginUsername}
                            />

                            <Button className="w-full" type="submit" size="lg" disabled={isLoading}>
                                {isLoading ? <Spinner /> : 'Enviar email de recuperación'}
                            </Button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default TutorForgotPassword;