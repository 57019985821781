import { ForwardRefExoticComponent, RefAttributes } from 'react';
import { Password, PasswordProps } from 'rizzui';

const overwriteLabel = (WrappedComponent: ForwardRefExoticComponent<PasswordProps & RefAttributes<HTMLInputElement>>) => {
    return ({ label, required = true, ...props }: any) => (
        <WrappedComponent
            label={<span>{label} {required && (<span className="text-red-500">*</span>)} </span>}
            {...props}
        />
    );
};

const RequiredPassword = overwriteLabel(Password);

export default RequiredPassword;