import { FC, useCallback, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useFetch from "../../../hooks/useFetch";
import { Loader } from "../../../components/loader/SpinnerLogo";
import { toast } from "sonner";
import { menuRoutes } from "../../../router/menu";
import TutorForm from "../TutorForm";
import PageHeader from "../../../layout/shared/page-header";
import AttivaCard from "../../../components/card/AttivaCard";
import { TutorService } from "../../../services/tutor/tutorService";
import { Tutor } from "../../../type/entities/tutor-type";
import useHandleErrors from "../../../hooks/useHandleErrors";

const pageHeader = {
    title: "Editar Tutor",
    breadcrumb: [
        {
            href: menuRoutes.tutors.path,
            name: "Tutores",
        },
        {
            name: "Editar",
        },
    ],
};

const TutorEdit: FC = () => {

    const { id = "" } = useParams<{ id: string }>();
    const navigate = useNavigate();
    const { handleErrors } = useHandleErrors();
    const service = new TutorService();

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [data, loading] = useFetch(useCallback(async () => {
        const response = await service.getTutorById(id as string);
        return response.getResponseData() as Tutor;
    }, [id]));

    const handleSubmit = async (values: any) => {
        setIsLoading(true);
        try {
            const response = await service.editTutor(values);
            const responseData = response.getResponseData();

            if (responseData.success) {
                navigate(-1);
                toast.success("Tutor editado correctamente");
            } else {
                responseData.data.errors.forEach((error: any) => {
                    toast.error(error.message);
                });
            }
        } catch (error: any) {
            handleErrors(error);
        } finally {
            setIsLoading(false);
        }
    };

    if (loading) return <Loader />;

    return (
        <>
            <PageHeader title={pageHeader.title} breadcrumb={pageHeader.breadcrumb}>
            </PageHeader>

            <AttivaCard title="Datos Principales" className="mb-4">
                {(data !== null && data !== undefined) && <TutorForm data={data} submit={handleSubmit} isLoading={isLoading} />}
            </AttivaCard>
        </>
    );
};

export default TutorEdit;