import { useCallback, useEffect, useState } from 'react';
import { NetworkService } from '../../services/networks/networkService';

/*----------------------------------------------------------------------------------------
* EN: Hook to manage networks fetching and transformation logic.
* ES: Hook para gestionar la lógica de obtención y transformación de redes comerciales.
----------------------------------------------------------------------------------------*/

const useNetowrks = () => {

    const [data, setData] = useState<any[]>([]);

    const fetchNetworks = useCallback(async () => {
        try {
            const service = new NetworkService();
            const response = await service.listNetworks({ limit: 999999 });
            const fetchedData = response.getResponseData().data;

            if (fetchedData && fetchedData?.data) {
                const mappedData = fetchedData.data?.map((data: { id: string; name: string; }) => ({
                    value: data.id,
                    label: data.name,
                }));
                setData(mappedData);
            }
        } catch (error) {
            console.log('Error fetching networks:', error);
        }
    }, []);

    useEffect(() => {
        fetchNetworks();
    }, []);

    const getNetworksList = () => {
        return data;
    };

    return { fetchNetworks, getNetworksList };
}

export default useNetowrks;