// src/store/slices/userProfileSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface UserProfileState {
    selectedView: string;
}

const initialState: UserProfileState = {
    selectedView: '/info',
};

const userProfileSlice = createSlice({
    name: 'userProfile',
    initialState,
    reducers: {
        setSelectedView(state, action: PayloadAction<string>) {
            state.selectedView = action.payload;
        },
    },
});

export const { setSelectedView } = userProfileSlice.actions;
export default userProfileSlice.reducer;