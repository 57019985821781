import { Fragment } from "react";
import { FiltersProvider } from "../../../components/providers/FiltersProvider";
import PageHeader from "../../../layout/shared/page-header";
import { menuRoutes } from "../../../router/menu";
import MyDocuments from "./MyDocuments";

const ListMyDocumentsWrapper = () => {

    const breadCrumb = [
        { name: 'Panel de control del tutor' },
        { name: 'Mis documentos', href: menuRoutes.tutor_panel.documents },
    ];

    const defaultFilters = {
        active: true,
    };

    return (
        <Fragment>
            <FiltersProvider defaultFilterFilters={defaultFilters}>
                <PageHeader title="Listado de mis documentos" breadcrumb={breadCrumb}></PageHeader>
                <MyDocuments />
            </FiltersProvider>
        </Fragment>
    );
}

export default ListMyDocumentsWrapper;