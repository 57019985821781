import { Fragment, useContext } from "react";
import { FiltersProvider } from "../../../components/providers/FiltersProvider";
import PageHeader from "../../../layout/shared/page-header";
import { Button } from "rizzui";
import classNames from "classnames";
import { PrivilegeContext } from "../../../components/priviledge/PriviledgeProvider";
import { useNavigate } from "react-router";
import { menuRoutes } from "../../../router/menu";
import RolesList from "./RolesList";
import cn from "../../../utils/classNames";

const RolesListWrapper = () => {

    const { userCan } = useContext(PrivilegeContext);
    const navigate = useNavigate();

    const breadCrumb = [
        { name: 'Roles', href: menuRoutes.roles.path },
        { name: 'Listado', href: menuRoutes.roles.path },
    ];

    return (
        <Fragment>
            <FiltersProvider>
                <PageHeader title="Listado de roles" breadcrumb={breadCrumb}>
                    <Button
                        color="primary"
                        onClick={() => { navigate(menuRoutes.roles.create) }}
                        className={cn('mt-3 md:mt-0',[classNames({ 'hidden': !userCan('create_roles', 'roles') })])}
                    >
                        Crear rol
                    </Button>
                </PageHeader>
                <RolesList />
            </FiltersProvider>
        </Fragment>
    );
}

export default RolesListWrapper;