export default function RolesIcon({ ...props }: React.SVGProps<SVGSVGElement>) {

    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 250 256" {...props} >
          <path
            fill="#e7f2ff"
            d="M22.406 91.836l13.198 83.737a65.039 65.039 0 0031.394 46.006l39.626 23.193a53.39 53.39 0 0053.937 0l39.626-23.193a65.037 65.037 0 0031.393-46.006c3.608-22.887 8.732-55.398 12.691-83.861a64.52 64.52 0 00-56.149-74.095c-36.341-3.963-72.681-3.951-109.083-.502l-.048.006a65.035 65.035 0 00-56.585 74.715z"
          ></path>
          <path
            fill="#4895ff"
            d="M139.583 133.918c-1.15 0-2.083.933-2.083 2.083v96.811a2.082 2.082 0 002.443 2.052 36.684 36.684 0 0012.199-4.477l39.626-23.192a48.372 48.372 0 0023.349-34.217 9395.736 9395.736 0 005.726-36.66 2.085 2.085 0 00-2.059-2.4z"
          ></path>
          <path
            fill="#96c8ef"
            d="M129.167 136.001c0-1.15-.933-2.083-2.084-2.083H48.349a2.08 2.08 0 00-2.058 2.408l5.777 36.653a48.371 48.371 0 0023.348 34.216l39.627 23.192a36.659 36.659 0 0011.649 4.376 2.082 2.082 0 002.475-2.046z"
          ></path>
          <path
            fill="#4895ff"
            d="M129.167 33.357a2.082 2.082 0 00-2.103-2.084c-15.422.141-30.858.964-46.321 2.425l-.061.006a48.372 48.372 0 00-41.813 55.537l5.451 34.585a2.084 2.084 0 002.058 1.758h80.705a2.083 2.083 0 002.084-2.083z"
          ></path>
          <path
            fill="#96c8ef"
            d="M139.615 31.31a2.085 2.085 0 00-2.115 2.083v90.108c0 1.151.933 2.083 2.083 2.083h81.106c1.03 0 1.906-.753 2.06-1.772a3012.64 3012.64 0 005.055-34.675 47.853 47.853 0 00-41.559-54.959l-.023-.003a499.883 499.883 0 00-46.607-2.865z"
          ></path>
        </svg>
      );
    }