export default function ShoppingCartIcon({
  ...props
}: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      fill="none"
      {...props}
    >
      <path
        fill="#F5F5F5"
        d="M18.145 4.57v12.813c0 .863-.7 1.562-1.563 1.562H3.418c-.862 0-1.562-.7-1.562-1.562V4.57h16.289Z"
      />
      <path
        fill="#E6E6E6"
        d="M4.2 18.945h-.782c-.862 0-1.562-.7-1.562-1.562V4.57h.781v12.813c0 .863.7 1.562 1.563 1.562Z"
      />
      <path
        fill="#E6E6E6"
        d="M18.145 5.273v5.091a1.87 1.87 0 0 1-.313.027h-.273c-.518 0-.987-.21-1.326-.55a1.868 1.868 0 0 1-.55-1.325c0 .518-.209.986-.548 1.325a1.875 1.875 0 0 1-3.2-1.326c-.001 1.036-.84 1.876-1.876 1.876h-.04c-.517 0-.986-.21-1.325-.55a1.868 1.868 0 0 1-.55-1.325c0 1.035-.839 1.875-1.874 1.875h-.078c-.518 0-.987-.21-1.326-.55a1.868 1.868 0 0 1-.55-1.325c0 1.035-.839 1.875-1.874 1.875h-.274a1.87 1.87 0 0 1-.312-.027v-5.09h16.289Z"
      />
      <path
        fill="#FD646F"
        d="m4.738 4.57-.382 2.62c-.026.206-.04.414-.04.622l-.001.098c-.051.99-.87 1.777-1.873 1.777h-.274c-.518 0-.986-.21-1.325-.549a1.867 1.867 0 0 1-.55-1.326c0-.205.046-.409.134-.595L1.856 4.57l1.314-.39 1.568.39Z"
      />
      <path
        fill="#FFC682"
        d="M8.145 4.57v3.242c0 .033 0 .066-.002.098-.051.99-.87 1.777-1.873 1.777h-.078c-.518 0-.987-.21-1.326-.549a1.867 1.867 0 0 1-.55-1.326c0-.208.014-.416.04-.622l.382-2.62 1.65-.39 1.757.39Z"
      />
      <path
        fill="#FD646F"
        d="M11.934 4.57v3.242c0 .033 0 .066-.002.098-.051.99-.87 1.777-1.873 1.777h-.039a1.875 1.875 0 0 1-1.875-1.875V4.57l1.934-.39 1.855.39Z"
      />
      <path
        fill="#FFC682"
        d="M15.684 7.812c0 .033 0 .066-.002.098-.025.48-.23.91-.547 1.228a1.875 1.875 0 0 1-3.2-1.326V4.57l1.737-.39 1.63.39.346 2.62c.024.206.036.414.036.622Z"
      />
      <path
        fill="#FD646F"
        d="M19.707 7.812c0 .033 0 .066-.002.098-.051.99-.87 1.777-1.873 1.777h-.273c-.518 0-.987-.21-1.326-.549a1.867 1.867 0 0 1-.55-1.326c0-.208-.011-.416-.035-.623l-.346-2.619 1.418-.39 1.425.39 1.429 2.647a1.382 1.382 0 0 1 .133.595Z"
      />
      <path
        fill="#50758D"
        d="M13.823 12.011a.293.293 0 0 0-.287-.35H6.932l-.01-.047a1.08 1.08 0 0 0-1.05-.852h-.5a.293.293 0 1 0 0 .586h.5c.229 0 .43.162.477.387l.87 4.112c.083.389.374.693.74.805a.88.88 0 1 0 1.639.047h1.312a.88.88 0 1 0 1.657 0h.07a.293.293 0 1 0 0-.586H8.27a.49.49 0 0 1-.477-.387l-.075-.355H12.905c.14 0 .26-.098.287-.235l.308-1.526a.29.29 0 0 0 .015-.073l.308-1.526Zm-4.76 4.981a.293.293 0 1 1-.587 0 .293.293 0 0 1 .587 0Zm2.969 0a.293.293 0 1 1-.587 0 .293.293 0 0 1 .587 0Zm.949-3.77H11.6l.123-.976h1.454l-.197.977Zm-3.523 1.563-.122-.977h1.602l-.122.977H9.458Zm-.195-1.562-.122-.977h1.992l-.122.977H9.263Zm-.713-.977.123.977h-1.41l-.207-.977H8.55Zm-1.163 1.563h1.359l.122.976H7.593l-.206-.977Zm4.02.976.122-.977h1.333l-.197.977h-1.258Z"
      />
      <path
        fill="#FC4755"
        d="M.296 7.91h4.019c-.051.99-.87 1.777-1.873 1.777h-.273c-.518 0-.987-.21-1.326-.549A1.867 1.867 0 0 1 .296 7.91Z"
      />
      <path
        fill="#FFAC5C"
        d="M4.32 7.91h3.823c-.051.99-.87 1.777-1.873 1.777h-.078c-.518 0-.987-.21-1.326-.549A1.867 1.867 0 0 1 4.32 7.91Z"
      />
      <path
        fill="#FC4755"
        d="M8.147 7.91h3.785c-.051.99-.87 1.777-1.873 1.777h-.039A1.875 1.875 0 0 1 8.147 7.91Z"
      />
      <path
        fill="#FFAC5C"
        d="M11.936 7.91h3.745c-.024.48-.229.91-.547 1.228a1.875 1.875 0 0 1-3.198-1.228Z"
      />
      <path
        fill="#FC4755"
        d="M19.705 7.91c-.051.99-.87 1.777-1.873 1.777h-.273c-.518 0-.987-.21-1.326-.549a1.867 1.867 0 0 1-.547-1.228h4.019Z"
      />
      <path
        fill="#50758D"
        d="M1.856 4.57V2.617c0-.863.7-1.562 1.562-1.562h13.164c.863 0 1.563.7 1.563 1.562V4.57H1.856Z"
      />
      <path
        fill="#2B597F"
        d="M4.311 3.375a.88.88 0 1 0-1.347-1.127l-.223.26a.88.88 0 1 0 1.347 1.127l.223-.26Zm2.314.008a.88.88 0 1 0-1.347-1.127l-.223.26a.88.88 0 1 0 1.347 1.127l.223-.26Zm2.305 0a.88.88 0 1 0-1.347-1.127l-.223.26a.88.88 0 1 0 1.347 1.127l.223-.26Z"
      />
      <path
        fill="#FD646F"
        d="M3.643 3.68a.88.88 0 0 1 0-1.758.88.88 0 0 1 0 1.758Z"
      />
      <path
        fill="#FFC682"
        d="M5.958 3.691a.88.88 0 0 1 0-1.757.88.88 0 0 1 0 1.757Z"
      />
      <path
        fill="#93DDB7"
        d="M8.262 3.691a.88.88 0 0 1 0-1.757.88.88 0 0 1 0 1.757Z"
      />
      <path
        fill="#2B597F"
        d="M17.055 3.383a.88.88 0 0 0-.668-1.45h-5.195a.878.878 0 0 0-.68.322l-.223.26a.88.88 0 0 0 .668 1.45h5.196a.877.877 0 0 0 .679-.322l.223-.26Z"
      />
      <path
        fill="#F5F5F5"
        d="M16.387 3.691h-5.195a.88.88 0 0 1 0-1.757h5.195a.88.88 0 0 1 0 1.757Z"
      />
      <path
        fill="#FFC682"
        d="M5.958 3.691a.88.88 0 0 1 0-1.757.88.88 0 0 1 0 1.757Z"
      />
      <path
        fill="#93DDB7"
        d="M8.262 3.691a.88.88 0 0 1 0-1.757.88.88 0 0 1 0 1.757Z"
      />
      <path
        fill="currentColor"
        d="M2.764 2.8a.88.88 0 0 0 1.758 0 .88.88 0 0 0-1.758 0Zm1.172 0a.293.293 0 1 1-.587 0 .293.293 0 0 1 .587 0Zm1.142.013a.88.88 0 0 0 1.758 0 .88.88 0 0 0-1.758 0Zm1.172 0a.293.293 0 1 1-.587-.001.293.293 0 0 1 .587 0Zm1.133 0a.88.88 0 0 0 1.758 0 .88.88 0 0 0-1.758 0Zm1.172 0a.293.293 0 1 1-.587-.001.293.293 0 0 1 .587 0Zm1.758 0a.88.88 0 0 0 .878.878h5.196a.88.88 0 0 0 0-1.757H11.19a.88.88 0 0 0-.878.878Zm6.367 0a.293.293 0 0 1-.293.292H11.19a.293.293 0 0 1 0-.585h5.196c.161 0 .293.131.293.292Z"
      />
      <path
        fill="currentColor"
        d="M20 7.812a1.702 1.702 0 0 0-.168-.734l-1.395-2.582V2.617A1.858 1.858 0 0 0 16.582.762H14.57a.293.293 0 1 0 0 .586h2.012c.7 0 1.27.57 1.27 1.27v1.66H2.149v-1.66c0-.7.57-1.27 1.27-1.27h9.784a.293.293 0 1 0 0-.586H3.418a1.858 1.858 0 0 0-1.855 1.855v1.88l-.62 1.147a.293.293 0 1 0 .515.278l.572-1.059H4.4l-.333 2.285v.005c-.028.218-.042.44-.042.66 0 .872-.71 1.581-1.582 1.581-.96.11-1.863-.587-1.856-1.582-.009-.239.1-.482.221-.683a.293.293 0 0 0-.516-.279c-.173.287-.297.624-.29.962 0 .986.66 1.819 1.562 2.082v7.489c0 1.023.832 1.855 1.855 1.855h13.164a1.858 1.858 0 0 0 1.855-1.855v-1.23a.293.293 0 0 0-.585 0v1.23c0 .7-.57 1.27-1.27 1.27H3.418c-.7 0-1.27-.57-1.27-1.27V9.98h.294c.8 0 1.5-.436 1.875-1.082.25.43.644.773 1.13.95a.293.293 0 0 0 .2-.55A1.588 1.588 0 0 1 4.61 7.813c0-.195.013-.39.036-.584l.345-2.365h2.86v2.95c0 .661-.416 1.258-1.037 1.486a.293.293 0 1 0 .201.55 2.17 2.17 0 0 0 1.13-.951A2.169 2.169 0 0 0 10.02 9.98h.039c.8 0 1.499-.435 1.875-1.081a2.169 2.169 0 0 0 1.875 1.081c.8 0 1.499-.435 1.875-1.081a2.169 2.169 0 0 0 1.875 1.081h.293v4.805a.293.293 0 1 0 .585 0V9.894A2.171 2.171 0 0 0 20 7.812Zm-9.941 1.582h-.04c-.872 0-1.581-.71-1.581-1.582V4.863h3.203v2.95c0 .872-.71 1.581-1.582 1.581Zm3.75 0c-.873 0-1.582-.71-1.582-1.582V4.863h2.818l.312 2.362c.022.195.034.391.034.587 0 .873-.71 1.582-1.582 1.582Zm4.339-.031c-.174.033-.41.035-.59.031-.872 0-1.581-.71-1.581-1.582 0-.218-.013-.439-.038-.656v-.005l-.303-2.288h2.334l1.342 2.486c.067.144.102.304.102.463 0 .765-.545 1.404-1.266 1.55Z"
      />
      <path
        fill="#000"
        d="M9.199 15.371h3.706c.14 0 .26-.098.287-.235l.63-3.125a.295.295 0 0 0-.287-.35H6.932a1.082 1.082 0 0 0-1.061-.9h-.5a.293.293 0 0 0 0 .587h.5c.229 0 .43.162.477.387l.871 4.112c.082.389.374.693.74.805a.88.88 0 1 0 1.639.047h1.312a.88.88 0 1 0 1.657 0h.07a.293.293 0 1 0 0-.586H8.27a.49.49 0 0 1-.478-.387l-.075-.355h1.481Zm-.137 1.621a.293.293 0 1 1-.586 0 .293.293 0 0 1 .586 0Zm2.97 0a.293.293 0 1 1-.587 0 .293.293 0 0 1 .586 0Zm-1.094-3.183-.123.976H9.459l-.122-.976h1.602Zm-1.676-.586-.122-.977h1.993l-.122.977H9.262Zm2.144 1.562.122-.976h1.334l-.197.976h-1.259Zm1.574-1.562h-1.379l.122-.977h1.454l-.197.977Zm-4.43-.977.122.977h-1.41l-.206-.977H8.55ZM7.386 13.81h1.36l.121.976H7.593l-.207-.976Z"
      />
    </svg>
  );
}
