import { useCallback, useEffect, useState } from 'react';
import { usePrivilege } from '../../components/priviledge/PriviledgeProvider';
import { DocumentTypeService } from '../../services/document-type/documentTypeService';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';

/*----------------------------------------------------------------------------------------
* EN: Hook to manage document types fetching and transformation logic.
* ES: Hook para gestionar la lógica de obtención y transformación de tipos de documentos.
----------------------------------------------------------------------------------------*/

const useDocumentTypes = () => {

    const { isAuthenticated } = useSelector((state: RootState) => state.tutor);
    const { userCan } = usePrivilege();
    const [models, setRoles] = useState<any[]>([]);

    const fetchDocumentTypes = useCallback(async (companyId?: string) => {
        if (!userCan('list_document_type', 'document_type') && !isAuthenticated) return;

        try {
            let service = isAuthenticated
                ? new DocumentTypeService().listTutorDocumentTypes({ filter_filters: { companyId: companyId }, limit: 999999 })
                : new DocumentTypeService().listDocumentTypes({ filter_filters: { companyId: companyId }, limit: 999999 });
            const response = await service;
            const fetchedData = response.getResponseData().data;

            if (fetchedData && fetchedData?.data) {
                const mappedModels = fetchedData.data?.map((data: { id: string; name: string; }) => ({
                    value: data.id,
                    label: data.name,
                }));
                setRoles(mappedModels);
            }
        } catch (error) {
            console.log('Error fetching document types:', error);
        }
    }, []);

    useEffect(() => {
        fetchDocumentTypes();
    }, []);

    const getDocumentTypesList = () => {
        return models;
    };

    return { fetchDocumentTypes, getDocumentTypesList };
}

export default useDocumentTypes;