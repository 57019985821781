import React, { useCallback, useEffect, useState } from 'react';
import ProfileLayout from '../ProfileLayout';
import { useParams } from 'react-router-dom';
import { UserService } from '../../../../services/user/userService';
import useFetch from '../../../../hooks/useFetch';
import { UserApiResponse } from '../../../../type/entities/user-type';
import FormGroup from '../../../../layout/shared/form-group';
import CustomSelect from '../../../../components/forms/CustomSelect';
import useRoles from '../../../../hooks/api-calls/useRoles';
import { ShowPermissionAccordion } from '../../../../components/ui/PermissionsAccordion';
import { Permission, PermissionGroup, PermissionsApiResponse, RolePermission } from '../../../../type/entities/role-type';
import { PermissionService } from '../../../../services/auth/permissionService';
import cn from '../../../../utils/classNames';
import { Loader } from '../../../../components/loader/SpinnerLogo';

interface UserPermissionsProps { }

const UserPermissions: React.FC<UserPermissionsProps> = () => {

    const { id = '' } = useParams<{ id: string }>();
    const { getRolesList } = useRoles();
    const [selectedPermissions, setSelectedPermissions] = useState<number[]>([]);

    const [data] = useFetch(useCallback(async () => {
        if (!id || id === '') return null;
        const response = await (new UserService()).getUserById(id);
        return response.getResponseData() as UserApiResponse;
    }, [id]));

    const [permissionsData, permissionsLoading] = useFetch(useCallback(async () => {
        const response = await (new PermissionService()).getPermissions();
        return response.getResponseData() as PermissionsApiResponse;
    }, []));

    function getUserRolesValues() {
        return data?.userRoles.map((roleWrapper: any) => (
            { value: roleWrapper.role.id, label: roleWrapper.role.name }
        ));
    };

    useEffect(() => {
        if (data) setSelectedPermissions(data?.userPermissions.map((permission: RolePermission) => permission.permission.id));
    }, [data]);

    return (
        <ProfileLayout>
            <div className="@container">
                <div className="mb-10 grid gap-7 divide-y-2 divide-dashed divide-gray-200 @2xl:gap-9 @3xl:gap-11">
                    <FormGroup
                        title="Rol del usuario"
                        className="pt-7 @2xl:pt-9 @3xl:grid-cols-12 @3xl:pt-11"
                    >
                        <CustomSelect
                            isDisabled
                            id={'roleId'}
                            label="Rol"
                            value={getUserRolesValues()}
                            options={getRolesList()}
                        />
                    </FormGroup>

                    {(permissionsData && !permissionsLoading)
                        ? permissionsData.map((permissionGroup: PermissionGroup) => {
                            const atLeastOneSelected = permissionGroup.permissions.some((permission: Permission) => selectedPermissions.includes(permission.id));

                            return (
                                <FormGroup
                                    key={`index-${permissionGroup.id}`}
                                    title={permissionGroup.label}
                                    description='Lista de permisos asignados al usuario'
                                    className='pt-6'
                                    childClassName='col-span-full grid gap-4 sm:grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 @4xl:col-span-8 @4xl:gap-5 xl:gap-7'
                                >
                                    <div key={permissionGroup.id} className={cn([atLeastOneSelected ? '' : 'hidden absolute', "col-span-full"])}>
                                        <ShowPermissionAccordion
                                            group={permissionGroup}
                                            selectAll={[]}
                                            selectedPermissions={selectedPermissions}
                                        />
                                    </div>
                                </FormGroup>
                            )
                        })
                        : <Loader height='50vh' />
                    }
                </div>
            </div>
        </ProfileLayout>
    );
};

export default UserPermissions;