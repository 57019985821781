import { useCallback } from 'react';

/**
 * Hook para generar la estructura de árbol de departamentos.
 */
const useDepartmentTree = (data: any, user: any) => {
    /**
     * Construir el árbol recursivamente.
     */
    const buildTree = useCallback((data: any, parentId = null, level = '1') => {
        const children = data.filter((item: any) =>
            parentId ? item.parentDepartment?.id === parentId : !item.parentDepartment
        );

        return children.map((child: any, index: number) => ({
            name: child.name,
            id: child.id,
            createdAt: child.createdAt.date,
            updatedAt: child.updatedAt?.date,
            viewStates: child.departmentsHasStatuses.filter((s: any) => s.action === 'view'),
            viewStatesCount: child.departmentsHasStatuses.filter((s: any) => s.action === 'view').length,
            editStates: child.departmentsHasStatuses.filter((s: any) => s.action === 'edit'),
            editStatesCount: child.departmentsHasStatuses.filter((s: any) => s.action === 'edit').length,
            users: child.user,
            usersCount: child.user.length,
            products: child.departmentsHasProduct,
            productsCount: child.departmentsHasProduct.length,
            attributes: { Red: child.commercialNetwork?.name },
            children: buildTree(data, child.id, `${level}.${index + 1}`),
        }));
    }, []);

    /**
     * Generar la estructura completa del árbol a partir de los datos.
     */
    const generateNetworkTree = useCallback(() => {
        return {
            name: user?.companyName || 'Attiva',
            id: '1',
            children: buildTree(data),
        };
    }, [user, data, buildTree]);

    return generateNetworkTree;
};

export default useDepartmentTree;