import { useCallback, useEffect, useState } from 'react';
import {ProductService} from "../../services/product/productService";

/*----------------------------------------------------------------------------------------
* EN: Hook to manage product fetching and transformation logic.
* ES: Hook para gestionar la lógica de obtención y transformación de productos.
----------------------------------------------------------------------------------------*/

const useProducts = (initialFilters = {}) => {

    const [data, setData] = useState<any[]>([]);
    const [filters, setFilters] = useState(initialFilters);

    const fetchProducts = useCallback(async () => {
        try {
            const service = new ProductService();
            const response = await service.listProducts(filters);
            const fetchedData = response.getResponseData().data;

            if (fetchedData && fetchedData?.data) {
                const mappedData = fetchedData.data?.map((data: { id: string; name: string; }) => ({
                    value: data.id,
                    label: data.name,
                }));
                setData(mappedData);
            }
        } catch (error) {
            console.log('Error fetching products:', error);
        }
    }, []);

    useEffect(() => {
        fetchProducts();
    }, []);

    const updateProductsHookFilters = (newFilters: any) => {
        setFilters((prevFilters) => ({ ...prevFilters, ...newFilters }));
    };


    const getProductsList = () => {
        return data;
    };

    return { fetchProducts, getProductsList, updateProductsHookFilters };
}

export default useProducts;