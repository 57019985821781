export default function ApartmentIcon({
  ...props
}: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 480.07 480" {...props}>
      <path fill="#fd882f" d="M72.03 8.04h336v464h-336zm0 0" />
      <path
        fill="#fd882f"
        d="M48.03.04h384v16h-384zM48.03 32.04h384v16h-384zM48.03 176.04h384v16h-384zM48.03 320.04h384v16h-384zm0 0"
      />
      <path fill="#dee1e3" d="M56.03 472.04h-32l-16-56h64zm0 0" />
      <path
        fill="#88b337"
        d="M72.03 312.04c0-5.7-1.55-11.3-4.49-16.19a14.92 14.92 0 0 1-.06-15.52 31.89 31.89 0 0 0-20-47.45 32.05 32.05 0 0 0-39.45 31.16c0 5.7 1.54 11.28 4.48 16.17a15.08 15.08 0 0 1 0 15.65 31.42 31.42 0 0 0 0 32.36 14.93 14.93 0 0 1 .06 15.52 31.89 31.89 0 0 0 20 47.45 32.06 32.06 0 0 0 39.46-31.15c0-5.7-1.55-11.3-4.48-16.18a15.08 15.08 0 0 1 0-15.65 31.38 31.38 0 0 0 4.48-16.17zm0 0"
      />
      <path fill="#a3603e" d="M32.03 264.04h16v152h-16zm0 0" />
      <path fill="#dee1e3" d="M456.03 472.04h-32l-16-56h64zm0 0" />
      <path
        fill="#88b337"
        d="M472.03 312.04c0-5.7-1.55-11.3-4.49-16.19a14.92 14.92 0 0 1-.06-15.52 31.89 31.89 0 0 0-20-47.45 32.05 32.05 0 0 0-39.45 31.16c0 5.7 1.54 11.28 4.48 16.17a15.08 15.08 0 0 1 0 15.65 31.42 31.42 0 0 0 0 32.36 14.93 14.93 0 0 1 .06 15.52 31.89 31.89 0 0 0 20 47.45 32.06 32.06 0 0 0 39.46-31.15c0-5.7-1.55-11.3-4.48-16.18a15.08 15.08 0 0 1 0-15.65 31.38 31.38 0 0 0 4.48-16.17zm0 0"
      />
      <path fill="#a3603e" d="M432.03 264.04h16v152h-16zm0 0" />
      <g fill="#ef701b">
        <path d="M72.03 96.04h24v16h-24zM192.03 120.04h24v16h-24zM384.03 144.04h24v16h-24zM280.03 80.04h16v16h-16zM288.03 224.04h24v16h-24zM88.03 280.04h16v16h-16zM272.03 304.04h16v16h-16zM384.03 368.04h16v16h-16zM192.03 416.04h16v16h-16zM104.03 144.04h24v16h-24zm0 0" />
      </g>
      <path fill="#a12a2a" d="M120.03 72.04h48v80h-48zm0 0" />
      <path fill="#dee1e3" d="M120.03 72.04h48v40h-48zm0 0" />
      <path fill="#c0c3c4" d="M120.03 72.04h48v16h-48zm0 0" />
      <path fill="#a12a2a" d="M216.03 72.04h48v80h-48zm0 0" />
      <path fill="#dee1e3" d="M216.03 72.04h48v40h-48zm0 0" />
      <path fill="#c0c3c4" d="M216.03 72.04h48v16h-48zm0 0" />
      <path fill="#a12a2a" d="M312.03 72.04h48v80h-48zm0 0" />
      <path fill="#dee1e3" d="M312.03 72.04h48v40h-48zm0 0" />
      <path fill="#c0c3c4" d="M312.03 72.04h48v16h-48zm0 0" />
      <path fill="#a12a2a" d="M120.03 216.04h48v80h-48zm0 0" />
      <path fill="#dee1e3" d="M120.03 216.04h48v40h-48zm0 0" />
      <path fill="#c0c3c4" d="M120.03 216.04h48v16h-48zm0 0" />
      <path fill="#a12a2a" d="M216.03 216.04h48v80h-48zm0 0" />
      <path fill="#dee1e3" d="M216.03 216.04h48v40h-48zm0 0" />
      <path fill="#c0c3c4" d="M216.03 216.04h48v16h-48zm0 0" />
      <path fill="#a12a2a" d="M312.03 216.04h48v80h-48zm0 0" />
      <path fill="#dee1e3" d="M312.03 216.04h48v40h-48zm0 0" />
      <path fill="#c0c3c4" d="M312.03 216.04h48v16h-48zm0 0" />
      <path fill="#a12a2a" d="M120.03 360.04h48v80h-48zm0 0" />
      <path fill="#dee1e3" d="M120.03 360.04h48v40h-48zm0 0" />
      <path fill="#c0c3c4" d="M120.03 360.04h48v16h-48zm0 0" />
      <path fill="#a12a2a" d="M216.03 360.04h48v80h-48zm0 0" />
      <path fill="#dee1e3" d="M216.03 360.04h48v40h-48zm0 0" />
      <path fill="#c0c3c4" d="M216.03 360.04h48v16h-48zm0 0" />
      <path fill="#a12a2a" d="M312.03 360.04h48v80h-48zm0 0" />
      <path fill="#912626" d="M312.03 360.04h48v16h-48zm0 0" />
      <path fill="#ff9747" d="M296.03 440.04h80v32h-80zm0 0" />
      <path fill="#c0c2c4" d="M.03 464.04h480v16H.03zm0 0" />
      <path
        fill="#ef701b"
        d="M72.03 16.04h336v16h-336zM72.03 48.04h336v16h-336zM72.03 192.04h336v16h-336zM72.03 336.04h336v16h-336zm0 0"
      />
      <path d="M208.03 152.04a8 8 0 0 0 8 8h48a8 8 0 0 0 8-8v-80a8 8 0 0 0-8-8h-48a8 8 0 0 0-8 8v48h-16v16h16zm16-72h32v24h-32zm0 40h32v24h-32zM312.03 160.04h48a8 8 0 0 0 8-8v-80a8 8 0 0 0-8-8h-48a8 8 0 0 0-8 8v80a8 8 0 0 0 8 8zm8-80h32v24h-32zm0 40h32v24h-32zM128.03 160.04h40a8 8 0 0 0 8-8v-80a8 8 0 0 0-8-8h-48a8 8 0 0 0-8 8v72h-8v16zm0-80h32v24h-32zm0 40h32v24h-32zM216.03 304.04h48a8 8 0 0 0 8-8v-80a8 8 0 0 0-8-8h-48a8 8 0 0 0-8 8v80a8 8 0 0 0 8 8zm8-80h32v24h-32zm0 40h32v24h-32zM304.03 256.04v40a8 8 0 0 0 8 8h48a8 8 0 0 0 8-8v-80a8 8 0 0 0-8-8h-48a8 8 0 0 0-8 8v8h-16v16h16zm16-32h32v24h-32zm0 40h32v24h-32zM120.03 304.04h48a8 8 0 0 0 8-8v-80a8 8 0 0 0-8-8h-48a8 8 0 0 0-8 8v80a8 8 0 0 0 8 8zm8-80h32v24h-32zm0 40h32v24h-32zM208.03 440.04a8 8 0 0 0 8 8h48a8 8 0 0 0 8-8v-80a8 8 0 0 0-8-8h-48a8 8 0 0 0-8 8v56h-16v16h16zm16-72h32v24h-32zm0 40h32v24h-32zM120.03 448.04h48a8 8 0 0 0 8-8v-80a8 8 0 0 0-8-8h-48a8 8 0 0 0-8 8v80a8 8 0 0 0 8 8zm8-80h32v24h-32zm0 40h32v24h-32zm0 0" />
      <path d="M480.03 312.04c0-7.15-1.93-14.17-5.6-20.3a6.92 6.92 0 0 1-.09-7.26 39.9 39.9 0 0 0-58.3-52.38v-40.06h16v-16h-16v-128h16v-16h-16v-16h16v-16h-384v16h16v16h-16v16h16v128h-16v16h16v39.88a39.08 39.08 0 0 0-14.77-6.84A40.05 40.05 0 0 0 .03 264.04c0 7.14 1.93 14.15 5.6 20.28a7.1 7.1 0 0 1 0 7.43 39.43 39.43 0 0 0 0 40.58 6.9 6.9 0 0 1 .09 7.26 39.87 39.87 0 0 0 25.04 59.4c.42.1.8.12 1.25.21v8.84H8.03a8 8 0 0 0-7.7 10.19l13.1 45.8H.02v16h480v-16h-13.4l13.1-45.8a8 8 0 0 0-7.7-10.2h-24v-8.9a39.77 39.77 0 0 0 26.4-59.38 7.12 7.12 0 0 1 0-7.43 39.41 39.41 0 0 0 5.6-20.28zM50 464.04H30.06l-11.42-40h42.78zm14.03-56h-16v-8.92a39.7 39.7 0 0 0 16-7.2zm0-144.21a23.33 23.33 0 0 1-3.4 12.36 22.97 22.97 0 0 0 .06 23.77 23.41 23.41 0 0 1 0 24.14 23.21 23.21 0 0 0 0 23.88 23.48 23.48 0 0 1 3.34 12.06 23.4 23.4 0 0 1-16 22.48V264.04h-16v118.64a23.95 23.95 0 0 1-15.26-16.63 23.6 23.6 0 0 1 2.66-18.17 22.96 22.96 0 0 0-.06-23.77 23.41 23.41 0 0 1 0-24.14 23.21 23.21 0 0 0 0-23.88 23.48 23.48 0 0 1-3.34-12.05 23.92 23.92 0 0 1 9.04-18.77 23.62 23.62 0 0 1 14.9-5.23 23.86 23.86 0 0 1 24.05 23.66zm304 200.2h-64v-16h64zm-48-32v-64h32v64zm80-64h-16v16h16v80h-16v-24a8 8 0 0 0-8-8h-8v-72a8 8 0 0 0-8-8h-48a8 8 0 0 0-8 8v72h-8a8 8 0 0 0-8 8v24h-208v-128h320zm0-48h-112v-16h-16v16h-192v-128h320zm0-176h-16v16h16v16h-320v-64h16v-16h-16v-48h320zm0-112h-320v-16h320zm16 360.12a39.03 39.03 0 0 0 14.75 6.84c.42.1.8.12 1.25.21v8.84h-16zm45.4 31.89-11.43 40h-19.94l-11.42-40zm-.74-124.08a23.41 23.41 0 0 1 0 24.14 23.21 23.21 0 0 0 0 23.88 23.48 23.48 0 0 1 3.34 12.06 23.4 23.4 0 0 1-16 22.48V264.04h-16v118.64a23.95 23.95 0 0 1-16-22.33v-.08a23.25 23.25 0 0 1 3.4-12.39 22.96 22.96 0 0 0-.06-23.77 23.41 23.41 0 0 1 0-24.14 23.21 23.21 0 0 0 0-23.88 23.48 23.48 0 0 1-3.34-12.05 23.92 23.92 0 0 1 9.04-18.77 23.62 23.62 0 0 1 14.9-5.23 23.86 23.86 0 0 1 23.32 17.98c1.6 6.17.65 12.72-2.66 18.17a22.97 22.97 0 0 0 .06 23.77zm0 0" />
      <path d="M280.03 80.04h16v16h-16zM88.03 280.04h16v16h-16zm0 0" />
    </svg>
  );
}
