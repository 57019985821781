import React, { Fragment } from 'react';
import * as yup from 'yup';
import { useNavigate, useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import FormGroup from '../../layout/shared/form-group';
import { Switch, Textarea } from 'rizzui';
import Page from '../../layout/Page/Page';
import FormFooter from '../_layout/_footers/form-footer';
import CustomSelect from '../../components/forms/CustomSelect';
import RequiredInput from '../../components/forms/InputHOC';
import { State } from '../../type/entities/state-type';
import useProductTypes from '../../hooks/api-calls/useProductTypes';
import { PopoverPicker } from '../../components/forms/PopoverPicker';

interface CreateFormProps {
    isLoading: boolean;
    submit: Function;
    data?: State | undefined;
}

export const entities = [
    { value: 'contract', label: 'Contrato' },
    { value: 'contract_product', label: 'Producto del contrato' },
    { value: 'contract_invoicing', label: 'Facturación del contrato' },
    { value: 'contract_incidence', label: 'Incidencia del contrato' },
    { value: 'expiration', label: 'Vencimiento' },
    { value: 'invoice', label: 'Factura' },
    { value: 'incidence', label: 'Incidencia' },
    { value: 'renovation', label: 'Renovación' },
];

const schema = yup.object({
    name: yup.string().min(1, 'Demasiado corto').max(300, 'Demasiado largo').required('Campo obligatorio'),
    color: yup.string().required('Campo obligatorio').matches(/^#[0-9A-Fa-f]{6}$/, 'Color no válido'),
    entity: yup.string().required('Campo obligatorio'),
    productTypeId: yup.string().nullable(),
    description: yup.string().nullable(),
    initialStatus: yup.boolean(),
    satisfactoryStatus: yup.boolean(),
    unsatisfactoryStatus: yup.boolean(),
});

const StateForm: React.FC<CreateFormProps> = ({ isLoading, submit, data }) => {

    const { id = '', entity = '' } = useParams();
    const navigate = useNavigate();
    const { getProductTypesList } = useProductTypes();
    const mode = data ? 'Editar' : 'Crear';

    const formik = useFormik({
        initialValues: {
            statusId: data?.id ?? id,
            name: data?.name ?? '',
            description: data?.description ?? '',
            color: data?.color ?? '#607d8b',
            productTypeId: data?.productType?.id ?? '',
            entity: data?.entity ?? entity,
            initialStatus: data?.initialStatus ?? false,
            satisfactoryStatus: data?.satisfactoryStatus ?? false,
            unsatisfactoryStatus: data?.unsatisfactoryStatus ?? false,
        },
        validationSchema: schema,
        onSubmit: values => { submit(values) },
    });

    const getContent = () => {
        return (
            <div className="@container">
                <form onSubmit={formik.handleSubmit}>
                    <div className="mb-10 grid gap-7 divide-y-2 divide-dashed divide-gray-200 @2xl:gap-9 @3xl:gap-11 font-medium">
                        <FormGroup
                            title="Información General"
                            description="Datos principales del estado"
                            className='pt-6'
                        >
                            <RequiredInput
                                id='name'
                                type="text"
                                label="Nombre"
                                className="[&>label>span]:font-medium"
                                inputClassName="text-sm"
                                onChange={formik.handleChange}
                                value={formik.values.name}
                                error={formik.errors.name}
                            />

                            <div className='flex flex-row flex-wrap gap-3 align-bottom'>
                                <RequiredInput
                                    id='color'
                                    type="text"
                                    label="Color"
                                    className="[&>label>span]:font-medium"
                                    inputClassName="text-sm flex flex-grow"
                                    onChange={formik.handleChange}
                                    value={formik.values.color}
                                    error={formik.errors.color}
                                />
                                <PopoverPicker color={formik.values.color} onChange={(color: string) => formik.setFieldValue('color', color)} />
                            </div>

                            <CustomSelect
                                isSearchable
                                id={'entity'}
                                label="Entidad"
                                value={{ value: formik.values.entity, label: entities?.find((g: any) => g.value === formik.values.entity)?.label }}
                                options={entities}
                                onChange={(e: any) => { formik.setFieldValue('entity', e?.value) }}
                                required
                                error={formik.errors.entity}
                            />

                            <CustomSelect
                                isSearchable
                                isClearable
                                id={'productTypeId'}
                                label="Tipo de producto"
                                value={{ value: formik.values.productTypeId, label: getProductTypesList()?.find((g: any) => g.value === formik.values.productTypeId)?.label }}
                                options={getProductTypesList()}
                                onChange={(e: any) => { formik.setFieldValue('productTypeId', e?.value) }}
                                error={formik.errors.productTypeId}
                            />

                            <Textarea
                                id='description'
                                label="Descripción"
                                className="[&>label>span]:font-medium col-span-2"
                                rows={3}
                                onChange={formik.handleChange}
                                value={formik.values.description}
                                error={formik.errors.description}
                            />
                        </FormGroup>

                        <FormGroup
                            title="Categoría"
                            description="Puede ser un estado inicial, satisfactorio o insatisfactorio"
                            className='pt-6'
                        >
                            <Switch
                                id='initialStatus'
                                label="Estado inicial"
                                switchKnobClassName='bg-secondary'
                                className="[&>label>span]:font-medium col-span-2"
                                onChange={formik.handleChange}
                                checked={formik.values.initialStatus}
                            />

                            <Switch
                                id='satisfactoryStatus'
                                label="Estado satisfactorio"
                                checked={formik.values.satisfactoryStatus}
                                onChange={() => {
                                    formik.setFieldValue('satisfactoryStatus', !formik.values.satisfactoryStatus);
                                    formik.setFieldValue('unsatisfactoryStatus', false);
                                }}
                                switchKnobClassName='bg-secondary'
                                className="[&>label>span]:font-medium"
                            />

                            <Switch
                                id='unsatisfactoryStatus'
                                label="Estado insatisfactorio"
                                checked={formik.values.unsatisfactoryStatus}
                                onChange={() => {
                                    formik.setFieldValue('unsatisfactoryStatus', !formik.values.unsatisfactoryStatus);
                                    formik.setFieldValue('satisfactoryStatus', false);
                                }}
                                switchKnobClassName='bg-secondary'
                                className="[&>label>span]:font-medium"
                            />
                        </FormGroup>
                    </div>
                </form>
            </div>
        )
    };

    return (
        <Fragment>
            <Page container="fluid">
                {(mode === "Editar" && data) && getContent()}
                {mode === "Crear" && getContent()}
            </Page>
            <FormFooter
                submitBtnText={mode + ' Estado'}
                handleCancelBtn={() => navigate(-1)}
                handleSubmitBtn={formik.submitForm}
                isLoading={isLoading}
            />
        </Fragment>
    )
};

export default StateForm;