import { FaRegImage, FaFileVideo, FaFileZipper, FaDatabase, FaFileAudio } from "react-icons/fa6";
import { FaFileCsv } from "react-icons/fa";
import { IoDocument } from "react-icons/io5";
import { LuFileJson } from "react-icons/lu";
import { MdOutlineAttachFile } from "react-icons/md";

/**
 * Returns the icon name based on the file extension.
 *
 * @param {string} extension - The file extension (e.g., 'jpg', 'pdf').
 * @returns {JSX.Element} - The icon component.
 */
export function getIconByExtension(extension: string) {
    switch (extension.toLowerCase()) {
        case 'jpg':
        case 'jpeg':
        case 'png':
        case 'gif':
        case 'svg':
            return <FaRegImage />;
        case 'mp4':
        case 'avi':
        case 'mkv':
        case 'mov':
        case 'wmv':
        case 'flv':
        case 'webm':
            return <FaFileVideo />;
        case 'txt':
        case 'doc':
        case 'docx':
        case 'odt':
        case 'pdf':
        case 'rtf':
        case 'tex':
        case 'wpd':
        case 'xlsx':
            return <IoDocument />;
        case 'zip':
        case 'rar':
        case '7z':
        case 'tar':
        case 'gz':
        case 'pkg':
        case 'deb':
            return <FaFileZipper />;
        case 'mp3':
        case 'wav':
        case 'flac':
        case 'ogg':
        case 'wma':
            return <FaFileAudio />;
        case 'sql':
            return <FaDatabase />;
        case 'json':
            return <LuFileJson />;
        case 'csv':
            return <FaFileCsv />;
        default:
            return <MdOutlineAttachFile />;
    }
}