import { FC, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useFetch from "../../../hooks/useFetch";
import { Loader } from "../../../components/loader/SpinnerLogo";
import { toast } from "sonner";
import { UserService } from "../../../services/user/userService";
import { User } from "../../../type/entities/user-type";
import UserForm from "../UserForm";
import UserEditLayout from "./UserEditLayout";

const UserEdit: FC = () => {

    const { id = "" } = useParams<{ id: string }>();
    const navigate = useNavigate();
    const service = new UserService();

    const [data, loading] = useFetch(useCallback(async () => {
        const response = await service.getUserById(id as string);
        return response.getResponseData() as User;
    }, [id]));

    const handleSubmit = async (values: any) => {
        try {
            if (values.password !== "" && values.passwordConfirm !== "") {
                const result = await service.changePassword(id, values.password, values.passwordConfirm);

                if (result.getResponseData().success) {
                    toast.success("Contraseña cambiada correctamente");
                } else {
                    toast.error("Error al cambiar la contraseña");
                }
            }

            const response = await service.editUser(values);
            const responseData = response.getResponseData();

            if (responseData.success) {
                navigate(-1);
                toast.success("Usuario editado correctamente");
            } else {
                responseData.data.errors.forEach((error: any) => {
                    toast.error(error.message);
                });
            }
        } catch (error: any) {
            toast.error(error.message);
        }
    };

    if (loading) return <Loader />;

    return (
        <UserEditLayout>
            {(data !== null && data !== undefined) && <UserForm data={data} submit={handleSubmit} isLoading={loading} profileImg={data.profileImg} />}
        </UserEditLayout>
    );
};

export default UserEdit;