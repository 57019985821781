import React from 'react';
import { ReactSVG } from 'react-svg';
import { toAbsoluteUrl } from '../extras/AssetHelpers';

type Props = {
  className?: string
  path: string
  svgClassName?: string
}

export const KTSVG: React.FC<Props> = ({ className = '', path, svgClassName = 'mh-50px' }) => {
  const svgPath = toAbsoluteUrl(path);

  if (!svgPath) {
    return null;
  }

  return (
    <span className={`svg-icon ${className}`}>
      <ReactSVG src={toAbsoluteUrl(path)} className={svgClassName} />
    </span>
  )
};