import { Fragment, useState } from "react";
import { FiltersProvider } from "../../../components/providers/FiltersProvider";
import PageHeader from "../../../layout/shared/page-header";
import { menuRoutes } from "../../../router/menu";
import { Button } from "rizzui";
import { PiFunnel } from "react-icons/pi";
import DepartmentsList from "./DepartmentsList";
import { usePrivilege } from "../../../components/priviledge/PriviledgeProvider";

const breadCrumb = [
    { name: 'Administración' },
    { name: 'Departamentos', href: menuRoutes.departments.path },
];

const DepartmentsListWrapper = () => {

    const { userCan } = usePrivilege();
    const [isOpen, setIsOpen] = useState<boolean>(false);

    const [openFilters, setOpenFilters] = useState<boolean>(false);

    return (
        <Fragment>
            <FiltersProvider>
                <PageHeader title="Listado de departamentos" breadcrumb={breadCrumb}>
                    {/* <Button
                        variant="outline"
                        onClick={() => { setOpenFilters(true) }}
                    >
                        <PiFunnel className="me-1.5 h-[18px] w-[18px]" strokeWidth={1.7} />
                        Filtros
                    </Button> */}
                    {userCan('create_departments', 'departments') && (
                        <Button
                            onClick={() => { setIsOpen(true); }}
                            color="primary"
                        >
                            Crear departamento
                        </Button>
                    )}
                </PageHeader>
                <DepartmentsList isOpen={isOpen} setIsOpen={setIsOpen} openFilters={openFilters} setOpenFilters={setOpenFilters} />
            </FiltersProvider>
        </Fragment>
    );
}

export default DepartmentsListWrapper;