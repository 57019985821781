import { Button, cn, Text } from "rizzui"
import { useScrollableSlider } from "../../hooks/useScrollableSlider"
import { LAYOUT_OPTIONS, useLayout } from "../../hooks/useLayout"
import { useBerylliumSidebars } from "../../hooks/themes/berylliumUtils"
import { PiCaretLeftBold, PiCaretRightBold } from "react-icons/pi"
import { Link, useLocation } from "react-router-dom"
import { permissionsGroup, usePrivilege } from "../priviledge/PriviledgeProvider"
import { setSelectedView } from "../../redux/userProfileSlice"
import { useDispatch } from "react-redux"

export type MenuItem = {
    path: string
    label: string
    permission: {
        action: string
        group: permissionsGroup
    }
}

export type TabNavProps = {
    menuItems: MenuItem[]
    rootPath?: string
}


export default function TabNav({menuItems}: TabNavProps) {

    const location = useLocation();
    const { sliderEl, sliderPrevBtn, sliderNextBtn, scrollToTheRight, scrollToTheLeft } = useScrollableSlider();
    const { layout } = useLayout();
    const { expandedLeft } = useBerylliumSidebars();
    const dispatch = useDispatch();
    const { userCan } = usePrivilege();
    const basePath = location.pathname.split('/').slice(0, -1).join('/');

    return (
        <div
            className={cn(
                'sticky z-20 -mx-4 -mt-4 border-b border-muted bg-white px-4 py-0 font-medium text-gray-500 sm:-mt-2 md:-mx-5 md:px-5 lg:-mx-8 lg:mt-0 lg:px-8 xl:-mx-6 xl:px-6 2xl:top-20 3xl:-mx-[33px] 3xl:px-[33px] 4xl:-mx-10 4xl:px-10 dark:bg-gray-50',
                layout === LAYOUT_OPTIONS.LITHIUM
                    ? 'top-[66px] sm:top-[70px] md:top-[73px] '
                    : layout === LAYOUT_OPTIONS.BERYLLIUM
                        ? 'top-[62px] sm:top-[72px] 2xl:top-[72px]'
                        : 'top-[62px] md:top-[71px]',
                layout === LAYOUT_OPTIONS.BERYLLIUM && expandedLeft && 'xl:-ms-1 xl:px-0 3xl:-ms-2 3xl:ps-0 4xl:-ms-2'
            )}
        >
            <div className="relative flex items-center overflow-hidden">
                    <Button
                        title="Anterior"
                        variant="text"
                        ref={sliderPrevBtn}
                        onClick={() => scrollToTheLeft()}
                        className="!absolute left-0 top-0.5 z-10 !h-[calc(100%-4px)] w-8 !justify-start bg-gradient-to-r from-white via-white to-transparent px-0 text-gray-500 hover:text-black lg:hidden dark:from-gray-50 dark:via-gray-50"
                    >
                        <PiCaretLeftBold className="w-5" />
                    </Button>
                    <div className="flex h-[52px] items-start overflow-hidden">
                        <div
                            className="-mb-7 flex w-full gap-3 overflow-x-auto scroll-smooth pb-7 md:gap-5 lg:gap-8"
                            ref={sliderEl}
                        >
                            {menuItems.map((menu: MenuItem, index: number) => {
                                if (menu. permission && !userCan(menu.permission.action, menu.permission.group)) return;

                                return (
                                    <Link
                                        to={`${basePath}${menu.path}`}
                                        relative="route"
                                        key={`menu-${index}`}
                                        className={cn(
                                            'group relative cursor-pointer whitespace-nowrap py-2.5 font-medium text-gray-500 before:absolute before:bottom-0 before:left-0 before:z-[1] before:h-0.5  before:bg-gray-1000 before:transition-all hover:text-gray-900',
                                            menu.path.toLowerCase() === location.pathname.toString().toLowerCase()
                                                ? 'before:visible before:w-full before:opacity-100'
                                                : 'before:invisible before:w-0 before:opacity-0',
                                            location.pathname.toString().toLowerCase().includes(menu.path.toLocaleLowerCase()) && 'text-black',
                                        )}
                                        onClick={() => dispatch(setSelectedView(menu.path))}
                                    >
                                        <Text
                                            as="span"
                                            className="inline-flex rounded-md px-2.5 py-1.5 transition-all duration-200 group-hover:bg-gray-100/70"
                                        >
                                            {menu.label}
                                        </Text>
                                    </Link>
                                );
                            })}
                        </div>
                    </div>
                    <Button
                        title="Siguiente"
                        variant="text"
                        ref={sliderNextBtn}
                        onClick={() => scrollToTheRight()}
                        className="!absolute right-0 top-0.5 z-10 !h-[calc(100%-4px)] w-8 !justify-end bg-gradient-to-l from-white via-white to-transparent px-0 text-gray-500 hover:text-black lg:hidden dark:from-gray-50 dark:via-gray-50"
                    >
                        <PiCaretRightBold className="w-5" />
                    </Button>
                </div>
        </div>
    )
}