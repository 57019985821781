export default function ProjectDashIcon({ ...props }: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      fill="none"
      {...props}
    >
      <path
        fill="#4D5A68"
        d="M14.25 4.375v1.406l-.938.938H2.938L2 5.78V4.375c0-.778.628-1.438 1.406-1.438h9.438c.778 0 1.406.66 1.406 1.438z"
      />
      <path
        fill="#304653"
        d="M14.25 4.375v1.406l-.938.938H8.125V2.937h4.719c.778 0 1.406.66 1.406 1.438z"
      />
      <path
        fill="#FFCA42"
        d="M14.25 5.781v10.782c0 .778-.628 1.437-1.406 1.437H3.406C2.628 18 2 17.34 2 16.562V5.783h12.25z"
      />
      <path
        fill="#FFAF3E"
        d="M14.25 5.781v10.782c0 .778-.628 1.437-1.406 1.437H8.125V5.781h6.125z"
      />
      <path
        fill="#CAD4E4"
        d="M4.344 4.844a.468.468 0 01-.469-.469V2.469a.468.468 0 11.938 0v1.906c0 .26-.21.469-.47.469zm1.906 0a.468.468 0 01-.469-.469V2.469a.468.468 0 11.938 0v1.906c0 .26-.21.469-.469.469zm2.344-2.375v1.906a.464.464 0 01-.469.469.464.464 0 01-.469-.469V2.469c0-.263.206-.469.469-.469s.469.206.469.469z"
      />
      <path
        fill="#ADB5CC"
        d="M10.031 4.844a.468.468 0 01-.469-.469V2.469a.468.468 0 11.938 0v1.906c0 .26-.21.469-.469.469zm1.875 0a.469.469 0 01-.469-.469V2.469a.469.469 0 11.938 0v1.906c0 .26-.21.469-.469.469z"
      />
      <path
        fill="#DD864D"
        d="M8.594 14.688a.464.464 0 01-.469.468H4.344a.464.464 0 01-.469-.469c0-.262.206-.468.469-.468h3.781c.263 0 .469.206.469.469zm-1.406-2.344H4.344a.469.469 0 110-.938h2.843a.469.469 0 110 .938zM6.25 9.531H4.344a.468.468 0 110-.937H6.25a.468.468 0 110 .937z"
      />
      <path
        fill="#304653"
        d="M14.81 12.13c.159-2.644 2.002-4.154 2.11-4.306a.47.47 0 00-.103-.732l-.584-.337a.469.469 0 11.468-.812l.584.338c.792.457.945 1.537.308 2.195-.151.211-1.707 1.377-1.847 3.71a.469.469 0 11-.936-.056z"
      />
      <path
        fill="#4D5A68"
        d="M17.81 4.96l-.702 1.218-1.216.38-.942-.543-.278-1.244.703-1.217a1.404 1.404 0 011.921-.515c.674.389.904 1.247.515 1.92z"
      />
      <path
        fill="#304653"
        d="M15.892 6.559l-.471-.272 1.875-3.248c.674.39.904 1.247.515 1.921l-.703 1.218-1.216.38z"
      />
      <path
        fill="#E9336D"
        d="M12.704 13.898l-2.517-1.453a7.139 7.139 0 011.486-3.754c.145-.176 2.665-3.398 2.783-3.546l.216-.374 2.436 1.407-.211.365c-.014.024-1.625 4.052-1.684 4.192a7.138 7.138 0 01-2.509 3.163z"
      />
      <path
        fill="#B12E65"
        d="M12.705 13.898l-1.259-.727 4.444-7.696 1.218.703-.211.365c-.014.024-1.625 4.052-1.684 4.192a7.139 7.139 0 01-2.508 3.163z"
      />
      <path
        fill="#CAD4E4"
        d="M11.962 12.278a1.505 1.505 0 00-1.6.051l-.175.116c-.005.083-.026.158-.03.242l-.12 1.988-.413.714a.464.464 0 00.172.64c.227.131.51.056.64-.171l.413-.715 1.662-1.097c.07-.046.123-.102.193-.148l.013-.21a1.505 1.505 0 00-.755-1.41z"
      />
      <path
        fill="#ADB5CC"
        d="M11.961 12.278l-1.228 2.127-.937 1.624a.464.464 0 00.64-.171l.413-.715 1.662-1.097c.07-.046.123-.102.193-.148l.013-.21a1.504 1.504 0 00-.756-1.41zM8.125 4.844V2c.263 0 .469.206.469.469v1.906a.464.464 0 01-.469.469z"
      />
      <path
        fill="#D36836"
        d="M8.594 14.688a.464.464 0 01-.469.468v-.937c.263 0 .469.206.469.469z"
      />
    </svg>
  );
}
