import React, { useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { toast } from 'sonner';
import useFetch from '../../../hooks/useFetch';
import { StateService } from '../../../services/state/stateService';
import { menuRoutes } from '../../../router/menu';
import AttivaCard from '../../../components/card/AttivaCard';
import PageHeader from '../../../layout/shared/page-header';
import { State } from '../../../type/entities/state-type';
import StateForm from '../StateForm';
import { Loader } from '../../../components/loader/SpinnerLogo';

const pageHeader = {
    title: "Editar Estado",
    breadcrumb: [
        {
            href: menuRoutes.states.path,
            name: "Estados",
        },
        {
            name: "Editar",
        },
    ],
};

const StateEdit = () => {

    const { id = "" } = useParams<{ id: string }>();
    const navigate = useNavigate();
    const service = new StateService();

    const [isLoading, setIsLoading] = React.useState(false);

    const [data, loading, error] = useFetch(useCallback(async () => {
        const response = await service.getStateById(id as string);
        return response.getResponseData() as State;
    }, [id]));

    const handleSubmit = async (values: any) => {
        setIsLoading(true);
        try {
            const response = await service.editState(values);
            const responseData = response.getResponseData();

            if (responseData.success) {
                navigate(-1);
                toast.success("Estado editado correctamente");
            } else {
                responseData.data.errors.forEach((error: any) => {
                    toast.error(error.message);
                });
            }
        } catch (error: any) {
            toast.error(error.message);
        } finally {
            setIsLoading(false);
        }
    };

    if (loading) return <Loader />;

    return (
        <>
            <PageHeader title={pageHeader.title} breadcrumb={pageHeader.breadcrumb} />

            <AttivaCard title="Datos Principales" className="mb-4">
                {(data !== null && data !== undefined) && <StateForm data={data} submit={handleSubmit} isLoading={isLoading} />}
            </AttivaCard>
        </>
    );
};

export default StateEdit;