/* eslint-disable react/no-unknown-property */
export default function RealEstateIcon({
  ...props
}: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 512 512"
      {...props}
    >
      <path
        fill="#FFB125"
        d="M170.479,159.141l-50.583,0.071l-0.139-85.24c-0.003-2.385,1.927-4.321,4.312-4.324l41.947-0.059
	c2.385-0.003,4.321,1.927,4.324,4.312L170.479,159.141z"
      />
      <path
        fill="#FF901D"
        d="M170.341,73.901c-0.003-2.385-1.939-4.315-4.324-4.312l-25.01,0.035
	c2.218,0.178,3.969,2.013,3.973,4.277l0.139,85.24l-25.222,0.035v0.035l50.583-0.071L170.341,73.901z"
      />
      <path
        fill="#E1EFFB"
        d="M254.124,68.044L118.628,195.686l0.271,194.046c0.007,5.119,4.163,9.263,9.281,9.256l252.811-0.353
	c5.119-0.007,9.263-4.163,9.256-9.281l-0.271-194.046L254.124,68.044z"
      />
      <path
        fill="#CCDFED"
        d="M389.976,195.307L254.125,68.044l-22.343,21.048l113.384,106.215l0.271,194.046
	c0.007,5.119-4.137,9.274-9.255,9.281h44.811c5.119-0.007,9.263-4.163,9.256-9.281L389.976,195.307z"
      />
      <path
        fill="#FF4C4C"
        d="M437.773,238.065c-4.574,0.006-9.159-1.661-12.761-5.035L254.127,72.95L83.69,233.507
	c-7.491,7.057-19.284,6.704-26.339-0.786c-7.057-7.49-6.705-19.283,0.786-26.339L241.315,33.823
	c7.161-6.747,18.335-6.762,25.515-0.036l183.659,172.047c7.51,7.035,7.895,18.827,0.859,26.336
	C447.687,236.079,442.736,238.058,437.773,238.065z"
      />
      <path
        fill="#F33939"
        d="M451.348,209.76c-3.661,3.908-8.612,5.887-13.575,5.894c-4.574,0.006-9.159-1.661-12.761-5.035
	L254.127,50.538L83.69,211.095c-7.491,7.057-19.284,6.704-26.339-0.786c-0.472-0.501-0.905-1.023-1.31-1.56
	c-5.369,7.117-5.009,17.264,1.31,23.971c7.054,7.49,18.848,7.843,26.339,0.786L254.127,72.95l170.885,160.08
	c3.602,3.374,8.187,5.041,12.761,5.035c4.964-0.007,9.914-1.986,13.575-5.894c6.288-6.711,6.631-16.833,1.277-23.933
	C452.229,208.762,451.806,209.271,451.348,209.76z"
      />
      <path
        fill="#2D5871"
        d="M289.175,228.014l-69.655,0.097c-3.268,0.005-5.914,2.658-5.909,5.926l0.23,164.83l81.49-0.114
	l-0.23-164.83C295.097,230.655,292.443,228.01,289.175,228.014z"
      />
      <path
        fill="#1D4859"
        d="M295.101,233.924c-0.005-3.268-2.658-5.914-5.926-5.909l-39.354,0.055
	c2.401,0.751,4.145,2.991,4.149,5.64l0.23,165.102l41.131-0.058L295.101,233.924z"
      />
      <path
        fill="#FED9A8"
        d="M466.909,315.553l-102.666,68.709l-30.597-1.717l-1.198-12.043
	c-1.783-17.927-15.586-32.315-33.424-34.839l-81.979-9.439c-18.911-2.676-37.119-9.013-53.605-18.657l0,0
	c-22.508-13.167-48.114-20.106-74.19-20.106H30.145v134.916l156.213,59.155c5.904,1.667,12.104,2.166,18.163,1.208
	c0.036-0.006,0.073-0.011,0.109-0.017l157.27-22.67c12.116-1.934,23.505-7.037,33.013-14.792l101.646-91.419
	c9.122-8.1,10.813-21.713,3.95-31.8l0,0C493.001,311.008,477.989,308.108,466.909,315.553z"
      />
      <path
        fill="#F4C892"
        d="M501.851,324.285l-100.469,84.571c-9.508,7.755-25.314,12.857-37.43,14.792l-159.321,11.389
	c-0.036,0.006-0.073,0.012-0.109,0.017c-6.059,0.957-12.26,0.458-18.163-1.208L30.145,374.691v47.688l156.213,59.155
	c2.952,0.833,5.978,1.375,9.023,1.588c0.761,0.053,1.524,0.086,2.287,0.098c2.289,0.035,4.581-0.119,6.853-0.478
	c0.036-0.006,0.073-0.012,0.109-0.017l157.27-22.67c3.786-0.605,7.502-1.519,11.111-2.726c0.722-0.242,1.439-0.495,2.152-0.76
	c3.565-1.324,7.017-2.939,10.321-4.829c0.661-0.378,1.315-0.767,1.964-1.166c2.594-1.599,5.089-3.372,7.466-5.311l101.646-91.419
	C505.023,346.328,507.077,334.072,501.851,324.285z"
      />
      <path
        fill="#2D5871"
        d="M50.066,468.205H26.68c-10.699,0-19.372-8.673-19.372-19.372V275.772
	c0-10.699,8.673-19.372,19.372-19.372h23.386c10.699,0,19.372,8.673,19.372,19.372v173.061
	C69.438,459.532,60.765,468.205,50.066,468.205z"
      />
      <path
        fill="#1D4859"
        d="M50.066,256.4H26.68c-1.315,0-2.598,0.134-3.839,0.384c8.859,1.782,15.532,9.604,15.532,18.988
	v173.061c0,9.384-6.673,17.206-15.532,18.988c1.241,0.25,2.525,0.384,3.839,0.384h23.386c10.699,0,19.372-8.673,19.372-19.372
	V275.772C69.438,265.073,60.765,256.4,50.066,256.4z"
      />
      <path
        d="M506.552,317.933c-9.739-14.312-29.351-18.1-43.707-8.452l-65.34,43.728l-0.19-136.11l22.7,21.265
	c4.827,4.521,11.121,7.009,17.729,7.009c0.012,0,0.026,0,0.039,0c7.134-0.01,14.022-3,18.898-8.205
	c4.737-5.057,7.222-11.655,6.996-18.581c-0.226-6.926-3.136-13.349-8.193-18.086l-43.035-40.314
	c-2.946-2.76-7.571-2.609-10.33,0.337c-2.759,2.946-2.608,7.57,0.337,10.33l43.035,40.314c2.208,2.068,3.478,4.872,3.577,7.895
	c0.098,3.023-0.986,5.904-3.055,8.111c-2.16,2.306-5.09,3.578-8.252,3.582c-0.006,0-0.011,0-0.017,0c-2.883,0-5.63-1.086-7.737-3.06
	l-35.026-32.811c-0.001-0.001-0.002-0.002-0.002-0.002L259.124,67.616c-2.816-2.637-7.198-2.633-10.007,0.014L113.624,195.268
	c-0.001,0.001-0.001,0.002-0.002,0.002l-34.943,32.917c-2.201,2.074-5.079,3.16-8.103,3.076c-3.024-0.09-5.831-1.353-7.905-3.556
	c-2.074-2.201-3.167-5.079-3.076-8.102c0.09-3.024,1.353-5.831,3.554-7.905L246.326,39.143c4.342-4.091,11.154-4.101,15.507-0.021
	L369,139.514c2.945,2.759,7.57,2.609,10.33-0.337c2.759-2.946,2.608-7.57-0.337-10.33L271.827,28.455
	c-9.973-9.344-25.576-9.323-35.523,0.049l-58.639,55.24l-0.016-9.853c-0.004-3.106-1.218-6.024-3.417-8.216
	c-2.195-2.189-5.109-3.394-8.209-3.394c-0.006,0-0.011,0-0.017,0l-41.947,0.059c-3.106,0.004-6.023,1.218-8.216,3.417
	c-2.192,2.199-3.398,5.12-3.393,8.227l0.116,71.087l-59.439,55.993c-5.044,4.751-7.935,11.182-8.142,18.108
	s2.296,13.518,7.047,18.56c4.75,5.044,11.181,7.936,18.108,8.143c0.265,0.008,0.53,0.012,0.795,0.012
	c6.635,0,12.918-2.49,17.767-7.06l22.649-21.336l0.09,64.291c-7.328-1.067-14.742-1.627-22.19-1.627H76.746v-4.382
	c0-14.711-11.969-26.68-26.68-26.68H26.68C11.969,249.092,0,261.06,0,275.772V390.29c0,4.036,3.272,7.308,7.308,7.308
	s7.308-3.272,7.308-7.308V275.772c0-6.652,5.412-12.064,12.064-12.064h23.385c6.652,0,12.064,5.412,12.064,12.064v11.69v149.796
	v11.575c0,6.652-5.412,12.064-12.064,12.064H26.68c-6.652,0-12.064-5.412-12.064-12.064v-12.788c0-4.036-3.272-7.308-7.308-7.308
	S0,432.009,0,436.045v12.788c0,14.711,11.969,26.68,26.68,26.68h23.385c14.711,0,26.68-11.968,26.68-26.68v-0.993l107.024,40.528
	c0.198,0.075,0.399,0.141,0.603,0.198c4.653,1.314,9.304,1.973,13.896,1.973c2.484,0,4.951-0.193,7.393-0.579l157.281-22.672
	c0.037-0.005,0.073-0.011,0.11-0.017c13.372-2.135,25.987-7.788,36.48-16.346c0.091-0.074,0.181-0.151,0.268-0.23l101.611-91.388
	C513.244,348.802,515.453,331.015,506.552,317.933z M163.037,76.902l0.034,20.59l-35.912,33.83l-0.089-54.371L163.037,76.902z
	 M126.061,284.617l-0.113-80.878L254.141,82.976l128.538,120.412l0.223,159.593l-20.691,13.848L340.3,375.6l-0.579-5.82
	c-2.039-20.498-17.171-36.987-37.18-40.922l-0.132-94.945c-0.01-7.286-5.942-13.207-13.227-13.207c-0.006,0-0.011,0-0.018,0
	l-69.654,0.098c-3.533,0.005-6.853,1.385-9.347,3.887c-2.495,2.501-3.866,5.825-3.861,9.357l0.116,82.781
	c-13.797-3.154-26.971-8.363-39.288-15.568c-1.537-0.899-3.089-1.767-4.654-2.611c-0.61-0.329-1.23-0.638-1.845-0.959
	c-0.952-0.497-1.902-0.997-2.864-1.474c-0.766-0.38-1.541-0.74-2.313-1.107c-0.819-0.389-1.637-0.782-2.462-1.157
	c-0.84-0.381-1.687-0.744-2.533-1.11c-0.773-0.334-1.546-0.67-2.324-0.991c-0.87-0.359-1.745-0.704-2.621-1.047
	c-0.777-0.304-1.555-0.606-2.336-0.898c-0.867-0.324-1.738-0.637-2.611-0.944c-0.819-0.289-1.639-0.571-2.463-0.846
	c-0.836-0.279-1.674-0.552-2.516-0.817c-0.897-0.283-1.798-0.553-2.7-0.82c-0.775-0.228-1.549-0.456-2.328-0.672
	c-1.017-0.283-2.039-0.548-3.062-0.81C127.019,284.875,126.543,284.736,126.061,284.617z M220.921,235.418l66.874-0.094
	l0.128,91.705l-61.125-7.037l-5.759-0.663L220.921,235.418z M491.673,348.41l-101.511,91.298
	c-8.459,6.859-18.606,11.396-29.361,13.122l-157.213,22.662c-0.033,0.005-0.065,0.01-0.098,0.015l-0.109,0.017
	c-4.763,0.754-9.723,0.436-14.744-0.942L76.746,432.21V294.77h12.505c10.645,0,21.218,1.229,31.514,3.616
	c0.383,0.089,0.765,0.18,1.147,0.272c1.265,0.305,2.527,0.619,3.783,0.959c0.11,0.03,0.219,0.062,0.329,0.092
	c1.259,0.344,2.513,0.707,3.763,1.086c0.224,0.068,0.449,0.135,0.672,0.204c1.33,0.411,2.654,0.84,3.971,1.29
	c0.136,0.047,0.271,0.095,0.407,0.142c2.743,0.947,5.459,1.98,8.141,3.098c0.146,0.061,0.292,0.122,0.438,0.184
	c1.337,0.562,2.666,1.143,3.986,1.747c0.035,0.016,0.071,0.033,0.106,0.05c1.276,0.586,2.543,1.194,3.802,1.819
	c0.148,0.073,0.297,0.145,0.445,0.219c1.23,0.615,2.45,1.252,3.663,1.904c0.229,0.123,0.457,0.249,0.686,0.373
	c1.224,0.667,2.441,1.347,3.646,2.052c17.391,10.173,36.323,16.762,56.271,19.585c0.063,0.009,0.125,0.017,0.188,0.024l81.881,9.427
	c0.453,0.065,0.903,0.14,1.35,0.224c12.054,2.264,21.568,11.171,24.751,22.77c0.472,1.718,0.804,3.496,0.986,5.319l0.325,3.27
	l-153.46-13.445c-4.018-0.351-7.566,2.622-7.918,6.642c-0.353,4.021,2.622,7.566,6.642,7.918l162.242,14.215
	c0.039,0.003,0.077,0.002,0.115,0.005s0.075,0.009,0.114,0.012l30.597,1.717c0.137,0.008,0.273,0.011,0.409,0.011
	c0.145,0,0.288-0.023,0.432-0.032c0.163-0.01,0.326-0.016,0.488-0.036c0.218-0.028,0.431-0.075,0.645-0.122
	c0.153-0.034,0.307-0.059,0.457-0.103c0.234-0.067,0.459-0.158,0.686-0.249c0.122-0.049,0.248-0.086,0.368-0.142
	c0.341-0.158,0.673-0.34,0.989-0.552l25.972-17.381c0.001-0.001,0.002-0.002,0.003-0.003l76.702-51.332
	c7.717-5.187,18.251-3.152,23.482,4.536C499.249,333.181,498.062,342.736,491.673,348.41z"
      />
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
    </svg>
  );
}
