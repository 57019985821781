export type CrmStatType = {
    title: string;
    customer: number;
    increased: boolean;
    percentage: number;
    lastMonth: number;
};

export const crmStatData: CrmStatType[] = [
    {
        title: 'Total Alumnos',
        customer: 127865,
        increased: true,
        percentage: 1.2,
        lastMonth: 126990,
    },
    {
        title: 'Nuevos Alumnos',
        customer: 875,
        increased: false,
        percentage: 3.5,
        lastMonth: 1057,
    },
    {
        title: 'Cursos Activos',
        customer: 4250,
        increased: true,
        percentage: 7.9,
        lastMonth: 3987,
    },
    {
        title: 'Tutores',
        customer: 2777,
        increased: false,
        percentage: 2.3,
        lastMonth: 2846,
    },
];

export const revenueSummaryData2 = [
    {
        day: 'Ene',
        revenue: 500,
    },
    {
        day: 'Feb',
        revenue: 850,
    },
    {
        day: 'Mar',
        revenue: 700,
    },
    {
        day: 'Abr',
        revenue: 390,
    },
    {
        day: 'May',
        revenue: 489,
    },
    {
        day: 'Jun',
        revenue: 800,
    },
    {
        day: 'Jul',
        revenue: 850,
    },
    {
        day: 'Ago',
        revenue: 700,
    },
    {
        day: 'Sep',
        revenue: 398,
    },
    {
        day: 'Oct',
        revenue: 489,
    },
    {
        day: 'Nov',
        revenue: 800,
    },
    {
        day: 'Dic',
        revenue: 850,
    },
];