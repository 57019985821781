export default function PieChartCurrencyIcon({
  ...props
}: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      fill="none"
      {...props}
    >
      <path
        fill="#B4B5EB"
        d="M10.498 1.86a7.814 7.814 0 0 1 6.939 7.636c.07 4.398-3.543 8.011-7.94 7.94a7.814 7.814 0 0 1-7.636-6.939.783.783 0 0 1 .776-.873h6.207c.432 0 .781-.35.781-.78V2.636c0-.468.408-.828.873-.777Z"
      />
      <path
        fill="#575FFF"
        d="M5.766 10.492a.781.781 0 0 1 .777-.867H2.637a.783.783 0 0 0-.776.873 7.814 7.814 0 0 0 9.71 6.691 7.82 7.82 0 0 1-5.805-6.697Z"
      />
      <path
        fill="#BAE7ED"
        d="M8.063 1.075v6.207c0 .431-.35.781-.781.781H1.075a.782.782 0 0 1-.777-.867A7.816 7.816 0 0 1 7.196.298a.782.782 0 0 1 .867.777Z"
      />
      <path
        fill="#76E0EF"
        d="M4.204 7.196a7.814 7.814 0 0 1 3.858-5.9v-.221a.782.782 0 0 0-.867-.777A7.816 7.816 0 0 0 .297 7.196a.781.781 0 0 0 .777.867h3.907a.782.782 0 0 1-.777-.867Z"
      />
      <path
        fill="#FFE380"
        d="M15.02 19.707a4.687 4.687 0 1 0 0-9.375 4.687 4.687 0 0 0 0 9.375Z"
      />
      <path
        fill="#F7DD5A"
        d="M14.238 18.926c0-.432.35-.781.782-.781v-6.25a.781.781 0 1 1 0-1.563 4.693 4.693 0 0 0-4.688 4.688 4.693 4.693 0 0 0 4.688 4.687.781.781 0 0 1-.782-.781Z"
      />
      <path
        fill="#F5C85D"
        d="M15.02 18.145a3.125 3.125 0 1 0 0-6.25 3.125 3.125 0 0 0 0 6.25Z"
      />
      <path
        fill="currentColor"
        d="M17.646 10.79c.062-.429.09-.865.083-1.299-.065-4.068-3.16-7.473-7.2-7.922a1.073 1.073 0 0 0-1.198 1.067v6.207c.001.27-.218.489-.487.489H2.637a1.073 1.073 0 0 0-1.067 1.197c.448 4.039 3.854 7.134 7.922 7.2l.13.001c.392 0 .783-.028 1.168-.084a4.98 4.98 0 0 0 4.23 2.353A4.986 4.986 0 0 0 20 15.02c0-1.783-.942-3.35-2.354-4.23Zm-8.145 6.353c-3.774-.061-6.933-2.932-7.35-6.679a.488.488 0 0 1 .485-.546h6.207c.593 0 1.075-.482 1.075-1.075V2.636a.49.49 0 0 1 .547-.485c3.746.417 6.617 3.576 6.678 7.35.005.331-.011.663-.05.99a4.945 4.945 0 0 0-.948-.324 6.554 6.554 0 0 0-4.884-6.88.293.293 0 1 0-.147.568 5.968 5.968 0 0 1 4.45 6.214 4.986 4.986 0 0 0-5.496 5.495 5.968 5.968 0 0 1-6.214-4.45.293.293 0 0 0-.566.145 6.555 6.555 0 0 0 6.229 4.907h.106c.181 0 .363-.007.543-.022.077.33.187.647.325.949-.327.039-.659.055-.99.05Zm5.518 2.27a4.4 4.4 0 0 1-4.394-4.394 4.4 4.4 0 0 1 4.394-4.395 4.4 4.4 0 0 1 4.395 4.395 4.4 4.4 0 0 1-4.395 4.394Z"
      />
      <path
        fill="currentColor"
        d="M15.02 11.602a3.422 3.422 0 0 0-3.418 3.418 3.422 3.422 0 0 0 3.418 3.418 3.422 3.422 0 0 0 3.418-3.418 3.422 3.422 0 0 0-3.418-3.418Zm0 6.25a2.835 2.835 0 0 1-2.832-2.832 2.835 2.835 0 0 1 2.832-2.832 2.835 2.835 0 0 1 2.832 2.832 2.835 2.835 0 0 1-2.832 2.832Z"
      />
      <path
        fill="currentColor"
        d="M15.215 14.727h-.39a.293.293 0 0 1 0-.586h.78a.293.293 0 0 0 0-.586h-.293v-.293a.293.293 0 0 0-.585 0v.298a.88.88 0 0 0 .098 1.752h.39a.293.293 0 0 1 0 .586h-.781a.293.293 0 0 0 0 .586h.293v.293a.293.293 0 0 0 .585 0v-.298a.88.88 0 0 0-.098-1.752ZM8.356 7.281V1.074A1.076 1.076 0 0 0 7.164.006 8.12 8.12 0 0 0 2.33 2.331 8.12 8.12 0 0 0 .007 7.163a1.075 1.075 0 0 0 1.067 1.193h6.208c.592 0 1.074-.482 1.074-1.075ZM.71 7.607a.481.481 0 0 1-.121-.38 7.532 7.532 0 0 1 2.157-4.482A7.532 7.532 0 0 1 7.228.59a.482.482 0 0 1 .38.121.483.483 0 0 1 .162.364v6.207c0 .27-.219.489-.488.489H1.074a.483.483 0 0 1-.364-.163Z"
      />
    </svg>
  );
}
