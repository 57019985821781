import React, { useCallback, useEffect, useState } from 'react';
import Page from '../../../layout/Page/Page';
import { useFiltersPR } from '../../../components/providers/FiltersProvider';
import useFetch from '../../../hooks/useFetch';
import { FilterDrawerView } from '../../../components/table/components/TableFilter';
import Tree from 'react-d3-tree';
import { useWindowDimensions } from '../../../hooks/useWindowDimensions';
import { Loader } from '../../../components/loader/SpinnerLogo';
import classNames from 'classnames';
import { usePrivilege } from '../../../components/priviledge/PriviledgeProvider';
import moment from 'moment';
import 'moment/locale/es';
import { NetworkService } from '../../../services/networks/networkService';
import NetworksFilters from './NetworksFilters';
import NetworkForm from '../NetworkForm';
import { NetworksApiResponse } from '../../../type/entities/network-type';
import { AuthState } from '../../../redux/authSlice';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import { DepartmentService } from '../../../services/departments/departmentService';
import DepartmentForm from '../../departments/DepartmentForm';
import { InfoModal } from '../../departments/list/components/InfoModal';
import { CopyConfigModal } from '../../departments/list/components/CopyConfigModal';

interface TreeNode {
    name: string;
    id: string;
    attributes?: any;
    children?: TreeNode[];
}

interface NetworksListProps {
    isDepartmentOpen: boolean;
    setIsDepartmentOpen: (value: boolean) => void;
    isNetworkOpen: boolean;
    setIsNetworkOpen: (value: boolean) => void;
    nodeId: string | undefined;
    setNodeId: (value: string | undefined) => void;
    openFilters: boolean;
    setOpenFilters: any;
}

const NetworksList: React.FC<NetworksListProps> = ({ isDepartmentOpen, setIsDepartmentOpen, isNetworkOpen, setIsNetworkOpen, nodeId, setNodeId, openFilters, setOpenFilters }) => {

    const { user }: AuthState = useSelector((state: RootState) => state.auth);
    const { userCan } = usePrivilege();
    const { filters, updateFilters, resetFilters } = useFiltersPR();
    const { width, height } = useWindowDimensions();

    const [networks, setNetworks] = useState<TreeNode | undefined>(undefined);
    const [tooltip, setTooltip] = useState<{ visible: boolean; x: number; y: number; nodeData: any | null; }>({ visible: false, x: 0, y: 0, nodeData: null });
    const [info, setInfo] = useState<boolean>(false);
    const [copyConfig, setCopyConfig] = useState<boolean>(false);

    const [networksData, loadingNetworks, networksError, refetchNetworks] = useFetch(useCallback(async () => {
        const response = await (new NetworkService()).listNetworks(filters);
        return response.getResponseData() as NetworksApiResponse;
    }, [filters]));

    const [departmentsData, loadingDepartments, departmentsError, refetchDepartments] = useFetch(useCallback(async () => {
        const response = await (new DepartmentService()).listDepartments(filters);
        return response.getResponseData();
    }, [filters]));

    /**
     * Build the tree structure recursively and include departments
     */
    function buildTree(data: any, departments: any[], parentId = null, level = '1') {
        // Filter networks that have the current 'parentId' as parent
        const children = data.filter((item: any) => {
            return parentId ? item.parentCommercialNetwork?.id === parentId : !item.parentCommercialNetwork;
        });

        // For each child found, we build its node
        return children.map((child: any, index: number) => {
            // Find departments that belong to this commercial network
            const childDepartments = departments?.filter((dept: any) => dept.commercialNetwork?.id === child.id);

            // Create department nodes
            const departmentNodes = childDepartments.map((dept: any) => ({
                name: dept.name,
                id: dept.id,
                type: 'department',
                createdAt: dept.createdAt.date,
                updatedAt: dept.updatedAt?.date,
                viewStates: dept.departmentsHasStatuses.filter((s: any) => s.action === 'view'),
                viewStatesCount: dept.departmentsHasStatuses.filter((s: any) => s.action === 'view').length,
                editStates: dept.departmentsHasStatuses.filter((s: any) => s.action === 'edit'),
                editStatesCount: dept.departmentsHasStatuses.filter((s: any) => s.action === 'edit').length,
                users: dept.user,
                usersCount: dept.user.length,
                products: dept.departmentsHasProduct,
                productsCount: dept.departmentsHasProduct.length,
                attributes: {
                    Red: dept.commercialNetwork?.name,
                },
                children: [], // Departments have no children
            }));

            return {
                name: child.name,
                id: child.id,
                type: 'network',
                active: child.active,
                createdAt: child.createdAt.date,
                updatedAt: child.updatedAt?.date,
                attributes: {
                    Código: child.code,
                    IP: child.ipAddress || 'N/A',
                    'Nº Departamentos': childDepartments.length, // Add number of departments to attributes
                    'Nº Usuarios': child.user?.length || '0',
                },
                // Recursively build children and append department nodes
                children: [...buildTree(data, departments, child.id, `${level}.${index + 1}`), ...departmentNodes],
            };
        });
    };

    /**
     * Generate the networks tree structure, including departments
     */
    function generateNetworkTree(data: any, departments: any[]) {
        return {
            name: user?.companyName || 'Attiva',
            id: '1',
            children: buildTree(data, departments),
        };
    };

    const handleNodeClick = (nodeData: any) => {
        if (nodeData?.id === '1') return;

        if (nodeData?.type === 'network' && userCan('edit_commercial_network', 'commercial_network')) {
            setNodeId(nodeData?.id);
            setIsDepartmentOpen(false);
            setIsNetworkOpen(true);
        }

        if (nodeData?.type === 'department' && userCan('edit_departments', 'departments')) {
            setNodeId(nodeData?.id);
            setIsNetworkOpen(false);
            setIsDepartmentOpen(true);
        }
    };

    const handleNodeMouseOver = (nodeData: any, evt: any) => {
        if (nodeData?.data?.id === '1') return;

        // Get node position relative to the SVG container
        const { x, y } = evt.target.getBoundingClientRect();

        // Toggle tooltip visibility and update position
        setTooltip({
            visible: true,
            x: x + window.scrollX + 35,
            y: y + window.scrollY,
            nodeData: nodeData as any,
        });
    };

    const getTooltipInfo = (nodeData: any) => {
        if (!nodeData) return null;
        switch (nodeData.type) {
            case 'network':
                return (
                    <>
                        <p className='text-sm font-medium text-gray-500'>IP: {nodeData.attributes.IP ? nodeData.attributes.IP : 'N/A'}</p>
                        <p className='text-sm font-medium text-gray-500'>Creación: {moment(nodeData.createdAt).format('DD/MM/YY')}</p>
                        {nodeData.updatedAt && <p className='text-sm font-medium text-gray-500'>Actualización: {moment(nodeData.updatedAt).format('DD/MM/YY')}</p>}
                    </>
                )
            case 'department':
                return (
                    <>
                        <p className='text-sm font-medium text-gray-500'>Usuarios: {nodeData.usersCount}</p>
                        <p className='text-sm font-medium text-gray-500'>Productos: {nodeData.productsCount}</p>
                        <p className='text-sm font-medium text-gray-500'>Estados visibles: {nodeData.viewStatesCount}</p>
                        <p className='text-sm font-medium text-gray-500'>Estados modificables: {nodeData.editStatesCount}</p>
                        <p className='text-sm font-medium text-gray-500'>Creado: {moment(nodeData.createdAt).format('DD/MM/YY')}</p>
                        {nodeData.updatedAt && <p className='text-sm font-medium text-gray-500'>Actualizado: {moment(nodeData.updatedAt).format('DD/MM/YY')}</p>}
                    </>
                )
            default:
                return null;
        }
    };

    /**
     * Update tree when data is fetched or filters change
     */
    useEffect(() => {
        if (networksData?.data.length > 0 && departmentsData?.data) {
            setNetworks(generateNetworkTree(networksData.data, departmentsData.data));
        } else {
            setNetworks(undefined);
        }
    }, [networksData, departmentsData, filters]);

    if (loadingNetworks || loadingDepartments) return <Loader height={'70'} />;

    return (
        <Page container='fluid'>
            <div className='grid grid-cols-3 gap-3 static networkWrapper'>
                <div id="treeWrapper"
                    className={classNames('col-span-3 relative', {
                        'md:col-span-2': isDepartmentOpen || isNetworkOpen,
                        'hidden': !userCan('list_commercial_network', 'commercial_network')
                    })}
                >
                    {(networksData !== undefined && networks)
                        ? (
                            <Tree
                                data={networks}
                                orientation="vertical"
                                pathFunc={'step'}
                                translate={{ x: width / 3, y: height / 10 }}
                                //dimensions={{ width: width, height: height - 200 }}
                                separation={{ siblings: 2, nonSiblings: 2 }}
                                nodeSize={{ x: 150, y: 150 }}
                                renderCustomNodeElement={(nodeData: any) => {
                                    const { nodeDatum } = nodeData;
                                    const isNetwork = nodeDatum.type === 'network';
                                    const isDepartment = nodeDatum.type === 'department';

                                    return (
                                        <g>
                                            {nodeData?.nodeDatum.id !== '1'
                                                ? (
                                                    <>
                                                        <circle
                                                            r={15}
                                                            style={{ fill: isNetwork ? '#f8ffa2' : '#8da4b9' }}
                                                            onClick={() => handleNodeClick(nodeDatum)}
                                                            onMouseOver={(evt: any) => handleNodeMouseOver(nodeData, evt)} onMouseOut={() => setTooltip({ ...tooltip, visible: false })}
                                                        />
                                                        <g className="rd3t-label">
                                                            <text className='rd3t-label__title' textAnchor="start" x={40}>
                                                                {nodeDatum.name}
                                                            </text>
                                                            <text className="rd3t-label__attributes">
                                                                {isNetwork && (
                                                                    <>
                                                                        {nodeDatum.attributes && Object.keys(nodeDatum.attributes).map((key: string, index: number) => (
                                                                            <tspan key={index} x={40} dy={index === 0 ? 20 : 15}>
                                                                                <tspan className='text-gray-500'>{key}: </tspan>
                                                                                <tspan className='text-gray-700'>{nodeDatum.attributes[key]}</tspan>
                                                                            </tspan>
                                                                        ))}
                                                                    </>
                                                                )}
                                                                {isDepartment && (
                                                                    <>
                                                                        {nodeDatum.attributes && Object.keys(nodeDatum.attributes).map((key: string, index: number) => (
                                                                            <tspan key={index} x={40} dy={index === 0 ? 20 : 15}>
                                                                                <tspan className='text-gray-500'>{key}: </tspan>
                                                                                <tspan className='text-gray-700'>{nodeDatum.attributes[key]}</tspan>
                                                                            </tspan>
                                                                        ))}
                                                                    </>
                                                                )}
                                                            </text>
                                                        </g>
                                                    </>
                                                )
                                                : (
                                                    <>
                                                        <circle
                                                            r={15}
                                                            style={{ fill: '#04ABD8' }}
                                                        />
                                                        <g className="rd3t-label">
                                                            <text className="rd3t-label__title" textAnchor="start" x={40}>
                                                                {nodeData.nodeDatum.name}
                                                            </text>
                                                        </g>
                                                    </>
                                                )
                                            }
                                        </g>
                                    );
                                }}
                                shouldCollapseNeighborNodes
                                collapsible={false}
                                hasInteractiveNodes
                                scaleExtent={{ min: 0.4, max: 3 }} // Zoom scale
                                onNodeClick={handleNodeClick}
                                onNodeMouseOver={handleNodeMouseOver}
                                onNodeMouseOut={() => setTooltip({ ...tooltip, visible: false })}
                                rootNodeClassName="node__root"
                                branchNodeClassName="node__branch"
                                leafNodeClassName="node__leaf mt-10"
                            />
                        )
                        : (
                            <div className='flex justify-center items-center h-[calc(100vh-100px)]'>
                                <p className='text-gray-500'>No se han encontrado redes comerciales</p>
                            </div>
                        )
                    }
                </div>

                {tooltip.visible && tooltip.nodeData !== null && (
                    <div
                        onMouseLeave={() => setTooltip({ ...tooltip, visible: false })}
                        style={{
                            position: 'absolute',
                            top: tooltip.y,
                            left: tooltip.x,
                            background: 'white',
                            border: '1px solid #ccc',
                            padding: '10px 15px',
                            borderRadius: '5px',
                            boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
                            minWidth: '120px',
                            maxWidth: '240px',
                            zIndex: 1000
                        }}
                    >
                        {getTooltipInfo(tooltip.nodeData?.nodeDatum)}
                    </div>
                )}

                <div className='col-span-3 md:col-span-1 asideTreeForm ps-3'>
                    <NetworkForm id={nodeId} setNodeId={setNodeId} isOpen={isNetworkOpen} setIsOpen={setIsNetworkOpen} refetch={refetchNetworks} />

                    <DepartmentForm id={nodeId} setNodeId={setNodeId} setCopyConfig={setCopyConfig} setInfo={setInfo} isOpen={isDepartmentOpen} setIsOpen={setIsDepartmentOpen} refetch={refetchDepartments} />
                </div>

                <InfoModal isOpen={info} nodeData={tooltip.nodeData?.nodeDatum} onClose={() => setInfo(false)} />

                <CopyConfigModal department={tooltip.nodeData?.nodeDatum} isOpen={copyConfig} onClose={() => setCopyConfig(false)} refetch={refetchDepartments} />
            </div>

            <FilterDrawerView isOpen={openFilters} setOpenDrawer={setOpenFilters} drawerTitle={'Filtros Empresas'}>
                <div className="grid grid-cols-1 gap-6">
                    <NetworksFilters filters={filters} updateFilters={updateFilters} resetFilters={resetFilters} />
                </div>
            </FilterDrawerView>
        </Page >
    );
};

export default NetworksList;