export default function ShootingStarIcon({
  ...props
}: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      fill="none"
      {...props}
    >
      <path
        fill="#EDC71F"
        d="M16.369 5.22S6.386 6.153.69 19.58c-.092.218-.417.137-.397-.098.15-1.771.619-5.04 2.087-7.321a.208.208 0 0 0-.177-.321l-.564.006a.208.208 0 0 1-.166-.335C6.67 4.645 14.936 4.402 14.936 4.402l1.433.817Z"
      />
      <path
        fill="#E6BB00"
        d="M14.936 4.402s-.496.015-1.321.138l1.191.68s-8.223.77-14 10.95a23.742 23.742 0 0 0-.512 3.312c-.02.235.305.316.397.099C6.386 6.154 16.37 5.219 16.37 5.219l-1.433-.817Z"
      />
      <path
        fill="#FFEA00"
        d="m16.025.525.931 1.89c.06.123.178.208.313.228l2.082.303c.34.05.477.469.23.71l-1.507 1.471a.417.417 0 0 0-.119.368l.356 2.078a.415.415 0 0 1-.602.439l-1.863-.981a.415.415 0 0 0-.386 0l-1.863.98a.415.415 0 0 1-.602-.438l.356-2.078a.416.416 0 0 0-.12-.368l-1.507-1.472a.416.416 0 0 1 .23-.709l2.083-.303a.415.415 0 0 0 .312-.228l.932-1.89a.415.415 0 0 1 .744 0Z"
      />
      <path
        fill="#F2DE00"
        d="M19.581 3.655a.416.416 0 0 0-.23-.709l-2.082-.303a.415.415 0 0 1-.313-.228l-.93-1.89a.415.415 0 0 0-.745 0l-.098.198c.568.829.9 1.83.9 2.911a5.149 5.149 0 0 1-2.216 4.235l1.593-.839a.414.414 0 0 1 .386 0l1.863.982c.304.16.66-.099.602-.439l-.356-2.078a.416.416 0 0 1 .12-.368l1.506-1.472Z"
      />
      <path
        fill="currentColor"
        d="M19.965 3.139a.704.704 0 0 0-.572-.482l-2.082-.303a.122.122 0 0 1-.092-.067l-.931-1.89c-.255-.518-1.015-.52-1.27 0l-.932 1.89a.122.122 0 0 1-.092.067l-2.082.303c-.568.083-.803.808-.393 1.209l.66.644A20.579 20.579 0 0 0 7.26 6.293c-2.408 1.25-4.434 2.946-6.02 5.042a.505.505 0 0 0 .404.804l.407-.005c-.267.432-.513.917-.734 1.444-.147.353.396.572.541.226.23-.55.489-1.05.769-1.485a.505.505 0 0 0-.426-.772l-.389.004c1.517-1.963 3.44-3.556 5.718-4.738a20.023 20.023 0 0 1 5.153-1.811l.343.336c.03.028.043.069.036.109l-.073.426c-2.204.904-4.272 2.26-6.15 4.032-2.502 2.364-4.587 5.41-6.202 9.064.11-1.035.32-2.396.722-3.75.104-.351-.453-.534-.562-.166-.489 1.65-.702 3.306-.795 4.404-.047.551.742.75.96.238 1.61-3.8 3.724-6.95 6.28-9.364 1.728-1.63 3.62-2.898 5.63-3.773l-.166.967c-.097.565.519 1.015 1.028.747l1.862-.981a.121.121 0 0 1 .113 0l1.863.98c.509.269 1.124-.18 1.028-.746l-.077-.45c-.062-.361-.643-.279-.578.099l.077.45a.123.123 0 0 1-.177.13l-1.863-.982a.707.707 0 0 0-.659 0l-1.863.981a.122.122 0 0 1-.176-.13l.355-2.078a.71.71 0 0 0-.203-.627l-1.507-1.471a.123.123 0 0 1 .068-.21l2.082-.303a.708.708 0 0 0 .533-.388l.931-1.891a.123.123 0 0 1 .22 0l.93 1.89c.103.21.303.355.534.389l2.082.303c.1.014.14.14.068.21L17.87 4.918c-.193.189-.247.466-.188.722.062.362.643.28.578-.099-.019-.057-.027-.159.019-.203l1.507-1.472a.705.705 0 0 0 .179-.727Z"
      />
      <path
        fill="currentColor"
        d="M8.323 10.181c-1.247 1.112-2.65 2.836-2.708 2.909-.234.288.22.658.455.369.014-.018 1.431-1.761 2.643-2.84.274-.244-.103-.692-.39-.438Zm-3.259 4.103a.293.293 0 0 0-.408.07c-1.156 1.64-1.797 2.97-1.824 3.027-.157.329.36.603.529.253.006-.014.648-1.346 1.774-2.942a.293.293 0 0 0-.07-.408Zm1.609-8.452c.044 0 .089-.01.131-.031a17.423 17.423 0 0 1 3.63-1.34c.356-.087.232-.66-.14-.568-1.298.32-2.56.786-3.753 1.384-.264.132-.166.555.132.555ZM4.028 7.51a.292.292 0 0 0 .192-.072c.004-.003.416-.36 1.169-.836.31-.197.01-.7-.314-.495-.794.503-1.222.874-1.24.89-.197.172-.065.513.193.513Zm9.426-5.604c.377 0 .378-.586 0-.586-.377 0-.378.586 0 .586Zm4.372.04c.377 0 .378-.587 0-.587-.377 0-.378.586 0 .586Zm1.264 3.417c-.377 0-.378.586 0 .586.377 0 .377-.586 0-.586ZM15.653 7.91c-.377 0-.378.586 0 .586.377 0 .378-.586 0-.586Z"
      />
    </svg>
  );
}
