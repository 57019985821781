export default function TableCollapsibleIcon({
  ...props
}: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      fill="none"
      {...props}
    >
      <path
        fill="#FFAB61"
        d="m9.117 10.594-8.03 3.318c-.575.226-.63 1.14-.062 1.39l7.869 3.242c.742.276 1.525.276 2.213 0l7.869-3.241c.568-.25.513-1.165-.063-1.391l-8.03-3.318c-.675-.275-1.07-.278-1.766 0Z"
      />
      <path
        fill="#FF8F64"
        d="M14.759 12.195c-1.177.5-2.363.993-3.535 1.497-.765.288-1.268.435-2.198.095l-2.598-1.112c-.781-.367-1.296.837-.491 1.149l2.567 1.098c1.056.461 2.045.412 3.283-.113l4.595-1.943-1.623-.67Z"
      />
      <path
        fill="#FFC861"
        d="m9.117 6.028-8.03 3.318c-.575.226-.63 1.14-.062 1.39l7.869 3.242c.742.276 1.525.276 2.213 0l7.869-3.241c.568-.251.513-1.165-.063-1.391l-8.03-3.318c-.675-.275-1.07-.278-1.766 0Z"
      />
      <path
        fill="#FFAB61"
        d="M14.788 7.64c-1.178.501-2.367.995-3.54 1.5-.766.289-1.27.436-2.202.094L6.444 8.121c-.782-.368-1.298.839-.492 1.15l2.572 1.1c1.057.463 2.048.413 3.287-.113l4.603-1.946-1.626-.671Z"
      />
      <path
        fill="#55A1FF"
        d="m9.117 1.457-8.03 3.318c-.575.227-.63 1.14-.062 1.391l7.869 3.242c.742.275 1.525.276 2.213 0l7.869-3.242c.568-.25.513-1.164-.063-1.39l-8.03-3.319c-.675-.275-1.07-.278-1.766 0Z"
      />
      <path
        fill="#00003A"
        d="M9.016 1.17.976 4.493C.137 4.823.067 6.097.9 6.463 3.656 7.586 6.44 8.759 8.798 9.718c.81.301 1.677.304 2.442-.002 1.293-.531 2.585-1.065 3.878-1.598l3.71 1.533c.316.124.357.68.051.815l-7.872 3.245c-.613.245-1.316.246-1.991-.005l-7.863-3.24c-.316-.15-.259-.697.056-.818l2.305-.951c.419-.145.16-.77-.238-.578l-2.3.95C.137 9.396.067 10.67.9 11.037l3.192 1.316-3.118 1.288c-.837.33-.907 1.604-.074 1.971 2.75 1.137 5.541 2.285 7.897 3.254.81.3 1.677.304 2.442-.003 2.75-1.13 5.539-2.28 7.891-3.25.834-.368.763-1.642-.074-1.972l-2.685-1.108c-.4-.197-.66.435-.238.577.899.37 1.795.747 2.694 1.113.316.125.357.681.051.816l-7.872 3.244c-.61.245-1.311.246-1.985-.002L1.153 15.04c-.306-.135-.265-.691.05-.816 1.237-.507 2.475-1.02 3.71-1.532 1.296.532 2.59 1.07 3.885 1.6.81.3 1.677.304 2.442-.003 2.75-1.126 5.537-2.286 7.891-3.25.834-.368.763-1.642-.074-1.972L15.938 7.78c1.064-.438 2.129-.878 3.193-1.315.834-.367.763-1.642-.074-1.971-2.675-1.118-5.367-2.2-8.038-3.323-.736-.305-1.293-.284-2.003 0Zm1.764.58c2.802 1.16 5.65 2.333 8.048 3.325.316.124.357.681.051.816l-7.872 3.244c-.613.245-1.316.246-1.991-.005L1.153 5.89c-.318-.138-.256-.703.056-.818l8.038-3.32c.659-.269.955-.234 1.534-.002Z"
      />
    </svg>
  );
}
