import SimpleBar from 'simplebar-react';
import {
    Bar,
    XAxis,
    YAxis,
    BarChart,
    LabelList,
    Rectangle,
    CartesianGrid,
    ResponsiveContainer,
} from 'recharts';
import WidgetCard from '../../../components/card/WidgetCard';
import cn from '../../../utils/classNames';
import { revenueSummaryData2 } from './example-data';

export default function CustomerGrowthSummary({
    className,
}: {
    className?: string;
}) {
    return (
        <WidgetCard
            className={cn('@container', className)}
            title="Cursos impartidos"
            description="Resumen de los cursos impartidos en el mes"
        >
            <SimpleBar className="w-full">
                <div className="mt-[22px] h-[350px]">
                    <ResponsiveContainer width="100%" height="100%" minWidth={600}>
                        <BarChart
                            data={revenueSummaryData2}
                            margin={{
                                top: 22,
                                left: -15,
                            }}
                            className="[&_.recharts-tooltip-cursor]:fill-opacity-20 dark:[&_.recharts-tooltip-cursor]:fill-opacity-10 [&_.recharts-cartesian-axis-tick-value]:fill-gray-500 [&_.recharts-cartesian-axis.yAxis]:-translate-y-3 rtl:[&_.recharts-cartesian-axis.yAxis]:-translate-x-12 [&_.recharts-cartesian-grid-vertical]:opacity-0"
                        >
                            <CartesianGrid strokeDasharray="1 0" vertical={false} />
                            <XAxis dataKey="day" tickLine={false} />
                            <YAxis axisLine={false} tickLine={false} />
                            <Bar
                                barSize={24}
                                fill={'#59A7FF'}
                                dataKey={'revenue'}
                                radius={[0, 0, 6, 6]}
                                activeBar={<Rectangle fill="#2CDDC7" stroke="#2CDDC7" />}
                                activeIndex={getActiveIndex()}
                                background={{ fill: '#F1F1F2', radius: 6 }}
                            >
                                <LabelList dataKey="revenue" content={renderCustomizedLabel} />
                            </Bar>
                        </BarChart>
                    </ResponsiveContainer>
                </div>
            </SimpleBar>
        </WidgetCard>
    );
}

function renderCustomizedLabel(props: any) {
    const { x, y, index } = props;
    let isActive = index === getActiveIndex();

    return (
        <g>
            <defs>
                <filter id="shadow">
                    <feDropShadow dx="0.2" dy="0.4" stdDeviation="0.2" />
                </filter>
            </defs>
            <rect
                x={x - 8}
                y={y - 4}
                width="40"
                height="4"
                rx="4"
                fill={isActive ? '#2CDDC7' : '#59A7FF'}
                filter="url(#shadow)"
            />
        </g>
    );
}

function getActiveIndex() {
    let thisMonthName = new Date().toLocaleString('default', { month: 'short' });
    let activeIndex = revenueSummaryData2.findIndex(
        (data: any) => data.day === thisMonthName
    );

    return activeIndex;
}