import cn from "../../utils/classNames";

interface FormGroupProps {
  title: React.ReactNode;
  className?: string;
  childClassName?: string;
  description?: string;
  children?: React.ReactNode;
}

export default function FormGroup({
  title,
  className,
  childClassName,
  description,
  children,
}: FormGroupProps) {
  return (
    <div className={cn('grid gap-5 @3xl:grid-cols-12', className)}>
      <div className="col-span-full @sm:col-span-1 @4xl:col-span-4">
        <h4 className="text-base font-medium">{title}</h4>
        {description && <p className="mt-2 text-muted500">{description}</p>}
      </div>

      {children && (
        <div className={childClassName ?? "col-span-full grid @2xl:grid-cols-2 @4xl:col-span-8 gap-4 @4xl:gap-5 xl:gap-7"}>
          {children}
        </div>
      )}
    </div>
  );
}