export default function RocketFlamingIcon({
  ...props
}: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      fill="none"
      {...props}
    >
      <path
        fill="#fff"
        d="M9.719 14.45 5.55 10.282l.071-.26a13.938 13.938 0 0 1 3.59-6.205c.026-.026-.025.025 0 0C11.918 1.16 15.8-.133 19.544.457c.592 3.745-.703 7.627-3.36 10.331-.025.026.027-.025.001 0A13.938 13.938 0 0 1 9.98 14.38l-.261.071Z"
      />
      <path
        fill="#D8D7DA"
        d="M19.543.458a11.89 11.89 0 0 0-5.356.398l4.957 4.958c.531-1.72.684-3.554.399-5.356Z"
      />
      <path
        fill="#FF6243"
        d="M19.394 4.873a11.81 11.81 0 0 0 .149-4.416 11.81 11.81 0 0 0-4.416.15l4.267 4.266ZM8.737 13.368 6.318 10.95c-.565-.565-5.58.314-5.58 4.103 0 0 1.45-.786 1.684-.42 0 0-2.098 2.509-2.106 5.052 2.534 0 4.632-2.105 4.632-2.105.283.174-.421 1.684-.421 1.684 3.789 0 4.775-5.33 4.21-5.895Z"
      />
      <path
        fill="#E55841"
        d="M6.318 10.949c-.164-.164-.702-.206-1.383-.091l.615.614-.893.894 2.977 2.977.893-.893.293.292c.112-.684.076-1.216-.083-1.374l-2.419-2.419Z"
      />
      <path
        fill="#FFDA44"
        d="M1.272 18.728c.341-1.54 1.358-3.031 1.796-3.555l.397-.474-.334-.52c-.198-.308-.545-.485-.953-.485-.091 0-.187.01-.287.026.249-.482.645-.903 1.187-1.257.87-.568 1.937-.826 2.562-.826.084 0 .143.005.183.01l2.215 2.214c.061.47-.182 2.046-1.097 3.263-.331.44-.704.768-1.117.985.077-.444.042-.953-.435-1.246l-.567-.349-.47.472c-.015.014-1.364 1.339-3.08 1.742Z"
      />
      <path
        fill="#DBBC3A"
        d="m8.038 13.861-2.215-2.215c-.04-.005-.1-.01-.184-.01-.08 0-.17.005-.264.014l-.718.718 2.977 2.977.253-.253c.141-.552.18-1.012.15-1.23Z"
      />
      <path
        fill="#fff"
        d="m2.224 18.221-.447-.447.48-.48.447.447-.48.48Zm.927-.927-.447-.447 2.442-2.44.446.446-2.441 2.441Z"
      />
      <path
        fill="#D8D7DA"
        d="M9.421 7.604 6.146 10.88l2.978 2.977 3.274-3.275-2.977-2.977Z"
      />
      <path
        fill="#FF6243"
        d="m5.849 12.366 1.786 1.786 3.572-3.572-1.786-1.786-3.572 3.572ZM5.55 10.28l.07-.26a14.04 14.04 0 0 1 2.023-4.332l-2.187-.856-1.84 4.705 1.956.766-.022-.023Zm8.76 2.075a14.042 14.042 0 0 1-4.332 2.022l-.26.072-.023-.023.766 1.957 4.705-1.841-.856-2.187Z"
      />
      <path
        fill="#E55841"
        d="m4.759 9.986.814.319-.022-.022.07-.261A14.04 14.04 0 0 1 7.645 5.69l-.804-.315A14.902 14.902 0 0 0 4.81 9.8l-.051.186Z"
      />
      <path
        fill="#D8D7DA"
        d="M14.422 9.158a2.526 2.526 0 1 0 0-5.052 2.526 2.526 0 0 0 0 5.052Z"
      />
      <path
        fill="#FFDA44"
        d="M14.422 8.107a2.526 2.526 0 1 0 0-5.052 2.526 2.526 0 0 0 0 5.052Z"
      />
      <path
        fill="#DBBC3A"
        d="M14.632 4.107c1.035 0 1.925.623 2.315 1.514v-.04a2.526 2.526 0 1 0-4.84 1.012 2.526 2.526 0 0 1 2.525-2.486Z"
      />
      <path
        fill="#fff"
        d="M14.421 7.578v-.632c.171 0 .338-.03.496-.092l.23.588a1.99 1.99 0 0 1-.726.136Zm1.357-.531-.428-.464c.279-.258.44-.625.44-1.005h.63c0 .556-.233 1.091-.642 1.469Z"
      />
      <path
        fill="#E55841"
        d="M14.311 12.36A14.042 14.042 0 0 1 9.98 14.38l-.26.072-.023-.023.319.815.186-.051a14.903 14.903 0 0 0 4.425-2.03l-.315-.805Zm-6.415-.256a5.9 5.9 0 0 1-1.574-.213l-.473.474 1.786 1.786 2.48-2.48a5.88 5.88 0 0 1-2.22.433Zm9.377-9.352 2.122 2.121a11.91 11.91 0 0 0 .27-3.299 5.035 5.035 0 0 1-2.392 1.178Z"
      />
      <path
        fill="currentColor"
        d="m15.576 14.722-.88-2.251a14.02 14.02 0 0 0 1.728-1.477 12.635 12.635 0 0 0 3.142-5.503l.011.011.125-.56.01-.046a13.048 13.048 0 0 0 .078-.388c.21-1.119.262-2.243.158-3.356l-.009-.09-.013-.122A12.169 12.169 0 0 0 19.9.722l-.01-.071a11.008 11.008 0 0 0-.035-.243L19.819.18l-.227-.036a11.572 11.572 0 0 0-.531-.071 10.544 10.544 0 0 0-.21-.021 12.077 12.077 0 0 0-3.362.157l-.028.005a12.597 12.597 0 0 0-.357.073l-.045.01-.561.125.01.01a12.635 12.635 0 0 0-5.52 3.159c-.531.531-1.019 1.105-1.459 1.713l-2.25-.881-2.072 5.293 2.189.857.899.899-.893.893 2.232 2.233.894-.894.898.899.857 2.189 5.293-2.071ZM18.176.64a4.327 4.327 0 0 1 .212.01h.01l.213.015.013.001a11.293 11.293 0 0 1 .385.037l.06.007.196.025a10.102 10.102 0 0 1 .047.403l.006.054.017.193v.002l.015.218c.05.872 0 1.75-.15 2.626L15.768.801A11.482 11.482 0 0 1 18.175.64ZM9.42 4.054A11.934 11.934 0 0 1 15.024.949l4.027 4.027a11.935 11.935 0 0 1-3.105 5.603 13.41 13.41 0 0 1-1.845 1.539l-.142.097-.174.115-.082.053a14.158 14.158 0 0 1-.198.124 13.73 13.73 0 0 1-2.54 1.228l-.073.026-.243.084-.143.047-.106.034a14.72 14.72 0 0 1-.163.05l-.087.026a15.39 15.39 0 0 1-.254.072l-.084.023-.838-.838 2.68-2.68L9.42 8.347l-2.68 2.679-.838-.838.023-.084c.023-.085.048-.17.072-.254l.026-.086a13.375 13.375 0 0 1 .084-.269l.048-.147.08-.231.03-.083a13.538 13.538 0 0 1 .083-.224c.308-.807.69-1.581 1.141-2.311l.039-.063.087-.137.058-.09a12.708 12.708 0 0 1 .21-.31A13.4 13.4 0 0 1 9.42 4.053ZM5.636 5.242l1.525.597-.045.069-.026.042a14.287 14.287 0 0 0-1.546 3.237l-.035.105a13.523 13.523 0 0 0-.157.52l-.017.06-1.31-.513 1.611-4.117Zm.659 7.124L9.42 9.24l1.34 1.34-3.127 3.125-1.34-1.34Zm3.893 2.282a17.354 17.354 0 0 0 .52-.157l.105-.035a14.28 14.28 0 0 0 3.28-1.572l.068-.045.597 1.525-4.117 1.61-.513-1.309.06-.017Z"
      />
      <path
        fill="currentColor"
        d="M14.421 8.422c1.567 0 2.842-1.275 2.842-2.842s-1.275-2.842-2.842-2.842c-1.567 0-2.842 1.275-2.842 2.842s1.275 2.842 2.842 2.842Zm0-5.052c1.219 0 2.21.991 2.21 2.21 0 1.22-.991 2.21-2.21 2.21a2.213 2.213 0 0 1-2.21-2.21c0-1.219.991-2.21 2.21-2.21Zm-9.486-.961.447.446L7.697.54 7.251.093 4.935 2.41ZM4.75 3.486l-.446-.447-3.158 3.158.446.446L4.75 3.486ZM.093 7.249l.447.446.631-.631-.446-.447-.632.632Zm19.814 8.448-.446-.447-3.158 3.158.447.446 3.158-3.157Zm-3.579 3.578-.446-.447-.632.632.447.446.631-.631Zm-1.263-1.682 2.316-2.316-.447-.446-2.315 2.315.446.447Zm2.711-3.608-.42.421.446.447.421-.421-.447-.447Zm-3.131 4.028-.447-.447-1.894 1.895.446.447 1.895-1.895ZM1.278 2.698l.447.447L4.33.54 3.883.093 1.278 2.698ZM.093 3.882l.447.446.71-.71-.446-.447-.71.71ZM5.02 18.914c.04-.101.082-.21.122-.323.19-.54.318-1.068-.029-1.281l-.212-.13-.176.176c-.02.02-1.856 1.83-4.08 2 .174-2.284 2-4.499 2.02-4.522l.148-.178-.126-.195c-.285-.445-1.029-.158-1.309-.05-.1.038-.197.08-.288.12.311-2.173 2.54-3.133 3.898-3.361l-.105-.623c-1.657.278-4.461 1.541-4.461 4.506v.53l.466-.253c.309-.167.648-.313.91-.399-.624.88-1.791 2.786-1.797 4.752L0 20h.317c1.83 0 3.419-1.019 4.247-1.67a8.267 8.267 0 0 1-.323.8l-.21.449h.496c1.304 0 2.45-.597 3.312-1.725.907-1.187 1.206-2.536 1.292-3.06l-.623-.103c-.243 1.477-1.246 3.908-3.488 4.223Z"
      />
    </svg>
  );
}
