import React, { useEffect, useState } from 'react';
import RequiredInput from './InputHOC';

interface Props {
    id: string;
    label: string;
    isRequired?: boolean;
    isClearable?: boolean;
    filters: any;
    updateFilters: (filter: any) => void;
    delay?: number;
}

const CustomTextDelayInput: React.FC<Props> = ({ 
    id, 
    label, 
    filters, 
    updateFilters, 
    delay = 1000, // Retraso por defecto de 1000 ms
    isRequired = false, 
    isClearable = false 
}) => {
    const [inputValue, setInputValue] = useState<string>(filters.filter_filters?.[id] || '');
    const [timer, setTimer] = useState<NodeJS.Timeout | null>(null);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value.replace('%', '');
        setInputValue(value);

        if (timer) {
            clearTimeout(timer);
        }

        const newTimer = setTimeout(() => {
            updateFilters({ [id]: value });
        }, delay);
        
        setTimer(newTimer);
    };

    useEffect(() => {
        setInputValue(filters.filter_filters?.[id] || '');
    }, [filters]);

    return (
        <RequiredInput
            required={isRequired}
            clearable={isClearable}
            id={id}
            label={label}
            value={inputValue}
            onChange={handleChange}
        />
    );
};

export default CustomTextDelayInput;
