export default function UserPlusIcon({
  ...props
}: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 20 20"
      {...props}
    >
      <path
        fill="#CCDE60"
        d="M11.284 15.942c0 .685.195 1.37.587 1.956H1.479v-2.176A6.5 6.5 0 0 1 7.983 9.22H8.4a6.556 6.556 0 0 1 5.623 3.203 3.655 3.655 0 0 0-2.738 3.52Z"
      />
      <path
        fill="#F7D6BF"
        d="M8.203 9.243a4.4 4.4 0 1 0 0-8.802 4.4 4.4 0 0 0 0 8.802Z"
      />
      <path
        fill="#80BFBE"
        d="M18.546 15.944a3.61 3.61 0 0 1-3.619 3.618 3.61 3.61 0 0 1-3.618-3.618 3.634 3.634 0 0 1 2.738-3.521c.293-.073.587-.122.905-.122 1.956.024 3.594 1.638 3.594 3.643Z"
      />
      <path
        fill="#172956"
        d="M3.68 6.553c-.025-.074-.05-.122-.074-.196a.43.43 0 0 1 .27-.538.43.43 0 0 1 .537.27c.025.048.05.097.05.17a.454.454 0 0 1-.245.563c-.22.098-.465-.05-.538-.27Zm15.28 9.389c0 2.249-1.809 4.058-4.058 4.058a4.031 4.031 0 0 1-3.276-1.663H9.132a.438.438 0 0 1-.44-.44c0-.244.195-.44.44-.44h2.03a3.91 3.91 0 0 1-.294-1.54c0-1.492.831-3.007 2.494-3.74a6.16 6.16 0 0 0-4.963-2.52h-.416c-3.35 0-6.064 2.715-6.088 6.089v1.76h5.281c.244 0 .44.196.44.44 0 .245-.196.44-.44.44H1.479c-.244 0-.415-.195-.44-.44V15.77a6.925 6.925 0 0 1 4.89-6.626 4.344 4.344 0 0 1-1.394-1.1.431.431 0 0 1 .05-.611.431.431 0 0 1 .61.049 3.97 3.97 0 0 0 2.983 1.344 3.93 3.93 0 0 0 3.937-3.985C12.09 2.641 10.33.881 8.129.905c-2.176 0-3.936 1.785-3.936 3.96 0 .245-.196.44-.44.44a.438.438 0 0 1-.44-.44A4.868 4.868 0 0 1 8.129 0c2.665-.024 4.841 2.152 4.866 4.817a4.834 4.834 0 0 1-2.616 4.303 6.95 6.95 0 0 1 3.79 2.86c2.518-.464 4.792 1.468 4.792 3.961Zm-.855 0a3.214 3.214 0 0 0-3.203-3.203 3.214 3.214 0 0 0-3.203 3.202 3.214 3.214 0 0 0 3.203 3.203 3.183 3.183 0 0 0 3.203-3.202Zm-1.54-.416h-1.223v-1.198a.438.438 0 0 0-.44-.44.438.438 0 0 0-.44.44v1.198h-1.223a.438.438 0 0 0-.44.44c0 .245.196.44.44.44h1.223v1.223c0 .244.196.44.44.44.245 0 .44-.196.44-.44v-1.223h1.223c.244 0 .44-.196.44-.44 0-.245-.22-.44-.44-.44Z"
      />
    </svg>
  );
}
