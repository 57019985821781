export default function UserLockIcon({
  ...props
}: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      fill="none"
      {...props}
    >
      <path
        fill="#FA669B"
        d="M9.456 17.817a8.362 8.362 0 1 0 0-16.723 8.362 8.362 0 0 0 0 16.723Z"
      />
      <path
        fill="#75E1F7"
        d="M15.324 15.21v.203a8.362 8.362 0 0 1-11.736 0v-.203a1.908 1.908 0 0 1 1.566-1.876l3.056-.557h2.493l3.055.557a1.906 1.906 0 0 1 1.566 1.876Z"
      />
      <path
        fill="#FFA788"
        d="M10.703 11.262v1.514a1.219 1.219 0 0 1-1.247.957 1.219 1.219 0 0 1-1.247-.957v-1.514h2.494Z"
      />
      <path
        fill="#FCBFAB"
        d="M6.521 7.62c0 1.92.84 3 1.622 3.593a2.18 2.18 0 0 0 2.625 0c.782-.592 1.621-1.674 1.621-3.592V6.614a2.934 2.934 0 0 0-5.868 0V7.62Z"
      />
      <path
        fill="#E6F2FF"
        d="M15.369 18.908a3.538 3.538 0 1 0 0-7.076 3.538 3.538 0 0 0 0 7.076Z"
      />
      <path fill="#FFD92E" d="M13.962 15.035h2.812v1.875h-2.812v-1.875Z" />
      <path
        fill="currentColor"
        d="M5.121 2.626A8.091 8.091 0 0 1 17.43 8.085a.273.273 0 1 0 .54-.092A8.64 8.64 0 0 0 4.827 2.165a.273.273 0 1 0 .293.46ZM3.29 4.08a.273.273 0 0 0 .202-.088c.174-.19.358-.373.547-.543a.273.273 0 1 0-.366-.406 8.726 8.726 0 0 0-.584.58.273.273 0 0 0 .201.458Z"
      />
      <path
        fill="currentColor"
        d="M17.613 12.29c.317-.91.479-1.87.478-2.834a.274.274 0 0 0-.547 0 8.07 8.07 0 0 1-.407 2.538 3.802 3.802 0 0 0-4.609.837l-1.552-.283v-1.15c.78-.606 1.687-1.746 1.687-3.776V6.615a3.207 3.207 0 0 0-6.415 0v1.007c0 2.03.908 3.17 1.688 3.776v1.15l-2.832.515a2.18 2.18 0 0 0-1.74 1.688 8.053 8.053 0 0 1-.711-9.673.274.274 0 1 0-.46-.297 8.637 8.637 0 0 0 6.56 13.28.273.273 0 0 0 .023-.546 8.036 8.036 0 0 1-4.915-2.24v-.066a1.633 1.633 0 0 1 1.341-1.607l2.818-.514a1.484 1.484 0 0 0 1.436.918 1.485 1.485 0 0 0 1.435-.918l1.267.23a3.797 3.797 0 0 0-.164 3.82 8.063 8.063 0 0 1-1.84.377.274.274 0 0 0 .023.545h.023a8.604 8.604 0 0 0 2.091-.446 3.813 3.813 0 0 0 4.399 1.33 3.81 3.81 0 0 0 .922-6.653ZM6.795 7.623V6.615a2.66 2.66 0 0 1 5.322 0v1.007c0 1.842-.823 2.851-1.513 3.373a1.907 1.907 0 0 1-2.296 0c-.69-.522-1.513-1.531-1.513-3.373Zm2.661 5.838a.934.934 0 0 1-.974-.714v-1.021a2.458 2.458 0 0 0 1.948 0v1.022a.933.933 0 0 1-.974.713Zm5.913 5.173a3.264 3.264 0 1 1 0-6.53 3.264 3.264 0 0 1 0 6.53Z"
      />
      <path
        fill="currentColor"
        d="M16.775 14.763h-.323v-.401a1.082 1.082 0 1 0-2.166 0v.401h-.323a.274.274 0 0 0-.273.273v1.875a.274.274 0 0 0 .273.274h2.812a.275.275 0 0 0 .274-.274v-1.875a.273.273 0 0 0-.274-.273Zm-1.942-.401a.538.538 0 0 1 .743-.504.539.539 0 0 1 .33.504v.401h-1.073v-.401Zm1.669 2.276h-2.266V15.31h2.266v1.328Z"
      />
    </svg>
  );
}
