import { DropdownMenuItemsType, SubMenuItemType } from "../type/menu-type";

export function useActivePathname() {
    const pathname = window.location.pathname

  /**
   * Checks if a given pathname is active.
   * @param {string | DropdownItemType[]} belongsTo - The value to compare the pathname against of an href array
   * @return {boolean} Returns true if the pathname is active, false otherwise.
   */
  function isActive(belongsTo: string | DropdownMenuItemsType): boolean {
    let isActive: boolean = false;
    if (typeof belongsTo === 'string') {
      isActive = pathname === belongsTo;
    } else if (belongsTo !== null && belongsTo !== undefined) {
      isActive = belongsTo?.some(
        (item) =>
          item?.path === pathname ||
          item?.subMenuItems?.some(
            (subItem: SubMenuItemType) => subItem.href === pathname
          )
      );
    }
    return isActive;
  }

  return {
    isActive,
  };
}
