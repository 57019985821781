import { Badge, Text, Title } from 'rizzui';
import { PiCaretRightBold, PiTrendDownBold, PiTrendUpBold, } from 'react-icons/pi';
import cn from '../../../utils/classNames';
import { formatNumberWithCommas } from '../../../utils/formatNumber';
import { CrmStatType, crmStatData } from './example-data';

export type StatCardProps = {
    className?: string;
    statItem: CrmStatType;
};

export default function CRMStats({ className }: { className?: string }) {
    return (
        <div className={cn('@container', className)}>
            <div className="grid grid-cols-1 gap-4 @lg:grid-cols-2 @3xl:gap-6 @4xl:grid-cols-4">
                {crmStatData.map((stat: CrmStatType, index: number) => {
                    return <StatCard key={'stat-card-' + index} statItem={stat} />;
                })}
            </div>
        </div>
    );
}

function StatCard({ className, statItem }: StatCardProps) {
    const { title, customer, increased, percentage, lastMonth } = statItem;

    return (
        <div
            className={cn(
                'space-y-4 rounded-lg border border-muted p-5 dark:bg-[#181818]',
                className
            )}
        >
            <div className='flex justify-between align-middle'>
                <Text className="font-semibold text-gray-900">{title}</Text>
                <PiCaretRightBold className="size-3.5 rtl:rotate-180" />
            </div>

            <div className='flex justify-end gap-2'>
                <Title className="text-3xl font-normal leading-none">
                    {formatNumberWithCommas(customer)}
                </Title>
                <Badge
                    size="sm"
                    style={{
                        backgroundColor: increased ? '#C0F2CC' : '#FCECD6',
                        color: increased ? '#22973F' : '#EE6D3D',
                    }}
                >
                    <span className="pe-1">{percentage}%</span>
                    {increased ? (
                        <PiTrendUpBold className="size-3" />
                    ) : (
                        <PiTrendDownBold className="size-3" />
                    )}
                </Badge>
            </div>

            <div className="text-gray-400">
                último mes:{' '}
                <strong className="text-gray-900">
                    {formatNumberWithCommas(lastMonth)}
                </strong>
            </div>
        </div>
    );
}