import { IoClose } from "react-icons/io5";
import { Modal, Text, ActionIcon, Button } from "rizzui";
import { DepartmentService } from "../../../../services/departments/departmentService";
import useHandleErrors from "../../../../hooks/useHandleErrors";
import { useCallback, useState } from "react";
import { toast } from "sonner";
import useDepartments from "../../../../hooks/api-calls/useDepartments";
import CustomSelect from "../../../../components/forms/CustomSelect";

interface CopyConfigModalProps {
    department: any;
    isOpen: boolean;
    onClose: () => void;
    refetch: () => void;
}

export const CopyConfigModal: React.FC<CopyConfigModalProps> = ({ department, isOpen, onClose, refetch }) => {

    const service = new DepartmentService();
    const { handleErrors } = useHandleErrors();
    const { getDepartmentsList } = useDepartments();

    const [departmentsIds, setDepartmentsIds] = useState<string[]>([]);

    const copyConfig = useCallback(async (ids: string[]) => {
        try {
            const response = await (await service.copyConfiguration(department.id, ids)).getResponseData();
            if (response.success) {
                toast.success('Se ha copiado la configuración correctamente');
                refetch();
                onClose();
            } else {
                handleErrors(response);
            }
        } catch (error) {
            handleErrors(error);
        }
    }, [service, refetch, handleErrors]);

    if (!department) return null;

    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            size='sm'
            overlayClassName='backdrop-blur'
            containerClassName='!max-w-xl !shadow-xl'
            className='z-[9999] [&_.pointer-events-none]:overflow-visible'
        >
            <div className='m-auto px-7 pt-6 pb-8'>
                <div className='mb-7 flex items-center justify-between'>
                    <Text className="text-xl">Copiar configuración de {department.name}</Text>
                    <ActionIcon size='sm' variant='text' onClick={onClose}>
                        <IoClose className='h-auto w-6' strokeWidth={1.8} />
                    </ActionIcon>
                </div>

                <CustomSelect
                    isMulti
                    isSearchable
                    id={'departments'}
                    label="Selecciona los departamentos a los que quieres aplicar la configuración:"
                    value={departmentsIds.map((id: string) => ({ value: id, label: getDepartmentsList().find((dep: any) => dep.value === id)?.label }))}
                    options={getDepartmentsList().filter((dep: any) => dep.value !== department.id)}
                    onChange={(e: any) => {
                        const selectedIds = e ? e.map((option: any) => option.value) : [];
                        setDepartmentsIds(selectedIds);
                    }}
                />
            </div>

            <div className='flex justify-center p-7'>
                <Button
                    onClick={() => copyConfig(departmentsIds)}
                    color='secondary'
                >
                    Copiar configuración
                </Button>
            </div>
        </Modal>
    );
};