import { Button } from 'rizzui';
import cn from '../../../utils/classNames';
import Spinner from '../../../components/bootstrap/Spinner';

interface FormFooterProps {
    customClassName?: string;
    cancelBtnText?: string;
    submitBtnText?: string;
    isLoading?: boolean;
    handleCancelBtn?: () => void;
    handleSubmitBtn?: () => void;
}

export const negMargin = 'mx-4 md:-mx-5 lg:-mx-6 3xl:-mx-8 4xl:-mx-10';

export default function FormFooter({
    isLoading,
    cancelBtnText = 'Cancelar ',
    submitBtnText = 'Guardar Datos',
    customClassName = " -mb-4 md:-mb-4 lg:-mb-6",
    handleCancelBtn,
    handleSubmitBtn,
}: FormFooterProps) {

    return (
        <div
            className={cn(
                'sticky bottom-0 left-0 right-0 z-10 flex items-center truncate justify-end gap-4 border-t bg-white  px-4 py-4 md:px-5 lg:px-6 3xl:px-8 4xl:px-10 dark:bg-gray-50 -me-4 -ms-4',
                customClassName,
            )}
        >

            <Button
                variant="outline"
                className="w-full xl:w-auto"
                onClick={handleCancelBtn}
            >
                {cancelBtnText}
            </Button>
            <Button type="submit" className="w-full xl:w-auto" onClick={handleSubmitBtn} isLoading={isLoading} disabled={isLoading}>
                {isLoading ? <Spinner /> : submitBtnText}
            </Button>
        </div>
    );
}
